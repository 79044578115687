@import '../../variables.scss';

.notes-popover-button{
  margin-left: 5px;
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
}

.checkmark{
  font-size: 19px!important;
  margin-right: 2px;
}
.alert-cell-container{
  margin-left: 10px;
  width: calc(100% - 10px);
  overflow-y: auto;
}
.alert-cell-container-group-scroll{
  height: 330px!important;
  width: 100%;
}
.alert-cell-container-patient-scroll{
  height: 340px!important;
  width: 100%;
  overflow-y: auto;
}
.company-notification-alert-input{
  width: 100%;
  margin-right: 20px;
}
.company-notification-title{
  color: $textGrey;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  align-items: flex-end;

  .labeled-input-heading{
    color: $textGrey;
    font-size: 16px;
    font-weight: 500;
  }
  span{
    color: $textGrey;
    font-weight: 500;
  }
  input{
    width: 100%;
    margin-right: 0;
    height: 41px;
  }
  .Select-menu{
    max-height: 175px;

    div{
      max-height: 175px;
    }
  }
  .Select-menu-outer{
    max-height: 175px;
  }
}
.company-notification-receipts-header-text{
  margin-bottom: $mediumSpacing;
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
