@import '../../variables';

.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.group-user-cell{
  max-height: 350px;
  width: 100%;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-bottom: $largeSpacing;
  overflow: hidden;
}

.group-survey-cell{
  max-height: 350px;
  width: 100%;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-right: $mediumSpacing;
  margin-bottom: $largeSpacing;
  overflow-y: auto;
  overflow-x: hidden;
}
.group-user-cell-text{
  font-size: 14px;
  color: $textGrey;
}
.group-user-button{
  height: 20px;
  margin-right: $mediumSpacing;
}
.group-user-title-container{
  padding: $mediumSpacing;
  border-bottom: 1px solid $mediumGrey;
  font-size: 14px;
  color: $textGrey;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg{
    height: 16px;
    width: 16px;
  }
}
.group-user-title-info{
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
}
.group-user-title-circle{
  background-color: $white;
  border-radius: 50%;
  font-size: 14px!important;
  font-weight: 600;
  box-shadow: $boxShadow;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-right: $smallSpacing;
  flex: 0 0 30px;
}
.group-user-title-group-name{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 500;
  height: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-left: $smallSpacing;
}
.group-user-title-group-user-locale{
  color: $textGrey;
  width: 100px;
  height: 24px;
  font-size: $fontLarge;
  font-weight: 500;
  margin-left: $mediumSpacing;
}
.margin-left-0{
  margin-left: 0;
}
.group-user-count-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $largeSpacing;
  padding-bottom: $mediumSpacing;
}
.group-survey-count-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
}
.extra-padding-top{
  padding-top: $largeSpacing;
}
.group-patient-user-cell{
  width: 33%;

  @media screen and (max-width: $mediumScreenWidth) {
    width: 50%;
  }

  @media screen and (max-width: $smallScreenWidth) {
    width: 100%;
  }
}
.patient-user-count{
  font-weight: 500;
  font-size: 14px;
  color: $textGrey;
}
.group-patient-cell-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.group-patient-cell-container-outer{
  max-height: 250px;
  @include hidden-scrollbar;
}
.group-patient-list-scroll{
  width: auto;
}
.ul-padding-left-20 {
  padding-left: 20px;
}
.list-style-none{
  list-style: none;
}
.list-style-none > .custom-input-cell {
  padding-left: 0;
}
.list-style-none > .custom-input-cell > .inline-text {
  padding-left: 5px;
}
