@import '../../variables';

.date-picker-container{
  display: flex;
  flex-direction: row!important;
  align-items: center;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  .date-picker{
    flex: 1 1 auto;
    margin-right: 10px;
  }
}
.reminder-input-container{
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  display: flex;
  justify-content: space-between;

  &>*{
    flex: 0 0 calc(50% - 10px);
  }
}
