@import '../../variables';
@import '../../index';

$border-style-manage-patients-nav: 2px solid $primary-gray-2;

#manage-patients-nav {
  border-bottom: $border-style-manage-patients-nav;
  padding: 4px 0px;
  font-size: 14px;
  a {
    padding: 4px 25px;
    color: #aaa;
    border-radius: 4px 4px 0 0;
    border: 1px solid transparent ;

  }

  a:hover {
    background-color: $primary-gray-2;
  }

  .selected-nav-item {
    color: $primary-gray-1;
    border-left: $border-style-manage-patients-nav;
    border-right: $border-style-manage-patients-nav;
    border-top: $border-style-manage-patients-nav;
    border-bottom: 2px solid $primary-white;
  }

  .selected-nav-item:hover {
    background-color: transparent;
  }
}

.patient-list-item {
  height: 100%;
}
.search-patients{
  font-size: 12px;
  color: #aaa;
  padding-bottom: 8px;
}
.search-users-input{
  height: 36px;
  width: 80%;
}
.no-padding{
  padding: 0px;
}
.fix-width{
  width: fit-content;
}
.manage-patient-status-title{
  color: $textGrey;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 500;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }

  input{
    width: 100%;
    margin-right: 0;
  }
}
.manage-patient-input-large{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.panel-scroll-patient-list{
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 273px);
  margin-top: $mediumSpacing;

  .scrollbar-container{
    overflow-y:auto!important;
  }
}
.patient-list-item-invite-cell{
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $mediumSpacing;
  margin-left: $mediumSpacing;
  margin-right: $mediumSpacing;
  font-size: $fontMedium;
  color: $textGrey;
}
