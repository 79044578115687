.reminder-schedule {
  font-family: Avenir-Roman;
  font-size: 12px;
  color: #9b9b9b;
  background-color: #eeeeee;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.reminder-schedule-text {
  font-family: Avenir-Roman;
  font-size: 12px;
  color: #9b9b9b;
}

.reminder-schedule-title-collapsed {
  color: #4a4a4a;
}

.reminder-schedule-text-container {
  margin-left: 5px;
}

.collapse-button-down {
  width: 0;
  height: 0;
  border: solid 5px;
  border-color: #9b9b9b transparent transparent transparent;
}

.collapse-button-up {
  width: 0;
  height: 0;
  border: solid 5px;
  border-color: transparent transparent #9b9b9b transparent;
}

.container {
  display: flex;
  align-items: center;
}

.collapsed-container {
  display: flex;
  align-items: flex-start;
}
