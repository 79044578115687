@import '../../_variables.scss';


.manage-campaigns-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
  margin-bottom: 15px;
}
.manage-patient-container-title{
  font-size: 14px;
  color: $textGrey;
  font-weight: 500;
  margin-bottom: $smallSpacing;
  margin-right: $largeSpacing;
}
.manage-patient-container-text{
   font-size: 12px;
   color: $mediumGrey;
   font-weight: 500;
   margin-right: $largeSpacing;
}
.manage-patient-container-text-add{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left:  $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.manage-patient-import-input{
  width: 100%;
  margin-top: $mediumSpacing;

  .text-input-field{
    width: calc(100% - 20px);
  }
}
.manage-patient-invite-input-email-search{
  width: calc(100% - 20px);
}
.manage-patient-container-invite{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-left:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  min-height: calc(100vh - 164px);

  .manage-patient-invite-input-large{
    width: 100%;

    input{
      width: 100%;
    }
  }

  @media screen and (max-width: $mediumScreenWidth) {
    height: calc(100vh - 282px);
  }
}
.manage-patient-invite-input-large{
  width: 100%;
}
.manage-patient-invite-search-text{
  width: 100%;
  margin-right: $largeSpacing;
  font-size: 12px;
  color: $mainBlue;
  margin-left: $smallSpacing;
}
.manage-patient-invite-input{
  width: calc(100% - 20px);
  margin-bottom: 10px;
  margin-right: 20px;
}
.manage-patient-invite-input-container{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth) {
    &.manage-patient-column{
      flex-direction: column;
    }
  }
}
.manage-patient-scroll-container {
  @include hidden-scrollbar;
}
.manage-patient-list-container{

  .patient-list-cell-container{
    margin-top: $mediumSpacing;
    height: calc(100vh - 260px);
  }
}
.manage-campaigns-container{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-left:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  min-height: calc(100vh - 164px);

  .manage-patient-invite-input-large{
    width: 100%;

    input{
      width: 100%;
    }
  }

  @media screen and (max-width: $mediumScreenWidth) {
    height: calc(100vh - 282px);
  }
}
