@import '../../variables';

.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.patient-cell{
  display: flex;
  justify-content: space-between;
  padding: $mediumSpacing;
  align-items: center;
  cursor: pointer;
}
.profile-pic-patient-cell{
  flex: 0 0 30px;
}
.patient-info{
  font-weight: 500;
  font-size: 14px;
  color: $textGrey;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  max-width: calc(100% - 20px);

  .profile-picture-container-inner{
   width: 30px;
    margin-right: 5px;
  }
}
.patient-editing{
  display: flex;
  align-items: center;
  cursor: pointer;
  svg{
    height: 16px;
    width: 16px;
  }

  input{
    margin: 0;
  }
}
.patient-user-input{
  height: 15px;
  width: 15px;
}

.patient-cell.add-appointment-patient-cell 
.patient-info 
.patient-cell-name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}

.patient-cell {
  &.add-appointment-patient-cell {
    &.is-edit-virtual-appointment {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 10px;
      align-items: flex-start;
      cursor: pointer;
      width: 100%;
      overflow-x: hidden;

      .patient-info {
        display: flex;
        align-items: flex-start;

        .profile-picture-container-inner {
          margin-right: 10px;
        }

        .patient-cell-name {
          white-space: normal;
          margin-top: 5px;
        }
      }

      .patient-editing {
        margin-right: 10px;
        height: 30px;
      }
    }
  }

}
