@import '../../variables';


.note-comment-cell-container{
  height: 100%;
  overflow-x: hidden;
}

.note-comment-cell-cell {
  display: flex;
  padding-bottom: $mediumSpacing;
  margin-bottom: 0;
  border-bottom: none;
}
.note-comment-cell-comment{
  width: 100%;
  color: $textGrey;
  font-size: 12px;
  font-weight: 400;
}
.note-comment-cell-info{
  color: $textGrey;
}
.note-comment-cell-info-author{
  color: $textGrey;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.note-comment-cell-info-date{
  color: $textGrey;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  line-height: 1;
}
.data-query-resolution-status{
  display: flex;
  align-items: center;
  margin-bottom:$smallSpacing;
}
.data-query-resolution-text{
  font-size: 12px;
  color: $textGrey;
  margin-left: 3px;
  font-weight: 400;
}
.data-query-comment-count-container{
  font-size: 14px;
  color: $textGrey;
}
.data-query-comment-count-zero{
  font-size: 14px;
  color: $textGrey;
  display: flex;
}
.data-query-comment-count-cell{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $textGrey;
  margin-bottom:$smallSpacing;
  margin-top: $smallSpacing;
}
.data-query-comment-count-text{
  font-size: 12px;
  color: $textGrey;
  margin-left: 3px;
  font-weight: 400;
}
.data-query-list-item{
  margin-top: $mediumSpacing;
  margin-bottom: $smallSpacing;
  width: 100%;
}
.data-query-comments-title{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
  cursor: pointer;
}
.data-query-note-cell-list{
  margin-bottom: $smallSpacing;
}
.scrollbar-data-scroll{
  max-height: 308px!important;
  min-height: 250px;
  overflow-y: auto;
  margin-bottom: $smallSpacing;
}
.popper-button-container{
  display: flex;
  justify-content: space-between;
  margin-right: $largeSpacing;
  margin-top: $mediumSpacing;
}
.popper-textarea{
  width: 100%;
}
