@import '../../../_variables.scss';

.selected-button-container{
  border: 1px solid $textGrey;
  color: $textGrey;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  padding-top: $mediumSpacing;
  padding-bottom: $mediumSpacing;
  font-size: $fontMedium;
  border-radius:5px;
  display: flex;
  align-items: center;
  background-color: $white;
  font-weight: 500;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;

  &:hover{
    color: $mainBlue;
    border: 1px solid $mainBlue;

    .selected-button-circle{
      box-shadow: 0 0 0 1px $mainBlue;
      border: 2px solid $white;
      height: 16px;
      width: 16px;
      background-color: $mainBlue;
      border-radius: 50%;
      margin-right: $smallSpacing;
    }
  }

  span{
    line-height: 1;
  }
}
.selected-button-selected-container{
  color: $mainBlue;
  border: 1px solid $mainBlue;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  padding-top: $mediumSpacing;
  padding-bottom: $mediumSpacing;
  font-size: $fontMedium;
  border-radius:5px;
  display: flex;
  align-items: center;
  background-color: $white;
  font-weight: 500;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;

  span{
    line-height: 1;
  }

  &:hover{
    .selected-button-selected-circle{
      width: 16px;
      border: 2px solid $white;
      height: 16px;
      box-shadow: 0 0 0 1px $textGrey;
      margin-right: $smallSpacing;
      border-radius: 50%;
      background-color: $white;
    }
  }
}
.selected-button-circle{
  box-shadow: 0 0 0 1px $textGrey;
  border: 2px solid $white;
  height: 16px;
  width: 16px;
  background-color: $white;
  border-radius: 50%;
  margin-right: $smallSpacing;
}
.selected-button-selected-circle{
  box-shadow: 0 0 0 1px $mainBlue;
  border: 2px solid $white;
  height: 16px;
  width: 16px;
  background-color: $mainBlue;
  border-radius: 50%;
  margin-right: $smallSpacing;
}
