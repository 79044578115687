@import '../../../_variables.scss';

.red-button-main-container{
  .red-button-main{
    min-width: 100px;
    height: 40px;
    padding-left: $largeSpacing;
    padding-right:  $largeSpacing;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: $smallSpacing;
    border: none;
    cursor: pointer;

    &:hover:not([disabled]){
      background-color: $mainRedHover;
    }
    &:active{
      background-color: $mainRedPressed;
      border: none;
    }
  }
  .button-icon{
    margin-top: -7px;
    font-size: large;
    margin-left: 5px;
  }
  .button-large{
    height: 50px;
  }
}
