.survey-medication-question-cell{
  width: 100%;
  margin-bottom: 20px;

  .labeled-input-container{
    width: 100%;
  }
  .Select-menu-outer{
    width: 100%;
  }
}
.survey-medication-question-cell-details-container{
  margin-top:10px;
}
