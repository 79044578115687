@import '../../_variables.scss';

#header {
  background-color: #18405a;
  border-color: #112d40;
  height: 50px;
  margin-bottom: 0px;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  border-width: 0 0 1px;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav-block > *{
    opacity: .5;
    &.active {
      opacity: 1;
    }
  }

  //@media screen and (max-width: $mediumScreenWidth){
  //  height: 50px;
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //}
}
//.display-mobile{
//  display: none;
//
//  @media screen and (max-width: $mediumScreenWidth){
//    display: block;
//  }
//}
//
//.display-web{
//
//  @media screen and (max-width: $mediumScreenWidth){
//    display: none!important;
//  }
//}
.nav-block{
  display: flex;
}

.header-logo{
  height: 30px;
  width: 195px;
  background-image: url("HSC Logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: $largeSpacing;
}
.header-article-icon-cell{
  display: flex;
  color: white;
  align-items: center;
}
.header-alert-icon{
  font-size: $fontSmall;
  color: white;
  margin-top: -30px;
  margin-left: 14px;
  height: 7px;
  width: 7px;
  position: relative;
  background-color: $red;
  border-radius: 7px;
  display: flex;
  align-items: center;
  line-height: 1;
}
.popover.study-closed {
  z-index: 1000001 !important;
}
.study-closed-info {
  display: flex;
  align-items: center;
  position: relative;
  align-items: center;
  width: 113px;
  margin-top: 7px;
  text-align: center;
  height: 30px;
  color: #347EC2;
  background: #D5DDE4;
  border-radius: 20px;

  &-icon {
    font-size: 18px;
    margin: 5px;
  }

  span {
    font-size: $fontSmall;
    font-weight: 600;
    cursor: pointer;
  }

  &-popover {
    font-style: normal;
    font-weight: 500;
    font-size: $fontSmall;
    line-height: 16px;
    display: flex;
    align-items: center;
    width: 270px;
    height: 32px;
    color: #9C9C9C;
  }
}
