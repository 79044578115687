@import '../../../_variables.scss';

.labeled-dropdown-input-container{
  font-size: 14px;
  color: $textGrey;
}
.labeled-dropdown-container{
  display: flex;
  position: relative;
  align-items: center;
  top: auto;
  bottom: 100%;
  flex: 1 1 auto;
}
.labeled-dropdown{
  width: 100%!important;
  display: block !important;

  &.labeled-dropdown-empty:not(.labeled-dropdown-active) {
    border: 1px solid $inputDisabled;
    outline: none;

    .is-empty {
      background-color: $inputBackground;
    }
  }
  input{
    color: $textGrey;
  }
}
.labeled-dropdown-active{
  border: 1px solid $mainBlue !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  background-color: $white;
  color: $textGrey;
}
.labeled-dropdown-disabled{
  border:1px solid $inputDisabled;
  color: $inputDisabledText;
  background-color: $inputDisabled;
}
.labeled-dropdown-outer{
  display: flex;
}
.labeled-input-errors{
  position: relative;
}
.labeled-dropdown-input-loading{
  position: absolute;
  width: auto;
  z-index: unset;
}
.labeled-dropdown-input-cell{
  display: flex;
  align-items: center;
  position: relative;
}
