@import '../../_variables.scss';

.ManagePayments {}

.header-row-table{

}
.secondary-header{
  height: 62px;
  background-color: $mainBlue;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  padding: $largeSpacing;
  display: flex;
  align-items: center;
  border-bottom-left-radius: $border-radius-panel;
  margin-bottom: $mediumSpacing;
  box-shadow: $boxShadow;
}
.main-panel-inner-container-secondary{
  overflow: visible;
  background-color: $white;
  box-shadow: $boxShadow;
  border-top-left-radius: $border-radius-panel;
  height: calc(100vh - 124px);
}
.manage-trigger-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  overflow-y: auto;
  padding-bottom: 70px;
  height: 100%;
  overflow-x: hidden;
}
.manage-new-trigger-container{
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  overflow-y: auto;
  padding-bottom: 70px;
  height: 100%;
  overflow-x: hidden;
}
.manage-triggers-survey-list-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
.manage-triggers-user-list-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
.manage-trigger-large{
  padding: $largeSpacing;
  padding-right: 0;
  padding-left: 0;
}
.manage-trigger-large-dual-input{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
