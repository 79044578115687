.selectedVitalFlowDetailNavItem {
  color: white;
  background-color: #2d81c9;
}

.unSelectedVitalFlowDetailNavItem {
  color: #2d81c9;
  background-color: white;
}

.baseVitalFloDetailNavItem {
  padding: 6px 15px;
  cursor: pointer;
  font-weight: bold;
  height: fit-content;
}

.firstVitalFloNavItem {
  border: solid 1px #2d81c9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.lastVitalFloNavItem {
  border: solid 1px #2d81c9;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
