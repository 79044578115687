@import '../../variables';

.manage-appointments-container-list{
  width: 100%;
  background-color: $white;
  padding: $largeSpacing;
  padding-bottom: 0;
  overflow: hidden;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 182px);
  .fc-direction-ltr .fc-timegrid-col-events{
    margin: 0 0 0 2px;
  }
}
.manage-appointments-container-create{
  width: 100%;
  background-color: $white;
  padding: $largeSpacing;
  padding-right: 0;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;

  .text-input-field{
    width: 100%;
  }
}
.manage-appointments-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
}
.manage-appointments-title-container{
  font-size: 14px;
  color: $textGrey;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  input{
    margin-bottom: 0;
    margin-right: 0px;
  }
}
.manage-appointments-input-large{
  width: calc(100% - 20px);
}
.manage-appointments-dual-input{
  display: flex;
  justify-content: space-between;
}
.manage-appointments-input-small{
  width: 50%;
}
.manage-appointments-input-tall{
  width: calc(50% - 20px);
}
.manage-appointments-participants-list{
  border:1px solid $mediumGrey;
  padding: $largeSpacing;
  display: flex;
  margin-top: $smallSpacing;
  border-radius: $borderRadius;
  margin-bottom: $largeSpacing;
  max-height: 275px;
  overflow-y: auto;
  width: calc(100% - 20px);
}
.fc-state-default {
  color: white;
  background-color: black;
}

.fc-state-active {
  background-color: #50E3C2;
}

.fc-state-hover {
  background-color: black;
  border-color: black;
  border-width: medium;
}
.fc-button .fc-button-inner {
  background-color: #000;
  color: #fff;
}
.fc-header-right .fc-button .fc-button-inner:hover {
  background-color: red;
  color: #fff;
}
.fc-button.fc-state-active .fc-button-inner {
  background-color: green;
  color: #fff;
}

.fc-next-button .fc-button .fc-button-primary {
  background-color: brown;
}
.virtual-appointments-list-scroll{
  overflow-y: auto;
}
.virtual-appointments-list-all-scroll{
  height: calc(100vh - 242px);
  @include hidden-scrollbar;
}
.virtual-appointments-list-group-scroll{
  height: 291px;
}
.virtual-appointments-list-patient-scroll{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
}
