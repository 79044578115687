@import '../../_variables.scss';
@import '../../index.scss';

.error-message{
  font-size: 14px;
  color: #d9534f;
  padding-bottom: 10px;
  margin: 5px;
}
.survey-title{
  font-size: 18px;
  color: #020202;
  font-weight: 600;
  margin-bottom: 10px;
}
.survey-title-large{
  font-size: 18px;
  color: #020202;
  font-weight: 600;
  width: 200px;
  flex: 0 0 200px;
  margin-right: $largeSpacing;
}
.survey-question-container{
  margin: 20px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 75vh;
  max-height: 700px;
  min-height: 300px;
  position: relative;
  border: 4px solid #31c8a3;
  font-size: 16px;
  color: #020202;
  border-radius: 10px;
  overflow: hidden;

  .scrollbar-container{
    height: 100%!important;
  }
}
.survey-question-container-disabled-scroll{
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  height: auto;
  position: relative;
  border: none;
  font-size: 16px;
  color: #020202;

  .scrollbar-container{
    height: 100%!important;
  }

  .modal-body{
    height: 100% !important;
  }
}
.survey-question-container-medium{
  width: 600px;
}
.survey-question-container-large{
  width: 750px;
}
.survey-button-container{
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  align-items: flex-end;
  z-index: 1111;
}
.survey-input-cell{
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.survey-input-radio-cell{
  position: relative;
  display: block;
  margin-bottom: 10px;
  width: 100%;
}
.survey-input-radio-container{
  display: flex;
  flex-wrap: wrap;
  padding-top: $mediumSpacing;
}
.disabled-input-cell{
  opacity: 0.5;
  pointer-events: none;
}
.survey-input-radio-two-column{
  position: relative;
  display: block;
  margin-bottom: 10px;
  width: 50%;
}
.survey-input-radio-three-column{
  position: relative;
  display: block;
  margin-bottom: 10px;
  width: 33%;
}
.survey-input-radio-four-column{
  position: relative;
  display: block;
  margin-bottom: 10px;
  width: 25%;
}
.survey-input-radio-five-column{
  position: relative;
  display: block;
  margin-bottom: 10px;
  width: 20%;
  margin-bottom: 0;
}
.survey-button{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  border: none;
  background-color: #aaa;
  color:#020202;
  margin-left: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.survey-button:hover{
  background-color:  #777;
  color:#fff;
}
.green-button{
  background-color: #31c8a3;
  color:#fff;
}
.green-button:hover{
  background-color: #2e6b5e;
  color:#fff;
}
.inline-text {
  display: inline;
}
.inline-text-radio-input-bottom-text{
  display: inline;
  max-width: calc(100% - 10px);
  white-space: wrap;
  word-break: break-word;

  margin-right: $mediumSpacing;
  margin-left: $mediumSpacing;
}
.survey-string-input{
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border:0;
  border: 1px solid #CCC;
  border-radius: 0px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  position: relative;
}
.survey-string-input:focus {
  border-color:#2EB398;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
}
.survey-string-input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.survey-string-input:-ms-input-placeholder {
  color: #999;
}
.survey-string-input::-webkit-input-placeholder {
  color: #999;
}
.survey-string-input:disabled{
  cursor: not-allowed;
  background-color: #eee;
}
select{
  max-height: 300px;
}

//Custom Radio and Checked CSS and HTML setup, SCC branding not in, for Radio just add 50% border radius- see bottom 2 changes
.custom-input-cell{
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 17px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 16px;
  height: max-content;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.survey-radio-cell-label-left{
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-left: 35px;
  margin-bottom: 17px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 16px;
  height: max-content;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-input-cell survey-radio-cell-label-left input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.survey-radio-cell-label-left-bottom{
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-left: 35px;
  margin-bottom: 17px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 16px;
  height: max-content;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-input-cell survey-radio-cell-label-bottom input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-input-cell input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}
.custom-checkmark{
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #2EB398;
}
.custom-input-cell:hover input ~ .custom-checkmark{
  background-color: #eee;
}
.custom-input-cell input:checked ~ .custom-checkmark{
  background-color:#eee;
}
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-input-cell input:checked ~ .custom-checkmark:after{
  display: block;
}
.custom-input-cell .custom-checkmark:after{
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #2EB398;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
//<label class="custom-input-cell">One
//<input type="checkbox" checked="checked">
//<span class="custom-checkmark"></span>
//</label>

.custom-checkmark-radio{
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 2px #2EB398;
}
.custom-input-cell:hover input ~ .custom-checkmark-radio{
  background-color: #ddd;
}

.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-input-cell input:checked ~ .custom-checkmark-radio:after{
  display: block;
}
.custom-input-cell .custom-checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2EB398;
}

.checked{
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #2EB398;
  border: 6px solid #eee;
  box-shadow: 0px 0px 1px 2px #2EB398;
  position: absolute;
}

.survey-other-checked-column{
  top: -8px;
}
.survey-other-checked-row{
  top: 0px;
}

.survey-question-and-buttons-spread{
  height: 100%;
  width: 100%;
}

.add-padding{
  padding: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.question-cell{
  padding: 20px;
  padding-bottom: 1px;
  height: auto;
}

.entry-slider{
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  width: 90%;

  .rc-slider-mark-text{
    margin-left: 0px !important;
    margin-right: 0px;
  }
}
.slider-with-bottom-value{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.centre-text{
  justify-content: center;
  width: 100%;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #31c8a3;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
  flex: 0 0 20px;
}

.side-by-side-dropdowns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .Select-menu-outer{
    width: 100%;
  }
}

.css-1pcexqc-container{
  width: 100%;
}

.select-with-label{
  width: 100%;
  margin: 5px;
}
//<label class="custom-input-cell">One
//<input type="radio">1
//<span class="custom-checkmark-radio"></span>
//</label>
.survey-button-container{
}
.survey-question-container-inner{
  height: calc(100% - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: $mediumSpacing;

  .answer-cell-large{
    margin-left: 0;
  }
}
.survey-add-query-button-container{
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  font-size: 14px;
  color: $mainBlue;
  font-weight: 500;
  cursor: pointer;
}
.survey-add-query-button-question-container{
  display: flex;
  font-size: 14px;
  color: $mainBlue;
  font-weight: 500;
  cursor: pointer;
  margin-left: $largeSpacing;
}
.error-message-question{
  text-align: center;
  color: $red;
  padding: 5px;
  width: calc(100% - 10px);
}
.no-border{
  border-color: transparent;
}
.survey-page-count{
  color: $textGrey;
  font-size: $fontSmall;
  margin-right: auto;
  margin-left: 2px;
  background-color: rgba(255,255,255,1);
  padding-left: $smallSpacing;
  padding-right: $smallSpacing;
  border-radius: 12px;
}
.survey-radio-cell-label-bottom{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 20px;
}
.survey-radio-cell-label-left{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 20px;
}
.custom-input-cell-column-right-text{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 30px;
  margin-bottom: 0;
}
.inline-text-radio-input-right-text{
  display: flex;
  align-items: center;
  max-width: calc(100% - 10px);
  white-space: wrap;
  word-break: break-word;
  height: 100%;
  margin-right: $mediumSpacing;
  margin-left: $mediumSpacing;
}
.inline-text-radio-input-column{
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-top: $smallSpacing;
  max-width: 100%;
}
.checked-column{
  left: calc(50% - 15px);
}
.custom-checkmark-radio-column{
  left: calc(50% - 15px);
}
.question-cell-large{
  padding: 10px;
  padding-bottom: 1px;
  height: auto;
  display: flex;
}
.answer-cell-large{
  flex: 1 1 auto;
  margin-left: $largeSpacing;
}
.survey-label-left{
  width: 150px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}
.survey-label-left{
  width: 200px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}
.survey-add-query-button-question-container-column{
  width: 100%;
}
.question-cell-large-survey-button{
  flex-wrap: wrap;
}
.survey-page-title{
  font-weight: 600;
  font-size: 20px;
  color: $black;
  margin: $mediumSpacing;
  margin-bottom: 0;
}
.progress {
  height: 20px;
  width: calc(100% - 15px);
  margin-right:$mediumSpacing;
  margin-left: $smallSpacing;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}
.survey-progress-bar{
}
.survey-progress-bar-container{
  display: flex;
  font-size: $fontSmall;
  color: $textGrey;
  margin-left: $mediumSpacing;
  margin-top: $mediumSpacing;
}
.survey-input-radio-other{
  width:100%;
  display:flex;
  align-items:center;
  margin-bottom:30px;
}
.inline-text-radio-input-other{
  margin-right: $mediumSpacing;
  margin-left: $mediumSpacing;
}
.survey-string-input-other{
  display: block;
  width: calc(100% - 50px);
  height: 34px;
  padding: 6px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border:0;
  border: 1px solid #CCC;
  border-radius: 0px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  position: relative;
}
.survey-string-input-other:focus {
  border-color:#2EB398;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
}
.survey-string-input-other::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.survey-string-input-other:-ms-input-placeholder {
  color: #999;
}
.survey-string-input-other::-webkit-input-placeholder {
  color: #999;
}
.survey-string-input-other:disabled{
  cursor: not-allowed;
  background-color: #eee;
}
.survey-input-radio-other-spacing-five{
  padding-left: calc((100% / 5) / 2 - (30px / 2));
  padding-right: calc((100% / 5) / 2 - (30px / 2));
}
.survey-input-radio-other-spacing-four{
  padding-left: calc((100% / 4) / 2 - (30px / 2));
  padding-right: calc((100% / 4) / 2 - (30px / 2));
}
.survey-input-radio-other-spacing-three{
  padding-left: calc((100% / 3) / 2 - (30px / 2));
  padding-right: calc((100% / 3) / 2 - (30px / 2));
}
.survey-input-radio-other-spacing-two{
  padding-left: calc((100% / 5) / 2 - (30px / 2));
  padding-right: calc((100% / 5) / 2 - (30px / 2));
}
.survey-input-radio-other-spacing-one{
  padding-left: calc((100% / 5) / 2 - (30px / 2));
  padding-right: calc((100% / 5) / 2 - (30px / 2));
}
.survey-medication-button-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.survey-button-add-medication{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  border: none;
  margin-left: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #31c8a3;
  color:#fff;
  margin-right: $mediumSpacing;

  &:hover{
    background-color: #2e6b5e;
    color:#fff;
  }
}
.survey-button-remove-medication{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  border: none;
  background-color: #aaa;
  color:#020202;
  margin-left: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    background-color:  #777;
    color:#fff;
  }
}
.survey-question-image{
  max-height: 250px;
  max-width: 250px;
  object-fit: contain;
  object-position: center;
  margin-left: auto;
  margin-right: auto;
}
.survey-question-image-small{
  max-height: 200px;
  max-width: 200px;
  object-fit: contain;
  object-position: center;
  margin-right: auto;
}
.survey-question-image-container{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.survey-question-image-button-download{
  position: absolute;
  right: 0;
  bottom: 40px;
  margin-bottom: $mediumSpacing;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  font-size: $fontMedium;
}
.survey-question-image-button-bottom{
  position: absolute;
  right: 0;
  bottom: 0;
}
.survey-question-image-cell{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.survey-question-econsent-cell{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  input{
    margin-right: 0;
  }
  .Select-menu-outer{
    width: 100%;
  }
}
.survey-question-image-name{
  color: $textGrey;
  font-size: $fontMedium;
  position: absolute;
  bottom: 40px;
  width: 145px;
  right: 0;
}
.survey-question-file-cell{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.survey-question-file-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.survey-question-file-name {
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: $mediumSpacing;
  letter-spacing: 1px;
}
.survey-question-file-button-upload{
  margin-bottom: $mediumSpacing;
  display: flex;
  letter-spacing: 1px;
  font-size: $fontMedium;
}
.survey-question-file-button{
  margin-left: 0;
  margin-bottom: $mediumSpacing;
}
.survey-signature-pad{
  max-width: 500px;
  max-height: 150px;
  border: 1px solid $textGrey;
  margin-left: auto;
  margin-right: auto;
}
.survey-signature-pad-image{
  max-width: 500px;
  width: 80%;
  max-height: 150px;
  border: 1px solid $textGrey;
  margin-left: auto;
  margin-right: auto;
  object-position: center;
  object-fit: contain;
}
.survey-signature-button-container{
  display: flex;
  margin-top: $mediumSpacing;
  max-width: 500px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
}
.survey-signature-button{
  margin: 0;
}
.survey-question-text-title{
  text-align: left;
  font-size: $fontMedium;
  color: $textGrey;
  margin-right: auto;
  font-weight: 500;
}
.survey-signature-checkbox-container{
  font-size: $fontMedium;
  margin-top: $mediumSpacing;
}
.survey-question-signature-container{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.survey-number-question-container-small{
  display: flex;
  align-items: center;
  justify-content: space-between;

  input{
    margin-bottom: 0;
    width: calc(50% - 0px);
  }
  .Select-menu-outer{
    width: 100%;
  }
}
.survey-text-question-container{
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input{
    margin-right: 5px;
  }
}
.survey-number-question-container{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.survey-text-question-skip-container{
  align-items: center;
  position:relative;
  margin-top:10px;

}
.survey-question-large-text-area{
  display: block;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  resize: none;
  height: 175px!important;
  border: 1px solid #CCC;
  border-radius: 0px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  position: relative;
}
.survey-question-large-text-area:focus {
  border-color:#2EB398;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
}
.survey-question-large-text-area:active {
  border-color:#2EB398;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(46,179,152, .6);
}
.survey-question-large-text-area::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.survey-question-large-text-area:-ms-input-placeholder {
  color: #999;
}
.survey-question-large-text-area::-webkit-input-placeholder {
  color: #999;
}
.survey-question-large-text-area:disabled{
  cursor: not-allowed;
  background-color: #eee;
}
.survey-text-question-pass{
  //border-color: $green;
  //outline: 0;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(27, 127, 79, 0.6);
  border-radius: 0;

  &:focus{
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(27, 127, 79, 0.6);
    border-color: $green;
  }
}

.survey-text-question-fail{
  //border-color: $red;
  //outline: 0;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(224, 32, 32, 0.6);
  border-radius: 0;

  &:focus{
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(224, 32, 32, 0.6);
    border-color: $red;
  }
}
.survey-cell-number-choice-dropdown{
  border-radius: 0 !important;
}

.react-datepicker__month-dropdown{
  height: 150px;
  overflow-y: scroll;
}
.survey-medication-input{
  width: 100%;
  margin-bottom: 20px;

  input{
    width: 100%;
    margin-right: 0;
  }
}
.medication-dual-input{
  width: 100%;
  display: flex;
  justify-content: space-between;

  input{
    width: 100%;
    margin-right: 0;
  }
}
.medication-third-input{
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  input{
    width: 100%;
    margin-right: 0;
  }
}
.survey-medication-input-small{
  width: calc(50% - 10px);

  .labeled-input-heading{
    width: 100%;
    margin-right: 0;
  }

  input{
    width: calc(100% - 10px);
    margin-right: 0;
  }
}
.survey-medication-input-third{
  width: calc(33% - (40px / 3));

  &:first-of-type{
    margin-right: 20px;
  }
  &:nth-of-type(2){
    margin-right: 20px;
  }
  input{
    width: 100%;
    margin-right: 0;
  }
  .editable-input{
    width: 100%;
    margin-right: 0;
  }
  .editable-input .react-datepicker__input-container input {
    height: 41px;
  }
}
.survey-loading-spinner{
  height: calc(100% - 100px);
  padding-top: 50px;
}
.survey-question-inner-container{
  height: calc(100% - 30px);
  flex: 1 1 calc(100% - 30px);
}
.survey-question-inner-container-error{
  flex: 1 1 auto;
  overflow-y: auto;
}
.survey-question-error{
  flex: 0 0;
}
.survey-month-year-picker{
  .react-datepicker__input-container input{
    padding-bottom: 1px;
  }
  .react-datepicker__navigation{
    top: 7px !important;
  }
  .react-datepicker__month-dropdown-container--select,  .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
    width: 80px;
  }
  .react-datepicker__month-read-view--down-arrow{
    right: -8px;
    top: 3px;
  }
  .react-datepicker__year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll{
    width: 50px;
    margin-right: 15px;
  }
  .react-datepicker__year-read-view--down-arrow,  .react-datepicker__month-year-read-view--down-arrow{
    right: -3px;
    top: 3px;
  }
}
.select-with-label-time{
  width: 100%;
  margin: 5px;
}
.survey-time-dropdown{
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.survey-time-dropdown-minute{
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.survey-labeled-input-modify-reason{
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 5px;

  .labeled-input-heading{
    color: $black;
  }

  .labeled-input-heading-tools{
    white-space: unset;
  }

  input{
    height: 34px;
    border-radius: 0px!important;
    border: 1px solid rgb(204,204,204);
    margin-right: 0;
    width: 100%;
  }
}
.survey-month-year-picker-cell{

  .react-datepicker{
    width: calc(100% + 5px)!important;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
    top: 1px!important;
  }

  .react-datepicker__month-container{
    width: 100%;
  }
  .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    width: auto!important;
  }
}
