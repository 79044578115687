@import '../../../_variables.scss';

.labeled-errors-warning-container{
  position: relative;
  margin-top: 5px;
}
.labeled-errors-message-cell{
  display: flex;
  color: $red;
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
  align-items: center;

  span{
    display: flex;
  }
}
.labeled-errors-error-icon{
  color:$red;
  font-size: 20px !important;
}
.labeled-errors-error-message{
  color:$red;
  font-size: $fontMedium;
  margin-left: 5px;
  white-space: normal;
  line-height: 1.3;
  margin-top: 1px;
  overflow: hidden;
}
.labeled-errors-warning-message-cell{
  display: flex;
  color: $orange;
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
  align-items: center;

  span{
    display: flex;
  }
}
.labeled-errors-warning-icon{
  color: $orange;
  font-size: 20px !important;
}
.labeled-errors-warning-message{
  color: $orange;
  font-size: $fontMedium;
  margin-left: 5px;
  white-space: normal;
  line-height: 1.3;
  margin-top: 1px;
  overflow: hidden;
}
.labeled-errors-input-error{
  border: 1px solid $red!important;
}
.labeled-errors-input-warning{
  border: 1px solid $orange!important;
}
