@import '../../variables';

.dynamic-list-container{
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background-color: $white;
}
.dynamic-list-title-cell{
  width: calc(100% - 40px);
  font-size: $fontMedium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: $largeSpacing;
  margin-right:$largeSpacing;
  margin-bottom: $mediumSpacing;
}
.dynamic-list-title{
  font-weight: 600;
  display: flex;
  flex: 0 0 auto;
  line-height: 1;
  margin-right: 20px;
}
.dynamic-list-title-search-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  max-width: 500px;
  margin-left: 20px;
  margin-bottom: $mediumSpacing;
}
.dynamic-list-title-list-search{
  max-width: 500px;
  width: 100%;
}
.dynamic-list-dynamic-list-cell{
  width: 100%;
  position: relative;
  height: auto;
}
.survey-version-collection-list-search-cell{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}
.dynamic-list-item-container{
  margin-top: 5px;
  overflow-x: auto;
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
}
.dynamic-list-title-back-button{
  margin-right: 20px;
  cursor: pointer;
}
.dynamic-list-cell-scroll-title-clamp{
  background-color: $textGrey;
  color: white;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  right: 0;
  display: block;
}
.dynamic-list-scroll-title{
  top: 0;
  color: $white;
  width: 100%;
  height: 35px;
  display: block;
  z-index: 3;
  position: sticky;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
  background-color: $textGrey;
}
.dynamic-list-scroll-title-clamp {
  top: 0;
  display: flex;
  position: sticky;
  align-items: center;
}
.dynamic-list-scroll-title-text{
  left: 20px;
  color: $white;
  display: flex;
  position: sticky;
  font-size: 14px;
  align-items: center;
  font-weight: 600;
  background-color: $textGrey;
}
.dynamic-list-scroll-title-switch{
  min-width:140px;
  margin-left: $largeSpacing;
  margin-top: 0!important;

  .labeled-switch-input-container-right{
    background-color: $textGrey;
    align-items: center;
  }
  .labeled-info-title{
    color: $white!important;
    margin-bottom: 0;
  }
}
.dynamic-list-cell-scroll-container{
  position: relative;
  display: unset;
}
.dynamic-list-outer-scroll-container{
  position: relative;
}
.dynamic-list-sub-header-view{
  width: calc(100% - 40px);
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;

  *{
    resize: none;
    overflow: hidden;
    max-width: 100%;
    max-height: 60px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .labeled-input-container{
    max-width: 100%;

    input{
      margin-right: 0;
      width: 100%;
    }

    textarea{
      margin-right: 0;
      width: 100%;
    }
  }
  img{
    width: auto;
    height: 40px;
    margin-bottom: 0;
  }
  .editable-input{
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }
}
.dynamic-list-header-view{
  height: 100%;
  flex: 1 1 auto;

  *{
    max-width: 100%;
    margin-bottom: 0;
    margin-right: 0;
    resize: none;
  }
  .labeled-input-container{
    max-width: 100%;
  }
  img{
    width: auto;
  }
}
.dynamic-list-title-header-view{
  max-width: 100%;
  min-width: calc(50% - 50%);
  display: flex;
  height: 100%;
  align-items: center;
  flex: 1 1 auto;
}
.dynamic-list-explanation-text{
  color: $mediumGrey;
  width: calc(100% - 40px);
  font-size: 12px;
  font-weight: 500;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
