@import '../../_variables.scss';

.PaymentsList {}

.table-payment-more{
  width: 20px;
  font-size: 20px;
  color: $textGrey;
  padding: 0;
  margin: 0;
}
.payment-status-cell{
  display: flex;
  align-items: center;
}
.payment-status-cell-top{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.payment-status-cell-criteria{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.payment-status-icon{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $red;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-status-icon-paid{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $green;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-criteria-text{
  width: 100%;
  color: $textGrey;
  font-size: 12px;
}
.payment-criteria-text-bold{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $textGrey;
  font-size: 12px;
  font-weight: 500;
}
.payment-criteria-text-thin{
  width: 100%;
  white-space: normal;
  color: $mediumGrey;
  font-size: 12px;
  font-weight: 300;
}

td{
  vertical-align: unset;
  padding-top: 0;
}

.payment-list-cell{
  padding: 0;
  list-style-type:disc;
  margin-bottom: 0;
  padding-inline-start: 10px;
}
.payment-criteria-icon{
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: $textGrey;
  margin-right: 2px;
}
.payment-list-title{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  padding-top: $mediumSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 600;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }
}
.payment-title-list-search{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.payment-list-cell-dual{
  display: flex;
}
.payments-list-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  padding-left: $mediumSpacing;
  margin-right: $mediumSpacing;
  margin-top: $largeSpacing;
}
.payments-list-header-small{
  width: 15%;
  padding: $mediumSpacing;
}
.payments-list-header-medium{
  width: 20%;
  padding: $mediumSpacing;
}
.payments-list-header-large{
  width: 30%;
  padding: $mediumSpacing;
}
.payment-cell-list-container{
  height: calc(100vh - 225px);
}
.payments-list-text{
  font-size: $fontMedium;
  color: $textGrey;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  width: 100%;
}
.payment-cell-info{
  width: 100%;
  padding: $mediumSpacing;
  color: $textGrey;
}
.payment-cell-paid-info{
  display: flex;
  padding: $mediumSpacing;
  color: $textGrey;
  justify-content: space-between;
  align-items: flex-start;
}
.payment-cell-paid{
  color: $textGrey;

  .ant-popover{
    max-width: 300px!important;
  }
}
.payment-cell-more-info{
  margin-left: auto;
}
.payment-cell-notes-popover-button{
  background: $light-gray;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 15px;
  width: 30px;
}
.payment-cell-fraud{
  display: flex;
  flex-direction: column;
}
.payment-cell-fraud-info-status{
  width: 100%;
  white-space: normal;
  color: $textGrey;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.payment-cell-fraud-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 25px;
  margin-left: 5px;
}
.payment-cell-fraud-info-count{
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
}
.payment-cell-fraud-alert{
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: $red;
  color: white;
  margin-right: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 1;
  letter-spacing: 0.5px;
}
.payment-cell-fraud-info-small{
  width: 100%;
  white-space: normal;
  color: $mediumGrey;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
