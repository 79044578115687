@import '../../_variables.scss';


.payment-modal{
  padding: $largeSpacing;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  flex-direction: row;
  width: 100%;
  overflow-y: auto;
  height: auto;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }

  input{
    margin: 0;
  }
}
.payment-modal-title{
  font-size: 16px;
  color: $mainBlue;
  font-weight: 600;
  margin-bottom: 15px;
}
.payment-trigger-input-cell{
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin-right: 20px;

  @media screen and (max-width: $mediumScreenWidth){
    flex:1 1 calc(100% - 20px);
    width: calc(100% - 20px);
    margin-right: $largeSpacing;
  }
}
.payment-trigger-input-title{
   font-weight: 500;
  color: $textGrey;
  font-size: 14px;
 }
.payment-modal-input-container{
  display: flex;
  align-items: center;

  input{
    margin: 0;
  }
}
.payment-modal-input-subtitle{
  font-size: $font-size-base!important;
}
.payment-trigger-input-small{
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin-right: 20px;

  .editable-input{
    margin-bottom: 10px;
  }
}

.payment-modal-text{
  font-size: 12px;
  line-height: 1.29;
}
.payment-modal-button-cell{
  display: flex;
  justify-content: flex-end;
}
.payment-modal-button{
  font-size: $font-size-base;
  color: $white;
  background-color: $mainBlue;
  border-radius: 3px;
  font-weight: 600;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-modal-button-disabled{
  font-size: $font-size-base;
  color: $textGrey;
  background-color: $inputBackground;
  border-radius: 3px;
  font-weight: 400;
  margin-right: $mediumSpacing;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-modal-padding{
  margin-right: $smallSpacing;
  margin-left: $smallSpacing;
}
.payment-modal-dual-cell{
  display: flex;
  justify-content: space-between;
}
.payment-modal-dual-spacing{
  margin-right: $largeSpacing;
}
.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.custom-select{
  appearance: none;
  background-image: url("../../Components/collapse-left-button.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center right 10px;
  position: relative;
}
.formControl {
  margin: 20px;
  min-width: 120px;
}
.selectEmpty {
  margin-top: 20px;
}
option{
  position: relative;
  left: 10px;
}

//.payment-modal-switch {
//  position: relative;
//  display: inline-block;
//  width: 60px;
//  height: 34px;
//  font-size: 12px;
//}
//
//.payment-modal-switch input{
//  opacity: 0;
//  width: 0;
//  height: 0;
//}
//.payment-modal-slider-round{
//  position: absolute;
//  cursor: pointer;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: $inputBackground;
//  color: $textGrey;
//  -webkit-transition: .4s;
//  transition: .4s;
//  content: "No";
//  border-radius: 34px;
//}
//.payment-modal-slider-round:before {
//  position: absolute;
//  content: "";
//  height: 26px;
//  width: 26px;
//  left: 4px;
//  bottom: 4px;
//  background-color: white;
//  -webkit-transition: .4s;
//  transition: .4s;
//  border-radius: 50%;
//}
//input:checked + .payment-modal-slider-round{
//  background-color: $mainBlue;
//  color: $white;
//  content: "Yes";
//}
//
//input:focus + .payment-modal-slider-round{
//  box-shadow: 0 0 1px $mainBlue;
//  color: $white;
//}
//
//input:checked + .payment-modal-slider-round:before{
//  -webkit-transform: translateX(26px);
//  -ms-transform: translateX(26px);
//  transform: translateX(26px);
//}
