@import '../../variables';

.manage-patient-user-list-cell{
}
.patient-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto !important;
  padding: 0;
}
.manage-patient-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.manage-patient-name{
  font-weight: 500;
  color: $textGrey;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}
.manage-patient-email{
  font-weight: 300;
  color: $textGrey;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.manage-patient-permission{
  font-weight: 400;
  color: $textGrey;
  font-size: 14px;

  .email{
    font-weight: 400;
    color: $textGrey;
    font-size: 14px;
  }
}
.manage-patient-admin-name{
  display: flex;
  width: 70%;
  padding: $mediumSpacing;

  .profile-picture-container-inner {
    width: 40px;
    margin-right: 5px;
  }
}
//.patient-list-item:nth-last-child(1) {
//  margin-bottom: 300px !important;
//} 
.patient-permissions-cell{
  display: flex;
  justify-content: space-between;
  width: 30%;
  padding: $mediumSpacing;
}
.profile-pic-manage-user{
  flex: 0 0 30px;
}
.manage-patient-contact{
  flex: 1 1 auto;
  overflow: hidden;
}
.manage-patients-user-list-item-cell{
  display: flex;
}
