@import '../../variables';

.close-study-confirmation{
  padding: 0 20px;

  &-warning{
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 21.86px;
    color: #4A4A4A;

    span{
      color: #D90B0B;
      font-weight: 900;
    }
  }

  &-button-container{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-evenly;

    .blue-button-main-container{
      background: #2D81C9;
      border-radius: 10px;

      .blue-button-main{
          min-width: 135px;
          height: 50px;
      }
    }
  }
}

.close-study-auth{
  padding: 0 20px;

  &-body{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0;
  }

  &-button-container{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .white-button-main-container,
    .blue-button-main-container{
      border-radius: $mediumSpacing;
      width: 50%;

      .white-button-main,
      .blue-button-main {
          width: 100%;
          height: 50px;
      }
    }
  }

  &-header{
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
  }

  &-input{
    width: 50%;
    margin-bottom: $mediumSpacing;
  }

  &-signature-pad{
    &-input{
      width: 100%;
      height: 150px;
      border: 1px solid #A8A8A8;
      border-radius: 5px;

      &-container{
        position: relative;
      }

      &-placeholder{
        position: absolute;
        top: 0;
        left: 0;
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        background-color: #EFEFEF;
      }
    }
    &-header{
      text-align: left;
      font-size: $fontMedium;
      color: $textGrey;
      margin-right: auto;
      font-weight: 800;
    }
  }
}