@import '../../../variables';
@import '../../../index.scss';

.virtualized-multiple-select-dropdown{
  background-color: #EEEEEE;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  font-size: 14px;
  border: 1px solid $mediumGrey;
  margin-top: 0!important;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;

  &:focus-visible{
    outline: none;
  }
  &.is-open{
    border: 1px solid $mainBlue !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    background-color: $white !important;
    color: $textGrey;
  }
  &.has-value{
    background-color: $white !important;
  }
  .Select-control{
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .Select-value{
    background-color: #FFFFFF;
    border: 1px solid #2D81C9;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    overflow: hidden;
    padding: 2px;
    width: auto;
    max-width: calc(50% - 8px);
    margin: 3px;
    border-radius: 2px 2px 2px 2px;

    .Select-value-icon{
      margin-right: 4px;
    }
    .Select-value-label{
      height: 20px;
      padding: 0;
      margin-left: 4px;
      color: #4A4A4A;
      position: relative;
      font-size: 13px;
      line-height: 1.5;
    }
  }
  .Select-multi-value-wrapper{
    display: flex;
    flex-wrap: wrap;
    max-height: 120px;
    min-height: 39px;
    padding: 3px;
    width: calc(100% - 30px);
  }
  .Select-input{
    height: 1px;

    input{
      height: 1px !important;
    }
  }
  .Select-menu-outer{
    padding: 0;
    top: unset;
    width: inherit;

    .Select-menu > div:first-child {
      width: auto !important;
    }
  }
}
