@import '../../../../variables';

.survey-builder-year-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .Select-menu-outer{
    width: 100%;
    left: 0;
  }
  .Select-control{
    z-index: 2 !important;
  }
}
.survey-builder-year-question-input{
  width: calc(50% - 10px);
}
