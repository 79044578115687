@import '../../../variables';

.survey-builder-question-cell{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $white;
  background-color: $white;

  ul{
    list-style-type: none;
    padding-inline-start: 0;

    li{
      list-style: none;
    }
  }
}
.survey-builder-question-cell-selected{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $mainBlue;
  background-color: $white;

  ul{
    list-style-type: none;
    padding-inline-start: 0;

    li{
      list-style: none;
    }
  }
}
.survey-builder-question-cell-textarea{
  width: 100%;
  margin-bottom: 0;

  textarea{
    width: 100%;
  }
}
.survey-builder-question-cell .survey-builder-question-cell{
  margin-bottom: 0;
  margin-top: 20px  ;
}
.survey-builder-question-enabled-settings-container{
  font-size: $fontSmall;
  margin-top: 10px;
}
.survey-builder-question-enabled-settings-title{
  color: $mainBlue;
  font-weight: 600;
  font-size: $fontMedium;
  margin-bottom: 3px;
}
.survey-builder-question-enabled-settings-array{
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: 1px;
}
.survey-builder-page-cell-header{
  display: flex;
  align-items: center;
}
.survey-builder-drag-handle{
  margin-right: 10px;
}
.survey-builder-question-cell-input-title{
  width: 250px;
  margin-left: auto;
  margin-bottom: $mediumSpacing;
}
.survey-builder-question-textarea{
  width: 100%;
  margin-bottom: 0;

  textarea{
    width: 100%;
  }
}
