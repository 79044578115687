@import '../../../../variables';


.survey-builder-survey-repeat-input-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid $lightGrey;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
.survey-builder-survey-repeat-input-dropdown{
  margin-left: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;

  &:first-of-type{
    padding-top: 20px;
  }

  &:last-of-type{
    border-bottom: 1px solid $lightGrey;
  }

  .text-input-field{
    width: 100%;
  }
}
