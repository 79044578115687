@import '../../_variables.scss';

.patient-list{
  height: 100%;
  background-color: $lightBlueBackground;
  flex-grow: 1;
  position: relative;
  overflow: visible;
  border-bottom-left-radius: 10px;

  .patient-list-container {
    #patients-list {
      tbody > tr > td, thead > tr > th {
        border-bottom: 1px solid $light-gray;
      }  
    }
  }
}
.patient-list-cell-container{
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  min-height: 200px;
  @include hidden-scrollbar;
}
.manage-patient-list-cell-container{
  height: calc(100% - 65px);

  .dynamic-list-cell-loading-container {
    border-left: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    position: sticky;
    min-width: 44px;
    right: 0;
  }

  .dynamic-list-cell-border{
    border-style: solid;
    border-color: $light-gray;
    border-width: 0 0 1px 0;

    &:first-of-type{
      border-right: 1px solid $light-gray;
    }
  }
  
  .dynamic-list-header-border {
    border: none;
  }

  .dynamic-list-header-menu-space {
    border-left: 1px solid $light-gray;
    background-color: white;
    align-items: center;
    position: sticky;
    min-width: 44px;
    display: flex;
    right: 0;

    img{
      margin-left: auto;
      margin-right: 10px;
    }
  }
}
.patient-list-double{
  background-color: $lightBlueBackground;
  flex-grow: 1;
  position: relative;
  margin-bottom: $largeSpacing;
  overflow: visible;
  border-bottom-left-radius: 10px;
}
.manage-patient-list-container{
  height: calc(100vh - 140px);
}
.patient-list-container{
  height: 100%;
  background-color: $white;
  width: 100%;
  overflow: hidden;
}
.patient-list-title-cell{
  display: flex;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: 30px;
  justify-content: space-between;
  font-size: $fontLarge;
  font-weight: 500;
  color: $textGrey;
  align-items: center;
}
.patient-list-input-large{
  width: 50%;
}
.patient-list-input-small{
  width: 50%;
  position: relative;
}
.patients-group-list-heading {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $primary-gray-1;
  background-color: #dedede;
  padding-left: 10px;
  height: 30px;
}

.patients-group-search-bar {
  height: 30px;
  border-bottom: solid 1px $primary-white;
  display: flex;
  padding-left: 10px;
  img {
    width: 13.9px;
    height: 15px;
    align-self: center;
  }
  input {
    padding-left: 5px;
    border: none;
    outline: none;
    width: 100%;
  }
}

.patient-group-search-results-list{
  width: calc(100%);
  min-width: 270px;
  border-bottom: 1px solid $lightGrey;
}

.patient-group-search-results-list {
  .inbox-item:last-child {
    margin-bottom: 125px;
  }
}

.patient-search-popper{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px $secondary-gray-2;
  padding-left: 20px;
  padding-right: 0;
  width: 400px;
  height: auto;
  margin-top: 10px;
  z-index:3;
}
.input-with-title{
  margin: 5px;
  width: 100%;
}
.inputs-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.button-container{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.button-container-popover{
  display: flex;
  width: calc(100% - 20px);
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.button-container-add-contact{
  display: flex;
  width: calc(100% - 20px);
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 20px;
  margin-bottom: $largeSpacing;
}
.button-margin{
  margin-right: $largeSpacing;
  margin-left: auto;
}

.button-popover{
  margin-right: 20px;
}
.button{
  border-radius: 5px;
  padding: 6px 20px;
  color: $primary-white;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 5px;
}

.blue-button{
  background-color: $panel-head-bg-color;
  border: 1px solid $panel-head-bg-color;
}

.gray-button{
  background-color: $light-gray;
  border: 1px solid $light-gray;
  color: $primary-gray-1;
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;

  &:after {
    content: " ";
    position: absolute;
    top: -5px; // we account for the popover-search-form-import-language padding
    left: -120px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: white;
    box-shadow: -1px -1px 2px  lighten($secondary-gray-2, 20);
  }
}

.patient-search-input{
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: none;
  width: 100%;

  &::placeholder{
    color: #bfbfbf;
  }
}


.ant-select-selection{
  box-shadow: none !important;
  height: unset !important;
  border: none !important;
  border-radius: 5px;
}
.ant-select-selection--single{
  box-shadow: none !important;
  height: unset !important;
  border: none !important;
  border-radius: 5px;
}
.ant-calendar-picker-input{
  box-shadow: none !important;
  height: unset !important;
  border: none !important;
  border-radius: 5px;
}
.ant-input{
  box-shadow: none !important;
  height: unset !important;
  border: none !important;
  border-radius: 5px;
}
.patient-list-header{
  display: flex;
  font-size: $fontSmall;
  color: $mediumGrey;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}
.patient-list-header-cell-large{
  width: 50%;
  padding: $mediumSpacing;
  font-weight: 600;
}
.patient-list-header-cell-small{
  width: 25%;
  padding: $mediumSpacing;
}
.patient-list-header-cell-small-dual{
  width: 25%;
  padding: $mediumSpacing;
  display: flex;

  div{
    width: 50%;
  }
}

.progress-breakdown {
  display: flex;
  margin: 0;
  flex-direction: column;
  width:100%;
}

.progress-breakdown-title {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1;
  font-weight: 600;
}

.progress-breakdown-count {
  font-size: 12px;
  color: #4a4a4a;
  margin-right: 20px;
  line-height: 1;
}

.progress-bar-legend{
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;

  thead {
    th {
      cursor: pointer;
    }
  }
}

tbody > tr > td, thead > tr > th{
  padding: 10px;
  width: 200px;
  word-wrap: break-word;
  vertical-align: baseline;
}

tbody > tr > td {
  vertical-align: inherit;
}

.circle {
  height: 8px;
  width: 8px;
  border-radius: 5px;
  display: inline-block;
  background-color: red;
  margin: auto;
  margin-right: 10px;
}
.progress-bar-legend-circle {
  height: 8px;
  width: 8px;
  border-radius: 5px;
  display: inline-block;
  background-color: red;
  margin-right: 5px !important;
  margin-bottom: 1px !important;
}

span {
  vertical-align: middle
}

.tableTitle {
  margin-right: 5px;
  font-family: Avenir;
  font-size: 12px;
  color: #4a4a4a;
}

.table-coloumn-width{
  width: 44px;
  padding: 0;
}

.table-content{
  font-size: 12px;
  color: #4a4a4a;
  font-family: Avenir-Roman;
}

.freezy-table-column-left {
  background-color: white;
  position: sticky;
  z-index: 1;
  left: 0;
  padding: 10px 20px;

  div.table-content {
    span {
      opacity: 0.5;
    }
  }
}

.freezy-table-column-left.patient-active {
  div.table-content {
      span {
          cursor: pointer;
          opacity: 1;
      }
  }
}

.freezy-table-column-right {
  background-color: white;
  position: sticky;
  z-index: 1;
  right: 0;
  border-left: 1px solid $light-gray;
  text-align: center;
}

tbody .freezy-table-column-left {
  border-right: 1px solid $light-gray;
}

.add-edit-main-container {
  overflow: auto;
  padding: 20px;
  height: 500px;
}

.add-edit-header-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.add-edit-header-footer > span {
  font-family: Avenir-Heavy;
  font-size: 16px;
  color: #2d81c9;
}

.add-edit-popover-menu[role=presentation] {
  z-index: 9100;
  transform: translateX(-16px);
}

.survey-list {
  height: calc(100% - 83px);
  overflow: auto;
  margin: 10px 0;
  padding-right: 10px;
}

.draggable-list {
  min-height: calc(100% - 80px);
}

.add-edit-column {
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;
  padding-right: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background-color: $white;
}

.add-edit-column-name {
  padding: 10px;
  border-radius: 5px;
  background-color: #eee;
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: #4a4a4a;
  display: inline-block;
  width: calc(100% - 62px);
  margin-right: 20px;
  margin-bottom: 0;
}

.add-edit-column-width {
  width: calc(100% - 100px);
}

.add-edit-attached {
  font-family: Avenir-Heavy;
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 20px;
  margin-right: 20px;
}

.add-edit-column-status {
  padding: 10px;
  font-size: 14px;
  color: #4a4a4a;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 0;
  font-family: Avenir-Roman;
  border: solid 1px #a8a8a8;
}

.add-edit-button {
  color: #a8a8a8;
  font-size: 14px;
  min-width: 100px;
  margin-bottom: 0;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #eee;
  font-family: Avenir-Roman;
  vertical-align: middle;
  border: none;
}
.saveButton {
  margin: 0;
  color: #fff;
  background-color: #2d81c9;
}
.manage-survey-dropdown{
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
}
.manage-survey-ques-dropdown {
  width: calc(100% - 20px);
  margin-top: $mediumSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.dropdown-error {
  color: $red;
  font-size: 14px;
  font-weight: normal;
}
button{
  padding: 16px 6px;
  background: white;
  border: 0px;
  color: red;
}
.patient-list-popover-search-container{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  padding-top: $largeSpacing;
}
.patient-list-popover-search-input{
  margin-right: $largeSpacing;
  width: calc(100% - 20px);
  margin-bottom: $mediumSpacing;
}
