@import '../../variables';

.schedule-trigger-extras-container{
  width:100%;
  height:100%;
  display:flex;
  overflow:hidden;
  overflow-y:scroll;
  padding-top:20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction:column;background-color:$white;
}
.schedule-trigger-extras-input{
  width:calc(50% - 10px);
  margin-bottom:20px;
}
.schedule-trigger-extras-dropdown{
  width:calc(50% - 10px);

  .labeled-dropdown{
    margin-right: 0!important;
  }
}
//.schedule-trigger-extras-input-container{
//  display:flex;
//  flex-wrap:wrap;
//  margin-left:20px;
//  flex-direction:row;
//}
.schedule-trigger-extras-add-view{
  color:$mainBlue;
  cursor:pointer;
  display:flex;
  font-weight:600;
  align-items:center;
  margin-left:auto;
  margin-right:auto;
}
.schedule-trigger-extras-cell{
  display:flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.schedule-trigger-extras-cell-start{
  display:flex;
  margin-bottom: 10px;
}
//.schedule-trigger-extras-input-cell{
//  width:100%;
//}
.schedule-trigger-extras-dropdown-small{
  width:calc((100% / 3) - (20px / 2));
  margin-right: 20px;

  &:last-of-type{
      margin-right: 0;
  }
}
.schedule-trigger-extras-dropdown-small-icon{
  width:calc((100% / 3) - (30px / 2) - 8px);
  margin-right: 20px;

  &:last-of-type{
    margin-right: 0;
  }
}
.schedule-trigger-extras-dropdown-inner{
  width:calc((100% / 2) - (20px / 2));
  margin-right: 20px;

  &:last-of-type{
    margin-right: 0;
  }
}
.schedule-trigger-extras-delayed-input{
  width:calc(33% - 20px);
  margin-right: 20px;
}
.schedule-trigger-extras-text-input{
  width: 50%;
  margin-bottom:20px;

  &:last-of-type{
    margin-left: 20px;
  }
}
.schedule-trigger-extras-text-input-full{
  width: 100%;
  margin-bottom:20px;
}
.schedule-trigger-extras-survey-input-cell{
  width:100%;
  padding-left: 20px;
}
.schedule-trigger-extras-cell-inner{
  width:100%;
  display: flex;
  justify-content: space-between;
}
.schedule-trigger-extras-cell-inner-survey{
  width: calc((100% / 3) * 2);
  display: flex;
  justify-content: space-between;
}
.schedule-trigger-extras-add-button{
  justify-content: center;
}
.schedule-trigger-extras-remove-button-container{
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
