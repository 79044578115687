@import '../../../../variables';


.survey-builder-checkbox-question-cell{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.survey-builder-checkbox-input-container{
  display: flex;
  justify-content: space-between;
}
.survey-builder-checkbox-input{
  width: calc(50% - 10px);
}
.survey-builder-checkbox-menu-button{
  margin-top: 5px;
  cursor: pointer;
}
.survey-builder-checkbox-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}
