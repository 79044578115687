@import '../../variables';

.survey-response-cell-important-label-full{
  flex:1 1 auto;
  margin-bottom:10px;
}
.survey-response-cell-important-label-half{
  flex:0 0 calc(50% - 5px);
  width:calc(50% - 5px);
  margin-bottom:10px;

  &:nth-of-type(2){
    margin-left: 10px;
  }
  &:nth-of-type(4){
    margin-left: 10px;
  }
}
.survey-response-cell-important-label-third{
  flex:0 0 calc((100% / 3) - (20px /3));
  width:calc((100% / 3) - (20px /3));
  margin-bottom:10px;

  &:nth-of-type(2){
    margin-right:10px;
    margin-left:10px;
  }
  &:nth-of-type(5){
    margin-right:10px;
    margin-left:10px;
  }
}
.survey-response-cell-important-container{
  display:flex;
  flex-wrap:wrap;
}
