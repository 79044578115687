@import '../../variables';

.dynamic-list-header-container{
  top: 0;
  left: 0;
  color: $textGrey;
  width: 100%;
  display: flex;
  z-index: 4;
  position: sticky;
  flex-wrap: nowrap;
  font-size: 12px;
  background-color: $white;
}
.dynamic-list-header-title-cell{
  padding: 10px;
  border-bottom: 1px solid $lightGrey;

  &:first-of-type{
    padding-left: 20px;
  }
}
.dynamic-list-header-title-cell-selected{
  font-weight: 600;
  padding: 10px;
  display: flex;
  color: $textGrey;
}
.dynamic-list-header-title{
  display: flex;
  height:100%;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.dynamic-list-header-arrow-up{
  margin-left: 5px;
  margin-top: 4px;
}
.dynamic-list-header-arrow-down{
  margin-left: 5px;
  margin-bottom: 4px;
}
.dynamic-list-header-clamped{

  &:first-of-type{
    position: sticky;
    left: 0px;
    z-index: 4;
    background-color: $white;
  }
}
.dynamic-list-header-border{
  border:solid $textLightGrey;
  border-collapse: collapse;
  box-sizing: border-box;
  border-left-width: 0;
}
.dynamic-list-header-menu-space{
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lightGrey;
}
.dynamic-list-header-menu-border{
  border:solid $textLightGrey;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-collapse: collapse;
  border-left-width: 0;
}
.dynamic-list-header-info{
  vertical-align: bottom;
}
.dynamic-list-header-custom-view-container{
  width:100%;
  height:100%;
}
