@import '../../_variables.scss';

.confirmation-modal-container{
  display: flex;
  background-color: white;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
.confirmation-modal-container-text{
  color: black;
  text-align: center;
  font-size: 16px;
}
.confirmation-modal-description-text{
  font-size: 14px;
  font-weight: 600;
  color: $textGrey;
}
.confirmation-modal-confirmation-button-container{
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
