@import '../../variables';

.download-questionnaire-reports-loading-spinner {
    margin-left: 20px;
    width: auto;
    display: block;
}
.download-questionnaire-reports-outer-scroll-container{
    height: calc(100vh - 412px);
    @include hidden-scrollbar;
}
.download-questionnaire-reports-cell-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: flex-start;
}
.download-questionnaire-reports-container{
    padding: $mediumSpacing $largeSpacing;
    border: 1px solid $mediumGrey;
    border-radius: $borderRadius;
    margin-bottom: $largeSpacing;
    height: calc(100vh - 325px);
    font-size: 14px;
    color: $textGrey;
    @include hidden-scrollbar;
    overflow-y: hidden !important;

    svg{
        height: 16px;
        width: 16px;
    }
}
.download-questionnaire-reports-title{
  margin-bottom: 10px;
}
.nested-survey-report-button{
  margin-right: $largeSpacing
}
