@import '../../../index';


.patient-tools-end-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.patient-tools-notes-popover-button{
  background: $light-gray;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 15px;
  width: 30px;
}
.patient-tools-progress-value{
  line-height: 1;
  margin-bottom: 3px;
}
.patient-tools-admin-progress-value{
  margin-right: 5px;
  line-height: 1;
}
.patient-tools-list{
  height: 100%;
}
