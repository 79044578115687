@import '../../../variables';

.SecondaryHeader {}

.secondary-header-container{
  height: 62px;
  background-color: $mainBlue;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  display: flex;
  align-items: center;
  border-bottom-left-radius: $border-radius-panel;
  box-shadow: $boxShadow;
  align-content: space-between;
  position: relative;

  .profile-picture-container-inner{
    width: 30px;
    margin: 0;
  }
  .ui.segment{
    border: none;
    border-radius: 50%;
  }
}
.secondary-header-circle{
  height: 40px;
  width: 40px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $smallSpacing;
  font-size: 16px;
  border-radius: 50%;
  color: $mainBlue;
  cursor: pointer;
}
.secondary-header-title{
  margin-left: 5px;
  color: $white;
  font-size: 16px;
  cursor: pointer;
}
.secondary-header-download{
  margin-left: auto;
}
