@import '../../variables';

:host{
  display: block;
}

.survey-builder-title-cell{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  background-color: $white;
  padding-top: 10px;
  z-index: 3;
  position: relative;
}
.survey-builder-title-blue{
  font-size: 16px;
  color: $mainBlue;
  margin-left: $largeSpacing;
  font-weight: 600;
}
.survey-builder-subtitle{
  color: $textGrey;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-left: $largeSpacing;
  margin-bottom: 10px;
}
.survey-builder-title-button-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.survey-builder-title-button{
  margin-right: 20px;
  max-width: 170px;
  white-space: nowrap;
}
.survey-builder-language-picker-container{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-bottom: $largeSpacing;
  background-color: $white;
  pointer-events: auto;

  svg{
  margin-left: auto;
    margin-top: 5px;
    flex: 0 0 auto;
  }
  .language-picker-container{
    flex: 1 1;
    min-width: calc(100% - 330px);
    max-width: calc(100% - 250px);
  }
  .translation-select-list{
    width: calc(100% - 20px);
  }
}
.survey-builder-info-container{
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  background-color: $white;
  z-index: 4;
  position: relative;
}
.survey-builder-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(100% - 20px);
  }
}

.survey-builder-input-page-options{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width:  calc(100% - 20px);
  }
}
.survey-builder-input-header{
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-switch-container{
  display: flex;
  flex-wrap: wrap;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
}
.survey-builder-switch-cell{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.survey-builder-input-switch{
  width: calc(100% / 3)!important;
  padding-left: 20px;
  border-right: 1px solid $lightGrey;
  padding-right: $largeSpacing;

  &:first-of-type{
    padding-left: 0;
  }

  &:nth-of-type(3n){
    padding-right: 0;
    border-right: none;
  }

  &:nth-of-type(3n + 1){
    padding-left: 0;
  }

  @media screen and (max-width: $largeScreenWidth){
    width: calc(100% / 2)!important;

    &:nth-of-type(3n){
      border-right: 1px solid $lightGrey;
      padding-right: $largeSpacing;
    }

    &:nth-of-type(3n + 1){
      padding-left: 20px;
    }

    &:nth-of-type(odd){
      padding-left: 0;
    }

    &:nth-of-type(even){
      padding-right: 0;
      border-right: none;
    }
  }

  @media screen and (max-width: $smallScreenWidth){
    width: 100%!important;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;

    &:nth-of-type(3n){
      border-right: 0;
      padding-right: 0;
    }

    &:nth-of-type(3n + 1){
      padding-left: 0px;
    }

  }
}
.survey-builder-settings-container{
  padding-bottom: 20px;
  background-color: $white;
  border-bottom: 1px solid $lightGrey;
  z-index: 3;
  position: relative;
}
.survey-builder-input-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  .labeled-input-heading{
    font-weight: 400;
  }
}
.survey-builder-page-cells-container{
  background-color: $lightBlueBackground;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 20px;
  list-style-type: none;
  padding-inline-start: 0;

  li{
    list-style: none;
    //z-index: 1;
  }
}
.survey-builder-navigation-container{
  border-bottom-left-radius: 15px;
  padding-left: 20px;
  border-top: 1px solid $lightGrey;
  background-color: $white;
  box-shadow: 1px 6px 16px 0px rgba(0,0,0,0.1);

  //box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.1);
  //clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  pointer-events: auto;
}
.survey-builder-language-nav-cell{
  top: -1px;
  height: 300px;
  display: block;
  z-index: 3;
  overflow: visible;
  position: sticky;
  background-color: transparent;
  pointer-events: none;
}
.survey-builder-cell-empty-sticky-cell{
  height: 75px;
  width: 100px;
}
.survey-builder-loading-spinner{
  height: calc(100vh - 350px - 90px);
}
.survey-builder-reorder-cell-container{
  min-height: calc(100vh - 327px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: $lightBlueBackground;
}
.survey-builder-cell-empty-sticky-cell-small-modal{
  height: 110px;
  width: 100%;
  flex: 1 1 100%;
  position: relative;
  background-color: $lightBlueBackground;
}
.survey-builder-cell-empty-sticky-cell-small{
  height: 180px;
  width: 100px;
  margin-bottom: -140px;
}
.survey-builder-cell-container{
  display: flex;
}
.survey-builder-cell-container-outer{
  position: relative;
  background-color: $lightBlueBackground;
  top: -150px;
  z-index: 0;
}
.survey-builder-cell-container-outer-modal-small{
  position: relative;
  background-color: $lightBlueBackground;
  top: 0px;
}
.survey-builder-container{
  min-height: calc(100vh - 350px - 184px);
  height: calc(100vh - 350px - 184px);
  overflow: hidden;
  display: initial;
  background-color: $white;
}
.survey-builder-language-picker-preview{
  margin-left: auto;
  margin-top: 20px;
  width: 100%;
  display: flex;
}
.survey-builder-button-container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  min-width: 250px;
  max-width: 310px;
  margin-left: 20px;
}
.survey-builder-title-loading-spinner{
  width: 120px;
}
.create-survey-drawer-button-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: $largeSpacing;

  .white-button-main-container .white-button-main{
    color: $red;
    border:2px solid $red;

    &:hover{
      background-color: $red!important;
      color: $white!important;
    }
  }
}
.create-survey-drawer-input-skip{
  margin-left: auto;
  width: 100%;

  &:first-of-type{
    padding-top: 20px;
  }

  &:last-of-type{
    border-bottom: 1px solid $lightGrey;
  }

  .text-input-field{
    width: 100%;
  }
  input{
    margin-right: 0;
  }
}
.create-survey-drawer-header-left{
  display: flex;
}
.create-survey-drawer-header-text{
  font-size: $fontMedium;
  color: $mainBlue;
  font-weight: 600;
}
.create-survey-drawer-header{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey ;
}
.create-survey-drawer-close{
  color: $textGrey;
  background-color: $white;
  cursor: pointer;
}
.create-survey-drawer-controls{
  margin: 20px;
  margin-top: 0;
}
.create-survey-drawer-switch-input{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid $lightGrey;
  padding-top: 20px;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
.create-survey-drawer{
  position: sticky;
  top: 200px;
  right:0;
  margin-top: 20px;
  width: 250px;
  height: calc(100vh - 383px);
  min-height: 200px;
  background-color: $white;
  box-shadow: $boxShadow;
  flex: 0 0 250px;
}
.create-survey-drawer-position{
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.create-survey-page-cell-container-drawer-open{
  width: calc(100% - 250px);
}
.create-survey-page-cell-container-drawer-closed{
  width: 100%;
}
.create-survey-title{
  font-size: 16px;
  color: $mediumGrey;
  margin-left: $largeSpacing;
  font-weight: 600;
}
.survey-builder-sortable-pages-list{
  list-style-type: none;
  padding-inline-start: 0;

  li{
    list-style: none;
  }
}
.survey-builder-sortable-pages-list-modal{
  list-style-type: none;
  padding-inline-start: 0;
  z-index: 9010;

  li{
    list-style: none;
  }
}
.survey-builder-question-textarea-container{
  background-color: $white;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.survey-builder-input-textarea{
  width: 100%;
  padding-bottom: $mediumSpacing;
}
.test-auto-height{
  display: flex;
  width: 100%;
  align-items: flex-start;

  *{
    flex: 1 1;
  }
}
.survey-builder-scroll-container{
  overflow-y: scroll;
  min-height: 100%;
  height: 100%;
}
.survey-builder-page-cells-container-open-drawer{
  flex: 0 0 calc(100% - 250px);
}
