@import '../../variables';
@import '../../index';


.custom-modal-body-container {
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 10px 0px 10px 0px;

  .subsection-element {
    padding: 0px 20px;
  }

  .search-symptoms {
    display: flex;
    flex-direction: column;
  }

}

.assign-symptoms-main-container textarea{
  font-size: 12px !important;
  border: 1px solid $mediumGrey!important;
  height: 100px!important;
  margin-bottom: $largeSpacing;
  margin-right: $largeSpacing;
  width: calc(100% - 22px);
}
.assign-symptoms-main-container{
  height: 100%
}
.assign-symptoms-body-container {
  @extend .custom-modal-body-container;
  .header-next-state {
    display: flex;
    flex-direction: column;
    .top-section {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      margin-left: 50%;
      margin-right: 20px;
    }
  }

  .extra-notes-input{
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

}

.change-password-body-container {
  @extend .custom-modal-body-container;
  height: auto;
}
.subsection-element{
  display: flex;
  justify-content: space-between;

  .react-datepicker__input-container{
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5;
    vertical-align: bottom;

    input{
      height: 36px;
    }
  }
}

.panel-main-header-title-text-black{
  font-size: 16px;
  font-weight: 400;
  color: $primary-heading-color-1;
  padding: 20px 20px;
  text-align: center;
}
.action-button-container-confirmation{
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;

  .action-button-container{
    width: 100px;
    justify-content: center;
  }
}
.action-button-confirmation{
  width: 100px;
}
.panel-main-header-title-container-confirmation{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
}
.confirmation-modal{
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.create-new-appointment-container{
  display: flex;
  background-color: white;
  height: 450px;
  flex-direction: column;
  padding: 0;

  @extend .custom-modal-body-container;
  height: 450px;
  .header-next-state {
    display: flex;
    flex-direction: column;
    .top-section {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      margin-left: 50%;
      margin-right: 20px;
    }
  }

  .extra-notes-input{
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

}
.assign-symptoms-modal-search-dropdown{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 10px;
}
.assign-symptoms-modal-search-textarea{
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 10px;
}
