@import '../../variables';

.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.user-permission-cell{
  height: 300px;
  width: 200px!important;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  padding: $largeSpacing $mediumSpacing ;
  display: flex;
  margin-left: $mediumSpacing;
}
.user-permission-cell-text{
  font-size: 14px;
  color: $textGrey;
  overflow-wrap: break-word;
  overflow-y: auto;
}
.user-permission-button{
  width: 20px;
  height: 20px;
  margin-right: $mediumSpacing;
  flex: 0 0 20px;
}
