@import '../../../_variables.scss';

.labeled-multiple-select-input-container{
  font-size: 14px;
  color: $textGrey;
}
.labeled-dropdown-active{
  border: 1px solid $mainBlue !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  background-color: $white;
  color: $textGrey;
}
.labeled-dropdown-disabled{
  border:1px solid $inputDisabled;
  color: $inputDisabledText;
  background-color: $inputDisabled;
}
.labeled-dropdown-outer{
  display: flex;
}
.labeled-multiple-select-input-loading{
  position: absolute;
  width: auto;
}
.labeled-multiple-select-input-cell{
  display: flex;
  align-items: center;
  position: relative;
}
.labeled-multiple-select-input-dropdown{
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}
