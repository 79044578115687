@import '../../_variables.scss';

.medical-record-container{
  height: 100%;
  overflow-y: auto;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
}
.medical-record-html-view{
  overflow-y: auto;
  height: calc(100% - 90px);
}
