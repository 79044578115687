$img-path: '/imgs/common';
$bootstrap-sass-asset-helper: true;
$icon-font-path: '/fonts/glyphicon/'; // only for glyphicon
$grid-gutter-width: 50px;

$font-size-base: 14px;
$line-height-base: 1; // 25/14
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~25px

$padding-base-vertical: 6px;
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 1);

$nav-link-padding: 4px ($line-height-computed);
$panel-heading-padding: ($line-height-computed / 2) ($line-height-computed);
$panel-body-padding: $line-height-computed;

$navbar-height: 50px;
$sidebar-controls-visibility: visible;

$font-family-sans-serif: Avenir, sans-serif;
$headings-font-family: Avenir, sans-serif;

$screen-sm-min: 990px;
$screen-md-min: 1281px;
$screen-lg-min: 1440px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$primary-color: #89949B;
$brand-primary: #428bca;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

$zindex-tooltip: 999999999999999999999999999;

$container-sm: 990px;
$container-md: 990px;
$container-lg: 990px;

$btn-height: 30px;
$btn-border-radius-base: 20px;
$btn-padding-base-vertical: 2px;
$btn-padding-base-horizontal: 15px;
$btn-padding-large-base-vertical: 10px;
$btn-padding-large-base-horizontal: 25px;

$btn-font-size: 12px;
$btn-font-weight: bold;
$btn-font-size-small: 10px;
$btn-font-size-xsmall: 8px;

$btn-link-color: #89949B;
$btn-default-color: #B4B7B9;
$btn-primary-color: $brand-primary;
$btn-success-color: $brand-success;
$btn-info-color: $brand-info;
$btn-warning-color: $brand-warning;
$btn-danger-color: $brand-danger;

$dropdown-link-color: rgb(137, 148, 155);
$dropdown-link-hover-bg: rgba(180, 183, 185, 0.05);

$link-color: #2EB398;
$link-hover-color: #2e6b5e;
$link-hover-decoration: none;

$nav-link-color: #aaa;
$nav-disabled-link-color: #777777;
$nav-link-hover-bg: #f5f5f5;
$nav-tabs-link-hover-border-color: #f5f5f5;

$navbar-default-bg: #18405a;
$breadcrumb-bg: #fff;

// Global Style Variables
$primary-white: #fff;
$secondary-gray-1: #9b9b9b;
$secondary-gray-2: #979797;
$padding-horizontal-outer-panel: 20px;
$padding-vertical-nav-panel: 10px;
$padding-body-panel: 20px;
$border-radius-panel: 10px;
$margin-vertical-subsection: 10px;
$margin-horizontal-subsection: 20px;
$margin-vertical-subsection-inner: 5px;
$subsection-heading-font-size: 14px;
$light-gray: #eeeeee;
$primary-gray-1: #4a4a4a;
$primary-gray-2: #f1f0ee;
$input-placeholder-color: #999; 
$primary-heading-color-1: #020202;
$panel-head-bg-color: #16405b;
$semi-bold: 500;
$bold: 600;
$form-field-height: 36px;
$margin-top-panel: 50px;

$paletteColourTransparent: rgba(255,255,255,0);


// Mobile Styles

$mobileScreenWidth: 600px;
$smallScreenWidth: 768px;
$mediumScreenWidth: 1024px;
$largeScreenWidth: 1300px;
$extraLargeScreenWidth: 1500px;

$mobileBorderRadius: 10px;
$mobileIconSize: 24px !important;

$borderRadiusSmall: 5px;
$borderRadius: 10px;

$boxShadow: 0px 0px 5px 2px rgba(0,0,0,0.1);

$noSpacing: 0px;
$smallSpacing: 5px;
$mediumSpacing: 10px;
$largeSpacing: 20px;

$mainBlue: #2D81C9;
$mainBlueHover: #1570BF;
$mainBluePressed: #1F4F81;

$mainRedHover: #F00A0A;
$mainRedPressed: #9C1C1C;

$pink: #E33966;
$orange: #FAA919;
$teal: #36818E;

$lightGreen: #66b033;
$green: #1B7F4F;
$red: #E02020;
$yellow: #f89117;

$textGrey: #4A4A4A;
$textLightGrey: #A8A8A8;
$fontMedium: 14px;
$fontLarge: 16px;
$fontSmall: 12px;

$inputBackground: #EEEE;
$inputDisabled: #EEEE;
$inputDisabledText: #4A4A4A;

$lightGrey: #EEEEEE;
$mediumGrey: #A8A8A8;
$greyButton: #E4E4E4;
$greyButtonHover: #c9c9c9;
$greyButtonPressed: #585858;
$lightBlueBackground: rgba(236,239,241,1);
$white: #FFFFFF;
$black: #000000;

@mixin ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// hides/shows scrollbar visually on hover on/off
@mixin hidden-scrollbar{
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover {
    scrollbar-color: #aaa transparent;
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
    }
  }
}

.ui.table tr td{
  border-top: none!important;
  padding-top: 10px!important;
  padding-bottom: 0px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
.header-row-table th{
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  padding-left: 20px!important;
  padding-right: 20px!important;
}
