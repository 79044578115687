@import '../../variables';

.alternative-contact-cell-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(100% - 20px);
  }
}

.alternative-contact-cell-labeled-phone-number-input{
  align-items: stretch;
}
