@import '../../variables';

.modal-title-cell{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: $largeSpacing;
}
.modal-title{
  font-size: 16px;
  font-weight: 600;
  color: $mainBlue;
  display: flex;
  flex-direction: column;
}
.modal-cell-title{
  justify-content: space-between;
  width: calc(100% - 40px);
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
  font-size: 16px;
  font-weight: 600;
  color: $mainBlue;
  display: flex;
  white-space: nowrap;
}
.modal-button-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.modal-button{
  margin: $largeSpacing;
}
.modal-title-button-container{
  display: flex;
  cursor: pointer;
}
.modal-body{
  padding-left: 0;
  padding-right: 0;
  overflow-y: auto;

  .Select-menu-outer{
    z-index: 9999;
  }
}
.modal-input{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: 20px;
  margin-bottom: $mediumSpacing;
}
.modal-subtitle-text{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
  margin-left: $largeSpacing;
}
.test{
  height: 100px;
  background-color: red;
}
.modal-input-small{
  width: 50%;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.confirm-password-box{
  width: 168px;
}
.modal-dual-input-container{
  display: flex;
  justify-content: space-between;
  margin-left: $largeSpacing;
}
.modal-error{
  color: red;
  font-size: $fontMedium;
  margin-left: $mediumSpacing;
  margin-right: $mediumSpacing;
  margin-bottom: $smallSpacing;
}
.modal-margins{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
}
.modal-switch-container{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  padding-bottom: $largeSpacing;
}
.modal-input-textarea{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-bottom: $mediumSpacing;
  margin-right: $largeSpacing;
}
.modal-body-button-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal-body-call-button{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
}
.modal-loading{
  margin: $largeSpacing;
}
.general-modal-errors{
  width: calc(100% - 20px);
  margin-left: 20px;
}
.general-modal-description-text{
  color: $textGrey;
  font-size: 14px;
  font-weight: 600;
}
