@import '../../variables';
@import '../../index';

.header-next-state {
  display: flex;
  flex-direction: column;

  .top-section {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    margin-left: 50%;
    margin-right: 20px;
  }
}
.search-symptoms {
  display: flex;
  flex-direction: column;
  padding-left: $largeSpacing;
}
.assign-medication-input{
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  width: calc(100% - 20px);
}
.assign-medication-input-textarea{
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  margin-left: $largeSpacing;
  margin-top: $mediumSpacing;
  width: calc(100% - 40px);
}
.assign-medication-form-error{
  font-size: 20px;
  text-align: center;
  color: red;
}
.field-row{
  display: flex;

  .field-group{
    flex: 0 1 50%;
    position: relative;
  }
  .number-input{
    width: 100%;
  }
}
.medication-details{
  padding: 0 20px;
  font-weight: 600;
}
.header-button-container{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .step-level{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.search-medication-dropdown{
  ul {
    max-height: 50vh;
    border-radius: 3px;
    border: solid 1px #979797;
  }

  li{
    margin-left: 10px;
    border-bottom: solid 1px #efefef;
  }
}
.assign-medication-search-medication{
  margin-right: $largeSpacing;
  width: calc(100% - 20px);
  margin-bottom: $mediumSpacing;
}
.assign-medication-modal-medication{
  font-size: $fontLarge;
  margin-bottom: $mediumSpacing;
  margin-top: $smallSpacing;
  font-weight: 600;
  margin-left: $largeSpacing;
}
