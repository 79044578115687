@import '../../../../variables';

.survey-builder-question-important{
  border-bottom: 1px solid $lightGrey;
}
.survey-builder-question-important-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-direction: column;

  .labeled-errors-warning-container{
    width: 100%;
    top: 0;
  }
}
.survey-builder-question-important-input{
  margin-left: auto;
  width: 100%;
  padding-bottom: 20px;
}
