@import '../../_variables.scss';

.manage-user-admin-container-text{
  margin-left: 20px;
  width: 60%;
  font-size: $fontSmall;
}
.manage-users-invite-container{
  width: 100%;
  background-color: $white;
  overflow-y: visible;
  overflow-x: hidden;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
  @include hidden-scrollbar;
}
.manage-users-container-inner{
  height: auto;
  width: 100%;
  overflow: hidden;
  display: grid;
}
.manage-users-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom: $largeSpacing;
  overflow: hidden;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 124px);
}
.manage-users-count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  color: $textGrey;
  font-weight: 500;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;

  span {
    font-size: 14px !important;
    color: $textGrey;
    font-weight: 500;
  }
}

.manage-user-list-header {
  display: flex;
  width: 100%;
  font-size: 14px !important;
  color: $mediumGrey;
  padding-bottom: $smallSpacing;
  margin-bottom: $largeSpacing;
  border-bottom: 1px solid $mediumGrey;
  margin-top: $largeSpacing;
}

.manage-user-list-header-text {
  width: 50%;
  font-size: 14px !important;
  color: $mediumGrey;
}

.manage-users-input-cell {
  display: flex;
  flex-direction: row;
  margin-left: $largeSpacing;
  width: calc(100% - 20px);


  @media screen and (max-width: $mediumScreenWidth) {
    flex-direction: column;
    justify-content: space-between;
  }
}
.manage-users-input-cell-dual {
  display: flex;
  flex-direction: row;
  margin-left: $largeSpacing;
  width: calc(100% - 20px);

  @media screen and (max-width: $mediumScreenWidth) {
    justify-content: space-between;
  }
}
.manage-users-input {
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $mediumScreenWidth) {
    width: calc(100% - 20px);
  }
}
.manage-users-input-small {
  width: calc(50%);

  .text-input-field{
    width: calc(100% - 20px);
  }

  &:last-of-type{
    input{
      margin-right: 0;
      width: 100%;
    }
  }
}

.manage-users-input-large {
  width: calc(100% - 20px);

  .text-input-field{
    width: calc(100% - 20px);
  }
}
.manage-users-title{
  width: 50%;
  font-size: 14px !important;
  color: $textGrey;
  margin-left: $largeSpacing;
  font-weight: 500;
}
.manage-users-permission-container{
  width: auto;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: visible;
  margin-bottom: $mediumSpacing;
  margin-top: $smallSpacing;
  height: 310px;
  max-width: calc(100vw - 40px);
  padding-right: $mediumSpacing;
}
.manage-permission-cell{
}
.manage-users-permission-outer-container{
  overflow: hidden;
  height: 315px;
  width: calc(100% - 0px);
  margin-bottom: 10px;
  margin-right: 10px;
}
.manage-user-button-cell{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
  padding-bottom: 50px;
  margin-top: $mediumSpacing;
}
.manage-scrollbar-container-here {
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  height: 600px !important;

  .scrollbar-container {
    height: 100% !important;
  }
}
.admin-cell-titles{
  display: flex;
  border-bottom: 1px solid $mediumGrey;
}
.admin-cell-title-large{
  display: flex;
  width: 67%;
  padding-left: $largeSpacing;
  padding-right: $mediumSpacing;
  padding-bottom: $smallSpacing;
  padding-top: $mediumSpacing;
  align-items: flex-start;
  font-size: $fontSmall;
  color: $mediumGrey
}
.admin-cell-title-small{
  display: flex;
  width: 33%;
  padding-left: $mediumSpacing;
  padding-right: $largeSpacing;
  padding-bottom: $smallSpacing;
  padding-top: $mediumSpacing;
  align-items: flex-start;
  font-size: $fontSmall;
  color: $mediumGrey
}
.manage-admin-cells-container{
  height: calc(100% - 56px);
  padding-top: $mediumSpacing;
}
.admin-cell-info-container{
  display: flex;
  align-items: flex-start;

  .profile-picture-container-inner{
    margin-right: 5px;
  }
}
.admin-cell-profile-image{
  flex: 0 0 30px;
}
.admin-cell-is-current-user{
  color: $mainBlue !important;
}
.admin-cell-password-change-requested{
  padding-bottom: 0;
  margin: 0;
}
.manage-users-input-group{
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.manage-users-admin-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom: $largeSpacing;
  overflow: hidden;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 124px);
}
.manage-users-admin-invite-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin: $largeSpacing;
}
.manage-users-admin-invite-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  margin-left: $largeSpacing;

  @media screen and (max-width: $mediumScreenWidth) {
    width: calc(100% - 40px);
  }
}
.manage-users-admin-invite-title{
   font-size: 14px !important;
   color: $textGrey;
   margin-left: $largeSpacing;
   font-weight: 500;
 }
