@import '../_variables.scss';

.modal-title-cell{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: $largeSpacing;
}
.modal-title{
  font-size: 16px;
  font-weight: 600;
  color: $mainBlue;
  display: flex;
}
.modal-button-container{
}
.modal-button{
  margin: $largeSpacing;
  margin-left: 0;
}
.modal-title-button-container{
  display: flex;
}
.modal-body{
  height: calc(100% - 70px);
}
.modal-input{
}
.modal-subtitle-text{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
}
