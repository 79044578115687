@import '../../../_variables.scss';

.labeled-info-container{
  color: $textGrey;
  font-size: 14px;
}
.labeled-info-title{
  color: $textGrey;
  width: 100%;
  display: flex;
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.labeled-info-title-bold{
  font-weight: 600;
}
.labeled-info-description{
  color: $mediumGrey;
  font-size: $fontSmall;
  margin-bottom: $mediumSpacing;
}
.labeled-info-asterisk-bold{
  font-weight: 600;
}
