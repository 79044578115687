@import '../../_variables.scss';

.add-report-column-modal-dynamic-list{
  height: 200px;
}
.add-report-column-modal-text{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.add-report-column-modal-add-configuration-container{
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: $fontMedium;
  font-weight: bold;
  color: $mainBlue;
  margin-bottom: 20px;
  cursor: pointer;
}
.add-report-column-modal-add-configuration-text{
  line-height: 1.4;
}
