@import '../../_variables.scss';

.TriggersList {}

.header-row-table{

}
.trigger-text{
  word-wrap: normal;
  white-space: normal;
}
.trigger-list-cell-container{
  height: calc(100vh - 273px);
  @include hidden-scrollbar;
}
.trigger-list-header{
  width: 20%;
  padding: $mediumSpacing;
}
.trigger-list-header-targets{
  width: 20%;
  padding: $mediumSpacing;
  margin-right: 10px;
}
.trigger-header-expanded{
  width: 25%;
}
.trigger-list-title{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  padding-top: $largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 600;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }
}
.trigger-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  padding-left: $mediumSpacing;
  padding-right: $mediumSpacing;
}
.trigger-title-list-search{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
