@import '../../variables';

.trigger-cell{
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-bottom: $mediumSpacing;
  border-bottom: 1px solid $lightGrey;
  padding-left: 10px;
}
.trigger-cell-date{
  width: 20%;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
}
.trigger-cell-typing-container{
  width: 20%;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
  word-break: break-word;
  hyphens: auto;
}
.trigger-cell-timing-container{
  width: 20%;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
}
.trigger-cell-flags-container{
  width: 20%;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
}
.trigger-cell-targets-container{
  width: 20%;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
}
.trigger-cell-expanded{
  width: 25%;
}
.trigger-cell-status-info{
  display: flex;
}
.trigger-cell-status-title{
  display: flex;
  align-items: center;
  height: 24px;
  margin-left: 3px;
}
.trigger-cell-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 25px;
  margin-left: 3px;
  margin-top: 3px;
}
.trigger-cell-alert-info{
  display: flex;
  flex-direction: column;
}
.trigger-cell-alert-status{
  align-items: center;
  display: flex;
}
.trigger-loop-animation{
  animation: spin 4s infinite linear;
}
@keyframes spin {
  0%  {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.trigger-cell-loading-container{
  width: 32px;
  height: 32px;
}
.trigger-cell-date-created{
  display: flex;
  flex-direction: column;
}
.trigger-cell-date-updated{
  display: flex;
  flex-direction: column;
}
.trigger-cell-timing-container-min{}
.trigger-cell-timing-container-max{}
.trigger-cell-targets-container-target{
  display: flex;
  flex-direction: column;
}
.trigger-cell-targets-container-secondary{
  display: flex;
  flex-direction: column;
}
.trigger-cell-typing-container-keyword{
  display: flex;
  flex-direction: column;
}
.trigger-cell-targets-container-survey-consent{
  display: flex;
  flex-direction: column;
}
.trigger-cell-targets-container-additional{
  display: flex;
  flex-direction: column;
}
.trigger-cell-loading-container{
  width: 32px;
  height: 32px;
}
.trigger-container-inputs{
  margin-left: $largeSpacing;
  display: flex;
  flex-wrap: wrap;
}
