@import '../../../../variables';


.survey-builder-medication-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.survey-builder-medication-input{
  width: calc(50% - 10px);
  z-index: 2!important;
}
