@import '../../variables';

.tools-list-container{
  width: 100%;
  height: calc(100vh - 232px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.vaccination-entries-list-container{
  height: 100%;
}
