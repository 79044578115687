@import '../../../_variables.scss';

.blue-button-main-container{
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  position: relative;

  .blue-button-main{
    color: white;
    border: none;
    cursor: pointer;
    height: 40px;
    display: flex;
    font-size: 14px;
    min-width: 100px;
    align-items: center;
    font-weight: 600;
    padding-left: $largeSpacing;
    border-radius: $smallSpacing;
    padding-right:  $largeSpacing;
    justify-content: center;
    background-color: $mainBlue;

    &:hover:not([disabled]){
      background-color: $mainBlueHover;
    }
    &:active{
      border: none;
      background-color: $mainBluePressed;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .button-large{
    height: 50px;
  }
}
.blue-button-loading{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 40px;
}
.blue-button-icon-down{
  font-size: large;
  margin-top: -7px;
  margin-left: 5px;
}
