.call-modal-note-area{
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 95%;
  height: 100%;
  max-height: 150px;
  min-height: 100px;
}

.call-modal-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
}
