@import '../../../../variables';

.survey-builder-date-question-cell{
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}
.survey-builder-date-question-input-container{
  width: 100%;
  display: flex;
}
.survey-builder-date-question-input-min-value{
  width: 140px;
  flex: 0 0 140px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-date-question-input-max-value{
  width: 140px;
  flex: 0 0 140px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-date-question-type-min-input{
  width: 85px;
  flex: 0 0 95px;
  min-width: 75px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;

  .labeled-dropdown{
    min-width: 85px;
  }
}
.survey-builder-date-question-type-max-input{
  width: 85px;
  flex: 0 0 95px;
  min-width: 75px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;

  .labeled-dropdown{
    min-width: 85px;
  }
}
.survey-builder-date-question-label-input{
  flex: 1 1 auto;
}
.survey-builder-date-question-max-label-input{
  flex: 1 1 auto;
}
.survey-builder-date-question-min-input{
  width: 140px;
  flex: 0 0 140px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-date-question-min-number-input {
  width: 60px;
  flex: 0 0 60px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-date-question-max-number-input{
  width: 60px;
  flex: 0 0 60px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-date-question-max-input {
  width: 140px;
  flex: 0 0 140px;
  margin-right: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
