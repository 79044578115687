@import '../../variables';

.survey-links-list-container {
  .dynamic-list-container {
    .dynamic-list-cell-template {
      .survey-link-progress {
        .labeled-input-heading-tools {
          color: $red;
          
          svg {
            color: $primary-gray-1;
          }
        }

      }
    }
  }
}