@import '../../../variables';

.survey-builder-radio-question-input-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.survey-builder-radio-question-input{
  width: calc((100% / 3) - (40px / 3));
  margin-bottom: 20px;

  .labeled-input-heading{
    margin-right: 0;
    width: 100%;
  }
}
.survey-builder-radio-question-choices-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.survey-builder-radio-question-choices-input-container{
  display: flex;
  justify-content: space-between;
}
.survey-builder-radio-question-choice-input{
  width: calc(50% - 10px);
}
.survey-builder-radio-question-menu-button{
  margin-top: 5px;
  cursor: pointer;
}
.survey-builder-radio-question-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}
.survey-builder-question-visibility-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
}
.survey-builder-radio-question-cell{
  padding-top: $largeSpacing;
}
.survey-builder-choices-cell{
  display: flex;
  width: 100%;
  margin-top: $mediumSpacing;
  margin-bottom: $mediumSpacing;
}
.survey-builder-choices-input{
  flex: 1 1 auto;
}
.survey-builder-choice-cell-dropdown{
  flex: 0 0 150px;
  margin-right:$largeSpacing;
}
.survey-builder-choices-input-label{
  flex: 1 1 auto;
  margin-right: $largeSpacing;
}
.survey-builder-choices-menu-button{
  margin-top: 5px;
  cursor: pointer;
}
.survey-builder-choices-input-db-write{
  flex: 1 1 auto;
  margin-left: $largeSpacing;
}
.survey-builder-choice-cell-switch-input{
  width: 110px;
  display: flex;
  justify-content: space-between;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
