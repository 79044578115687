@import '../../../_variables.scss';

.labeled-datepicker-input-container{
  font-size: 14px;
  color: $textGrey;
}
.labeled-datepicker-input-cell{
  color: $textGrey;
  border: none!important;
  height: unset!important;
  display: flex;
  padding: 0!important;
  font-size: 14px;
  align-items: center;
  line-height: normal;

  .react-datepicker-wrapper{
    width: 100%;
  }
  .react-datepicker-popper{
    z-index: 9669;
  }
  .react-datepicker__input-container input{
    height: 39px;
    margin: 0;
    border:1px solid $mediumGrey;
    display: flex;
    padding: $mediumSpacing;
    align-items: center;
    border-radius: $smallSpacing;
  }
  .labeled-datepicker-input-field-inactive input{
    color: $inputDisabledText;
    border: solid 1px $inputDisabled!important;
    outline: none;
    font-size: 14px;
    padding-left: 12px;
    border-radius: 3px !important;
    background-color: $inputDisabled!important;
  }
}
.labeled-datepicker-empty-colour-grey {
  color: $inputDisabledText;
  background-color: $inputDisabled;
  border: solid 1px $inputDisabled!important;
}
.labeled-datepicker-loading{
  right: 10px;
  width: auto;
  position: absolute;
}

