@import '../../_variables.scss';
@import '../../index.scss';

$form-input-large-width: 950px;
$form-input-small-width: 470px;
$form-input-large-font-size: 18px;
$form-input-small-font-size: 12px;
$msg-text-font-size: 12px;
$logo-large-font-size: 20px;
$logo-small-font-size: 14px;
$main-small-text-font-size: 14px;

%login-sign-up {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

%login-sign-up-heading {
  font-weight: $bold;
  text-align: 'left';
  color: #fff;
  line-height: 1.1;
}

.floating-label {
  position:relative;
  display: flex;
  width: auto;
}

#login-msg-and-errors-container {
  @extend .flex-column;
  width: 100%;
  justify-content: space-between;
}

.login-sign-up-logo-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  img {
    margin-right: 13.3px;
  }
  span {
    font-size: $logo-large-font-size;
    color: $primary-white;
    opacity: 0.75;
  }
}

.login-sign-up-container {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0 1000px rgba(1,78,102,0.85) inset !important;
  }

  @extend %login-sign-up;
  background-image: linear-gradient(125deg, #2da69d, #1b76cf);
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-top: 20px;
  .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
    color: rgba(255,255,255,0.5);
  }

  .login-heading {
    @extend %login-sign-up-heading;
    margin-bottom: 25px;
    width: $form-input-small-width;
  }

  .sign-up-heading {
    @extend %login-sign-up-heading;
  }

  .login-signup-container-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}

.error-msg {
  color: #928080;
  background-color: #fff;
  border-color: #928080;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.success-msg {
  color: #1C8A5A;
  background-color: #fff;
  border-color: #1C8A5A;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.floating-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  /****  floating-Lable style start ****/

  .horizontal-form-fields {
    display: flex;
    justify-content: space-between;
  }

  .sign-up-input-small-width {
    display: flex;
  }

  .login-input-width {
    width: $form-input-small-width !important;
  }

  .floating-input-area-code{
    border-radius: 35px;
    border: none;
    height: auto;
    color: #fff;
    width: 100%;
    overflow: hidden;

  }

  .floating-input-area-code:empty{
    border:none;
  }

  .floating-input-area-code:focus {
    outline:none;
  }

  .floating-input {
    border-radius: 35px;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);
    height: auto;
    margin-bottom: 10px;
    color: #fff;
    width: 100%;
    overflow: hidden;
  }

  .floating-input:empty{
    border:none;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .floating-input:focus {
    outline:none;
  }

  label {
    top:10px;
    color: $primary-white;
    font-weight:normal;
    position:absolute;
    left: 30px;
    pointer-events:none;
    align-self: center;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
  }

}

#tandc_msg {
  color: #ffffff;
  margin-left: 20px;
  a {
    color: #ffffff;
  }
}

.forgot-password-button {
  text-decoration: 'none';
  opacity: 0.5;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer
}

.back-button {
  text-decoration: 'none';
  opacity: 0.5;
  font-weight: 500;
  color: #ffffff;
  margin-left: 20px;
  cursor: pointer;
  white-space: nowrap;
}


.sign-up-msg {
  font-size: $msg-text-font-size + 5;
  color: $primary-white;
}

.login-submit-button {
  width: 150px;
  height: 50px;
  border-radius: 35px;
  background-color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: #2d81c9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-up-submit-button {
  border-radius: 35px;
  background-color: #ffffff;
  text-align: center;
  font-weight: 600;
  // color: #e96017;
  color: #2d81c9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pros-preview {
  height: 15vh;
  max-height: 330px;
  width: auto;
}

.login-msg {
  display: flex;
  color: $primary-white; 
  font-size: $msg-text-font-size + 5;
  justify-content: center;
}

.login-signup-link {
  color: $primary-white;
  text-decoration: underline;
  font-size: inherit;
}
.login-spinner{
  color: $white;
  width: 150px;

  div{
    color: $white;
  }
  .ui.loader:after{
    border-color: white transparent transparent;
  }
  ::after{
    color: $white!important;
  }
  ::before{
    color: $white!important;
  }
}

@media only screen and (min-width: $screen-md-max) {

  .floating-form {
    label {
      font-size: $form-input-large-font-size;
      top: $form-input-large-font-size;
    }
  }

  #tandc_msg {
    font-size: $msg-text-font-size;
    margin-top: 25px;
  }

  .login-sign-up-logo-head {
    margin-bottom: 15px;
    
    span {
      font-size: $logo-large-font-size;
    }

    img {
      height: 40px;
      width: 40px;
    }
  }
  .sign-up-input-large {
    width: $form-input-large-width;
  }

  .sign-up-input-small {
    width: $form-input-small-width;
  }

  .horizontal-form-fields {
    width: $form-input-large-width;
  }

  .sign-up-heading  {
    font-size: $logo-large-font-size;
    width: $form-input-large-width;
  }

  .login-heading  {
    font-size: $logo-large-font-size;
  }

  .floating-input{
    padding: 20px 20px 10px 20px !important;
    font-size: $form-input-large-font-size;
  }

  .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
    top: 10px;
    font-size: 14px;
    color: $secondary-gray-1;
  }

  .floating-input-area-code{
    padding: 20px 20px 10px 20px !important;
    font-size: $form-input-large-font-size;
  }

  .floating-input-area-code:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
    top: 10px;
    font-size: 14px;
    color: $secondary-gray-1;
  }

  .sign-up-submit-button {
    width: 280px;
    height: 50px;
    font-size: 20px;
  }
  
} 

@media only screen and (max-width: $screen-lg-min) {
  .login-msg {
    font-size: $form-input-small-font-size;
  }

  .sign-up-heading {
    margin-bottom: 10px;
  }

  #tandc_msg {
    font-size: $main-small-text-font-size;
    margin-top: 10px;
  }

  .login-sign-up-logo-head {
    margin-bottom: 5px;

    span {
      font-size: $logo-small-font-size;
    }

    img {
      height: 20px;
      width: 20px;
    }
  }

  .floating-form {
    label {
      font-size: $main-small-text-font-size;
      top: 14px;
    }
    .forgot-password-button {
      font-size: $main-small-text-font-size;
    }
  }
  
  .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
    top: 3px;
    font-size: 10px;
    color: $secondary-gray-1;
  }
  .floating-input{
    padding: 15px 20px !important ;
    font-size: $form-input-small-font-size;
    
  }
  
  .sign-up-heading  {
    width: $form-input-small-width;
    font-size: $logo-small-font-size;
  }

  .login-heading  {
    font-size: $logo-small-font-size;
  }

  .sign-up-input-large {
    width: $form-input-small-width;
  }

  .sign-up-input-small {
    width: ($form-input-small-width/2) - 5;
  }

  .horizontal-form-fields {
    width: $form-input-small-width;
  }

  .sign-up-submit-button {
    width: 140px;
    height: 45px;
    font-size: $main-small-text-font-size;
  }

  .login-submit-button {
    font-size: $main-small-text-font-size;
    height: 45px;
    width: 140px;
  }

  .sign-up-msg {
    font-size: $main-small-text-font-size;
  }

  .error-msg {
    font-size: $main-small-text-font-size;
  }
  .success-msg {
    font-size: $main-small-text-font-size;
  }
}
.login-add-phone-number-text{
  font-size: $fontMedium;
  color: $white;
  margin-bottom: $mediumSpacing;
}
.login-add-phone-number-container{
  display: flex;
  width: 500px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 300px;
  }

}
.login-add-phone-number-select{
  width: 225px;
  margin-right: $mediumSpacing;
  flex: 0 0 225px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    flex: 0 0 auto;
  }
}
.login-add-phone-number-input{
  width: calc(100% - 0px)!important;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
}
.login-phone-number-cell{

}
.floating-input-phone{
  padding-top: 20px!important;
  padding-bottom: 10px!important;
}
.floating-input-phone-container {
  border-radius: 35px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  overflow: hidden;
  padding-right: 10px;
}
.country-code-option{
  background: rgba(1, 78, 102, 0.99);
  color: #fff;
  font-size: 14px;
}
