@import '../../variables';

.virtual-date-cell{
  margin-bottom: $largeSpacing;
}
.virtual-date-title{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
}
.virtual-appointment-info-cell{
  border:1px solid $mediumGrey;
  padding: $largeSpacing;
  display: flex;
  margin-top: $smallSpacing;
  border-radius: $borderRadius;
  margin-bottom: $mediumSpacing;
}
.virtual-appointment-info-time{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
  width: 130px;
  flex-shrink: 0;
}
.virtual-appointment-info{
  flex: 1 1 auto;
  margin: $noSpacing $largeSpacing;

  svg{
    height: 16px;
    width: 16px;
  }
  path{
    height: 16px;
  }
}
.virtual-appointment-title{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
  margin-bottom: 8px;
}
.virtual-appointment-participants{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: $smallSpacing;
}
.virtual-appointment-notes{
  font-size: 12px;
  color: $textGrey;
}
.virtual-appointment-dots-button{
  height: 29px;
  width: 29px;
}
.virtual-appointment-participant-cell{
  display: flex;
  align-items: center;
  margin-bottom: $smallSpacing;
  padding-right: $mediumSpacing;
}
.virtual-appointment-participant-cell-name{
  color: $mediumGrey;
  font-size: $fontSmall;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.virtual-appointment-participant-cell-circle{
  height: 10px!important;
  width: 10px!important;
  border-radius: 50%;
  background-color: $lightGrey;
  margin-right: $smallSpacing;
  flex: 0 0 10px;
}
