@import '../../variables';

.load-failed-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.load-failed-text{
  color: $textGrey;
  text-align: center;
  font-size: $fontMedium;
  font-weight: 600;
  margin-bottom: $mediumSpacing;
}
