@import '../../../../variables';

.survey-builder-number-question-cell{
  padding-top: 20px;
}
.survey-builder-number-input-container{
  display: flex;
  justify-content: space-between;
}
.survey-builder-number-input{
  width: calc(25% - 15px);

  input{
    margin-right: 0;
    width: 100%;
  }
}
.survey-builder-number-choices-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
    width: 100%;
}
.survey-builder-number-choices-input-container{
  display: flex;
  justify-content: space-between;
}
.survey-builder-number-choices-input{
  width: calc(50% - 30px);

  input{
    margin-right: 0;
    width: 100%;
  }
}
.survey-builder-number-choices-menu-button{
  margin-top: 5px;
  cursor: pointer;
}
.survey-builder-number-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}
