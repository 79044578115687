@import '../../variables';

.call-notes-list-outer-scroll-container{
  flex: 1 1 auto;
  padding-top: 10px;
}
.call-notes-list-blocked{
  font-size: 16px;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
  color: $textGrey;
  margin-top: $largeSpacing;
  height: auto;
}
