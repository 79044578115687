@import '../../_variables.scss';

.survey-to-tool-modal-input-container{
  width: calc(100% - 40px);
  margin-left:$largeSpacing;
  margin-bottom: $largeSpacing;

  input{
    margin-bottom: 0px;
  }
}
.survey-to-tool-modal-switch-container{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  padding-top: 5px;
  padding-bottom: 10px;
}
.survey-to-tool-modal-language-picker-container{
  width: calc(100% - 20px);
  padding-bottom: 10px;
}
