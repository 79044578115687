@import '../../../../variables.scss';

.alternative-contact-section {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  align-content: space-around;

  .alternative-contact-section-fields {
    margin-right: 5vw;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.container-fluid {
  padding-left: 30px;
  padding-right: 0px;
  margin: 0px;
  width: 100%;
  display: flex;

  .row {
    width: 100%;
  }
}

.extra-padding-right{
  padding-right: 10px;
  padding-bottom: 10px;
}

.personal-information-section {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  margin-left: $largeSpacing;
  margin-bottom: 10px;
}
.personal-information-section-alt{
  width: calc(100% - 20px);
  margin-left: $largeSpacing;
  margin-bottom: 10px;
  margin-right: 20px;
}
.personal-information-input{
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.profile-personal-information-input{
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.profile-personal-information-input-full-width {
  width: calc(100% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.profile-research-information-disposition-code{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width:$smallScreenWidth){
    width: calc(100% - 20px);
    margin-right: $largeSpacing;
    margin-bottom: $mediumSpacing;
  }
}
.profile-research-information-consent-confirmed{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width:$smallScreenWidth){
    width: calc(100% - 20px);
    margin-right: $largeSpacing;
    margin-bottom: $mediumSpacing;
  }
}
.profile-research-information-subject-status{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(100% - 20px);
    margin-right: $largeSpacing;
    margin-bottom: $mediumSpacing;
  }
}
.personal-information-input-switch{
  width: calc(50% - 10px);
}

.personal-information-input-small{
  width: 25% !important;
  max-width: 140px;
}

.medication-allergy-modal-input{
  width: 50%;
  margin-left:25px;
}
.contact-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
  align-items: center;
  width: calc(50% - 160px);

}
.patient-profile-cell{
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 300px;
  border-radius: $borderRadius;
  background-color: $white;
  margin-bottom: $largeSpacing;
  overflow-y: auto;
  padding: $largeSpacing;
}
.patient-profile-title{
  color: $textGrey;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 500;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }

  input{
    width: 100%;
    margin-right: 0;
  }
}
.patient-profile-title-input{
  width: 50%;
}
.patient-profile-title-input-icon{
  width: calc(100% - 30px);
}
.patient-profile-title-input-container{
  display: flex;
  width: 50%;
}
.research-dual-input{
  display: flex;
  justify-content: space-between;
  width: 50%;

  .editable-input{
    margin-bottom: 10px;
  }
}
.research-dual-input-large{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.research-input-small-date{
  width: calc(50% - 20px);
  margin-right: 20px;
}
.personal-information-bottom-container{
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: $smallScreenWidth){
    flex-direction: column;
  }
}
.personal-information-bottom-cell{
  width: 50%;
  display: flex;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.personal-information-bottom-switch-cell{
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.personal-information-section-contact-translator-needed{
  width: calc(50% - 20px);
  display: flex;
  margin-right: 20px;
  padding-top: 10px;
}
.personal-information-section-contact-change-password{
  width: calc(50% - 20px);
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px;
  margin-left: auto;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
.personal-information-test-account{
  margin-top: 10px;
}
.personal-information-patient-alerts{
  margin-top: 10px;
}
.profile-contact-information-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(100% - 20px);
  }
}
