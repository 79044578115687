@import '../../_variables.scss';

.audit-history-list-container{
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding-bottom: $largeSpacing;

  .scrollbar-container {
    height: 100% !important;
  }
}
.audit-history-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 162px);
  overflow-y: auto;
}
.admin-history-header-container {
  display: flex;
  width: 100%;

  .admin-history-search-by-specific-patient-input-container,
  .audit-history-search-input-container {
    width: 50%;
    margin-bottom: 10px;
  }
  
  .audit-history-search-input-container {
    margin-right: 20px;
    position: relative;
  }

  .Select-menu-outer > div.Select-menu > div{
    width: 100% !important;
  }

  @media screen and (max-width: $mediumScreenWidth) {
    flex-direction: column;

    .admin-history-search-by-specific-patient-input-container,
    .audit-history-search-input-container {
      width: 100%;
    }
  }
}
