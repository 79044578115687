@import '../../_variables.scss';
@import '../../index.scss';

#sidebar {
  @extend .flex-column;
  margin-top: $margin-top-panel;
  background-color: $lightBlueBackground!important;
  position: inherit !important;
  height: calc(100vh - 50px);

  @media screen and (max-width: $mediumScreenWidth){
    background-color: $lightBlueBackground!important;
  }
}

.sidebar-header {
  @extend .subsection-heading;
  padding: 0px;
  padding-top: $mediumSpacing;
  color: $mainBlue;
  font-size: 14px;
  font-weight: 600;
}

.collapsed-sidebar-header {
  margin-left: 0px;
  display: flex;
  justify-content: center;
}

.groups-list:last-child {
  flex: 1 1 auto;
  overflow-y: auto;
}

.group-nav-item {
  margin-left: 0px;
  margin-right: 0px;
  height: fit-content;

  .groupCircleIcon{
    width: 30px;
    height: 30px;
    z-index: -1;
  }

  a {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #2c3e50;
    padding-left: 0px;
    outline: none;
    text-decoration: none;
  }

  .groups-list-item {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
  }

  .group-name-container{
    display: flex;
    align-items: center;
    text-align: start;
    width: auto;
    max-width: unset!important;
    .name {
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100px;
      display: block;
    }
  }

  .alert-indicator{
    position: absolute;
    right: 10px;
    top: 16px;
  }
}


.groups-container-style {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.expanded-nav-item {
  justify-content: flex-start;
  padding-left: 15px;
}

.collapsed-nav-item {
  justify-content: center;
  padding-left: 0px;
  width: auto;
}

.collapsed-all-patients-nav-item {
  justify-content: center;
}

.all-patients-container-style {
  @extend .flex-row-wrap;
  align-items: center;
  width: 116px;
  z-index: 2;
  border-bottom: solid 1px $light-gray;
  padding-top: 18px;
  padding-bottom: 16px;
  height: auto;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  color: $primary-heading-color-1;
  
  .all-patients-heading {
    font-size: 14px;
    font-weight: $semi-bold;
  }

  #homeIcon {
    font-size: 30px;
  }
}

.sidebar-manage-container-outer-style {
  border-top: solid 1px $white;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;

  &:first-of-type{
    border-top: 0;
    margin-top: 0;
  }

  .manage-container-style {
    margin-top: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;

    #manageIcon {
      height: 23px;
      width: 25px;
    }

    a {

      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #9b9b9b;
    }
  }
}

.sidebar-controls-buttons {
  border-top: solid 1px #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 59px;
  margin-top: auto;
  min-height: 40px;
  button{
    outline: none;
    border:none;
    background: #fff;
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    #collapse-left-button {
      height: 15px;
      width: auto;
    }
  }
}

.manage-container-list-style {
  @extend .flex-column;
}

.manage-container-list-item-style{
  @extend .flex-row-wrap;
  height: 30px;
  align-items: center;
  text-decoration: none;
  font-size: 30px;
  color: $secondary-gray-1;

  @media screen and (max-width: $mediumScreenWidth){
    color: $textGrey;
  }

  &:hover{
    font-weight: 600;
    color: $mainBlue;
  }
  &:active{
    font-weight: 600;
    color: $mainBlue;
  }
}

#sidebar-groups-manage-icon{
  height: 17px;
  width: 30px;
}
#sidebar-users-manage-icon{
  height: 30px;
  width: 30px;
}
#sidebar-patients-manage-icon{
  height: 30px;
  width: 30px;
}
#sidebar-settings-manage-icon{
  height: 30px;
  width: 30px;
}

.manage-nav-tool-tip {
  text-transform:capitalize;
}

.collapsed-manage-nav-item {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sidebar-groups-manage-icon{
  height: 17px;
  width: 30px;
}
.sidebar-users-manage-icon{
  height: 30px;
  width: 30px;
}
.sidebar-patients-manage-icon{
  height: 30px;
  width: 30px;
}

#sidebar li {
  list-style: none;
}
#sidebar ul {
  padding: 1em
}
.sidebar-circle{
  background-color: $white;
  border-radius: 50%;
  font-size: 14px!important;
  font-weight: 600;
}
.sidebar-group-name{
  font-size: 14px!important;
  color: $textGrey;
}
.sidebar-alert{
  margin-right: $largeSpacing;
  font-size: 14px;
}
.alert-indicator{
  font-size: 12px!important;
  font-weight: 500!important;
}
.sidebar-top-container{
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid $white;
  min-height: 62px!important;
  flex:0 0;
}
.sidebar-profile-picture {
  margin-left: $largeSpacing;
  background-color: $white;
  height: 40px;
  width: 40px;
}
.sidebar-logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $white;
  margin-left: $mediumSpacing;
}
.sidebar-user-info{
  display: flex;
  flex-direction: column;
  max-width: 120px;
  margin-left: $mediumSpacing;
}
.sidebar-user-info-text{
  max-width: 120px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  @include ellipsis;
}
.sidebar-bottom-container{
  height: 62px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-top: 1px solid $white;
  min-height: 62px!important;
  flex:0 0;
  position: absolute;
  bottom: 0;
  width: 205px;

  .profile-picture-container-inner{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sidebar-logout{
  color: $mainBlue;
  text-decoration: underline;
  cursor: pointer;
}
.groups-list-spacing{
  padding-left: 15px;
  padding-top: 5px;
}

.groups-list-spacing-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px !important;
  color: #4a4a4a;
  font-weight: 500;
  width: 115px;
  display: block;
}

.groups-list-item {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 15px;

  &.is-active {
    background-color: $mainBlue;

    .groups-list-spacing-name {
      color: $white;
    }

    .name.sidebar-group-name {
      color: $white;
    }
  }

  .circle-container.sidebar-circle {
    align-self: center;
  }
}

.scrollbar-container{
  height: unset!important;
}
.groups-container-content{
  overflow:hidden;
}
.sidebar-header{
  flex: 0 0;
  padding-bottom: $mediumSpacing;
}
.sidebar-manage-nav-item-outer-container {
  height: 40px;
  display: flex;
  align-items: center;

  & > div:first-child {
    width: 100%;
  }

  .manage-nav-item-heading {
    margin-left: 5px;
    color: $textGrey;
    font-size: 14px;
    font-weight: $semi-bold;
    text-transform:capitalize;
  }

  .manage-container-list-item-style {
    width: 100%;
  }

  &:hover{
    color: $mainBlue;
    cursor: pointer;

    .manage-container-list-item-style {
      color: $mainBlue;

      .manage-nav-item-heading {
        font-weight: 600;
        color: $mainBlue;
      }
    }
  }

  &.sidebar-manage-nav-item-active {
    background-color: $mainBlue;

    .manage-container-list-item-style {
      color: $white;

      .manage-nav-item-heading {
        color: $white;
        font-weight: 600;
      }
    }

  }
}
.sidebar-scrollable-container{
  height: 100%;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include hidden-scrollbar;
}
.sidebar{
  position: relative;
}
