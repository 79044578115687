  @import '../../_variables.scss';


.manage-groups-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
.manage-groups-container-create{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  padding-left:$largeSpacing;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 174px);
  @include hidden-scrollbar;
}
.manage-group-search{
  width: 50%;
  margin-left: auto;
  margin-bottom: $largeSpacing;
  margin-right:$largeSpacing;
}
.manage-group-cells-container{
  width: calc(100% - 30px);
  margin-left: $largeSpacing;
  margin-right: $mediumSpacing;
  height: calc(100vh - 280px);
  @include hidden-scrollbar;
}
  .manage-groups-search{
  width: 100%;

  input{
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
  }
}
.manage-groups-user-search{
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: $largeSpacing;

  input{
    margin-bottom: 0;
    width: 100%;
    margin-right: 0;
  }
}
.manage-groups-search-title{
  font-weight: 500;
  color: $textGrey;
  font-size: 14px;
}
.manage-group-name-create{
  width: 50%;
  margin-right: 20px;

  @media screen and (max-width: 600px){
    width: 100%
  }
}
.manage-groups-user-search-input{
  width: 50%;
}
.manage-groups-list{
  margin-right: $largeSpacing;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-top: $largeSpacing;
  margin-bottom: $largeSpacing;
  padding-top: $largeSpacing;
  padding-bottom: $largeSpacing;
  padding-left: $smallSpacing;
  padding-right: $smallSpacing;
}
.manage-group-list-container{
  overflow-y: auto;
  height: 100%;
}
.manage-groups-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
}
.manage-groups-patient-list-container{
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  padding: $mediumSpacing;
  padding-bottom: 0;
  width: calc(100% - 20px);
  min-height: calc(100% - 200px);
  margin-bottom: $largeSpacing;
}
.fixed-height-medium{
  height: auto;
  max-height: 350px;
}
.manage-groups-input-container{
  display: flex;

  @media screen and (max-width: 600px){
    flex-direction: column;
  }
}
.manage-groups-input{
  width: 50%;
  margin-right: 20px;

  @media screen and (max-width: 600px){
    width: 100%
  }
}

.manage-groups-container-create-text{
  width: 100%;
  margin-bottom: 10px;
}
  .manage-groups-create-input-container{
    display: flex;
    margin-bottom: 10px;

    @media screen and (max-width: 600px){
      flex-direction: column;
    }
  }
  .manage-groups-create-dropdown-input{
    width: 50%;
    margin-right: 20px;
    display: block!important;;

    @media screen and (max-width: 600px){
      width: 100%
    }
  }
