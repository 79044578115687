@import '../../../../variables';


.survey-builder-rank-choices-cell{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.survey-builder-rank-choices-input-container{
  display: flex;
  justify-content: space-between;
}
.survey-builder-rank-input{
  width: calc(50% - 20px);
  margin-bottom: $mediumSpacing;

  &:first-of-type{
    margin-right: $largeSpacing;
  }
}
.survey-builder-rank-menu-button{
  margin-top: 5px;
  cursor: pointer;
}
.survey-builder-rank-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}
