@import '../../_variables.scss';

.ManagePayments {}

.header-row-table{

}
.secondary-header{
  height: 62px;
  background-color: $mainBlue;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  padding: $largeSpacing;
  display: flex;
  align-items: center;
  border-bottom-left-radius: $border-radius-panel;
  margin-bottom: $mediumSpacing;
  box-shadow: $boxShadow;
}
.main-panel-inner-container-secondary{
  overflow: visible;
  background-color: $white;
  box-shadow: $boxShadow;
  border-top-left-radius: $border-radius-panel;
  height: calc(100vh - 124px);
}
.manage-payments-new-trigger-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100% - 50px);
  overflow-y: auto;
}
.manage-payments-new-trigger-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom: 70px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.manage-payments-trigger-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
.manage-payments-trigger-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.manage-payments-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
.manage-payments-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.manage-payments-new-trigger-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.manage-payment-account-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}
