@import '../../_variables.scss';

.PaymentAccount {}
.manage-payments-title-container{
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.manage-payments-container-text{
  margin-left: 20px;
  width: calc(50% - 20px);

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.payment-account-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: $largeSpacing;
  margin-bottom: $mediumSpacing;
  margin-top: $mediumSpacing;
  margin-right: 0;
  width: calc(100% - 20px);


  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.payment-text-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 20px);
  }
}
.payment-text-input-dropdown{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 20px);
  }
}
.payment-button-container{
  width: calc(100% - 40px);
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
}
.payment-button{
  margin-left: $mediumSpacing;
}
.payment-error-message{
  width: 100%;
  padding-bottom: $mediumSpacing;
}
.payment-account-title-dropdown{
  width: calc(50% - 20px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 40px);
  }
}
