@import '../../../_variables.scss';

.labeled-phone-number-input-container{
  color: $textGrey;
  font-size: 14px;
}
.labeled-phone-number-input-cell{
  display: flex;
  position: relative;
  align-items: center;
}
.labeled-phone-number-input-loading{
  right: 45px;
  width: auto;
  position: absolute;
}
.labeled-phone-number-input-call-button{
  margin-left: $smallSpacing;
}
