@import '../../../_variables.scss';

.patient-panel-headings-container {
  display: flex;
  align-items: center;
  padding-left: 9px;
  width: auto;
  height: 30px;
  //background-color: #bed5de;
  //border-bottom: solid 1px $primary-white;
  .section-control-button:hover {
    cursor: pointer;
  }
  .section-control-button{
    display: inline-flex;
    align-items: center;
    img {
      width: 8px;
      height: 10px;
    }
    h4 {
      font-size: 14px;
      font-weight: 900;
      text-align: left;
      color: $primary-gray-1;
      text-transform: capitalize;
      margin-left: 8px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

}
