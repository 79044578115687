@import '../../../variables';

.my-profile-row {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.manage-settings-study-container{
  flex: 1 1 auto;
}
.profile-input-container{
  display: flex;
  flex: 1 1 auto;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
    padding-left: 0;
  }
}
.profile-pic-container {
  width: 100%;
  display: flex;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
  .change-pic {
    opacity: 0;
  }
}
.profile-pic-container:hover {
  .change-pic {
    opacity: 1;
  }
}
.verified-tag-between-profile-inputs {
  display: flex;
  align-items: center;
  height: 35px;
  width: 95px;
  justify-content: space-around;
  padding: 0 10px;
  span {
    font-size: 12px;
    color: #16405b;
    font-weight: 500;
  }
  img {
    height: 20px;
    width: 20px;
  }
}
#file-upload {
  display: none;
}
.manage-settings-container{
  width: 100%;
  background-color: $white;
  padding: $largeSpacing;
  padding-right: 0;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 183px);
  overflow-y: auto;
}
.manage-settings-hs-features-container{
  width: 100%;
  background-color: $white;
  padding:0;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 183px);
  overflow-y: auto;
}
.profile-picture-cell{
  width: 100px;
  margin-right: $largeSpacing;
}
.settings-text-input{
  width: 50%;

  @media screen and (max-width: $mediumScreenWidth){
    flex:1 1 100%;
    width: 100%;
  }
}
.manage-settings-profile-first-name{
  width: 50%;
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: $mediumScreenWidth){
    flex:1 1;
    width: calc(100% - 20px);
  }
}
.manage-settings-profile-last-name{
  width: 50%;
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: $mediumScreenWidth){
    flex:1 1;
    width: calc(100% - 20px);
  }
}
.manage-settings-profile-email{
  flex:1 1 calc(100% - 20px);
  width: calc(100% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;
}
.settings-text-input-large{
  flex:1 1 100%;
  width: 100%;
  margin-bottom: 20px;

  input{
    width: 100%;
  }
}
.settings-text-input-long{
  width: 100%;

  input{
    width: 100%;
  }
}
.settings-text-input-long-local-name{
  width: calc(100% - 20px);
  margin-bottom: 10px;
}
.settings-text-input-long-local{
  width: calc(100% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;
}
.manage-settings-number-input-long{
  width:calc(100% - 20px);
  margin-top:20px;
  margin-right:20px;
  margin-bottom:20px;
}
.settings-text-input-edit{
  flex:0 0 calc(100% - 0px);
  width: calc(100% - 0px);

  @media screen and (max-width: $mediumScreenWidth){
    flex:0 0 calc(100%);
    width: calc(100%);
  }
}
.settings-text-input-edit-language{
  flex:0 0 calc(100% - 20px);
  width: calc(100% - 20px);
  margin-right: 20px;

  @media screen and (max-width: $mediumScreenWidth){
    flex:0 0 calc(100% - 20px);
    width: calc(100% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.settings-text-input-edit-password{
  flex:0 0 calc(100% - 20px);
  width: calc(100% - 20px);

  input{
    border: solid 1px $inputDisabled!important;
    color: $inputDisabledText;
    background-color: $inputDisabled!important;
    pointer-events: none;
  }
}
.settings-edit-container{
  display: flex;
  flex: 0 0 50%;
  width: 50%;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: $mediumScreenWidth){
    flex:1 1 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.settings-input-container{
  width: 100%;
  display: flex;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }

  .text-input-field{
    width: calc(100% - 20px);
  }
}
.settings-text-header{
  font-weight: 500;
  font-size: 14px;
  color: $textGrey;
}
.settings-text{
  font-size: 12px;
  color: $mediumGrey;
  margin-bottom: $mediumSpacing;
}
.auto-width{
  width: auto !important;
}
.manage-settings-admin-description{
  font-size: $fontMedium;
  color: $textGrey;
  display: flex;
  flex-direction: column;
}
.manage-settings-last-enrolment-date-input{
  width: calc(100% - 20px);
}

.close-study {
  width: calc(100% - 20px);

  &-content {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #A8A8A8;
  }

  &-separator {
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }

  &-switch {
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    button {
      margin-left: 10px
    }
  }
}
