@import '../../variables';

.article-cell-container{
  box-shadow: $boxShadow;
  margin: $smallSpacing;
  margin-bottom: 10px;
  border-radius: $borderRadius;
  overflow: hidden;
}
.article-cell-header{
  display: flex;
  position: relative;
  align-items: center;
  border-top-right-radius: $borderRadius;
  margin: 15px;
}
.article-cell-source-logo{
  width: 30px;
  height: 30px;
  margin-right: $mediumSpacing;
  border-radius: 50%  ;
}
.article-cell-source-name{
  font-weight: bold;
  color: $textGrey;
  font-size: $fontMedium;
  margin-right: $smallSpacing;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.article-cell-sponsored{
  font-weight: 500;
  color: $mainBlue;
  font-size: $fontMedium;
  margin-left: $mediumSpacing;
}
.article-cell-progress-container{
  position: absolute;
  right: -15px;
  top: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 60px 60px 0;
  border-color: transparent $mainBlue transparent transparent;
}
.article-cell-progress-completed{
  background-color: white;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: -50px;
  font-size: $fontSmall;
  color: $mainBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.article-cell-progress-indicator {
  position: absolute;
  top: 10px;
  right: -50px;
  font-size: $fontSmall;
  border-radius: 50%;
}
.article-cell-image-container{
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.article-cell-image{
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin-bottom: $smallSpacing;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.article-cell-title{
  font-weight: 600;
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: $smallSpacing;
}
.article-cell-short-description{
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: $mediumSpacing;
  height: 100px;
  overflow-y: hidden;
}
.article-cell-created-at{
  color: $textGrey;
  font-size: $fontSmall;
  margin-bottom: $mediumSpacing;
}
.article-cell-footer{
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.article-cell-views-icon{
  color: $mainBlue;
  height: 15px;
  width: 15px
}
.article-cell-view-count{
  color: $textGrey;
  font-size: $fontMedium;
  margin-left:$smallSpacing;
  margin-right: $smallSpacing;
}
.article-cell-survey_has_questions-icon{
  margin-left: $smallSpacing;
  color: $mainBlue;
  height: 15px;
  width: 15px
}
.article-cell-survey_has_questions{
  color: $textGrey;
  font-size: $fontMedium;
  margin-left:$smallSpacing;
}
.article-cell-info{
  margin: 15px;
  margin-top:0;
}
