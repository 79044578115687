@import '../../variables';

.alternative-contact-section-title{
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}

.alternative-contact-section-container{
  margin-top: 15px;
  padding-bottom: $largeSpacing;
}
