@import '../../_variables.scss';

.account-deactivated-page{
  height: 100%;
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.account-deactivated-text{
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: $largeSpacing;
  font-size: $fontLarge;
  max-width: 320px;
}
.account-deactivated-button{
  margin-bottom: 200px;
}
