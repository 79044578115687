@import '../../_variables.scss';

.relationship-configuration-names-modal-filter-cell{
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: calc(100% - 40px);
  margin-right: 20px;
  margin-bottom: 20px;
}
.relationship-configuration-names-modal-filter-cell-config-options{
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: calc(100% - 40px);
  margin-right: 20px;
  margin-bottom: 20px;
}
.relationship-configuration-names-modal-save{
  align-self: center;
  max-width: 170px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}
.relationship-configuration-names-modal-header-delayed-input{
  max-width: calc(100% - 64px);
  min-width: calc(100%/3 - 20px);
  margin-right: 20px;
}
.relationship-configuration-names-modal-configuration-name-input{
  max-width: calc(100% - 44px);
  min-width: calc(100%/3 - 20px);
  margin-right: $largeSpacing;
}
.relationship-configuration-names-modal-body{
  height: 100%;
  max-height: 335px;
  overflow-y: auto;
}
.relationship-configuration-names-modal-show-arguments-container{
  width: calc(100%/3 * 2);
  display: flex;
  justify-content: space-between;
}
.relationship-configuration-names-modal-show-arguments-delayed-input{
  flex: 1 1 calc(50% - 30px);
}
.relationship-configuration-names-modal-show-arguments-dropdown{
  width: calc(50% - 20px);
  margin-right: 20px;
}
.relationship-configuration-names-modal-config-name-delayed-input{
  width: calc(50% - 10px);
}
.relationship-configuration-names-modal-show-config-options-container{
  width: calc(100%/3 * 2);
  display: flex;
  justify-content: space-between;
}
.relationship-configuration-names-modal-empty-button{
  width: 30px;
}
