@import '../../variables';

.article{
  position: relative;
}
.article-close-button{
  position: fixed;
  right: 0px;
  top: 0px;
  height: 40px;
  width: 40px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
}
.article-header{
  display: flex;
  position: relative;
  align-items: center;
  margin: 15px;
  margin-left: 20px;
}
.article-source-logo{
  width: 30px;
  height: 30px;
  margin-right: $mediumSpacing;
  border-radius: 50%  ;
}
.article-source{
  font-weight: 500;
  color: $textGrey;
  font-size: $fontMedium;
  margin-right: $mediumSpacing;
}
.article-sponsored{
  font-weight: 500;
  color: $mainBlue;
  font-size: $fontMedium;
  margin-left: $mediumSpacing;
}
.article-progress-container{
  position: absolute;
  right: -15px;
  top: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 60px 60px 0;
  border-color: transparent $mainBlue transparent transparent;
}
.article-progress-completed{
  background-color: white;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: -50px;
  font-size: $fontSmall;
  color: $mainBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.article-progress-indicator{
  height: 15px;
  width: 15px;
  position: absolute;
  top: 10px;
  right: -50px;
  font-size: $fontSmall;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.article-title-info-cell{
  display: flex;
  margin-left: 20px;
  margin-right: 15px;
}
.article-title-progress-completed{
  margin-right: $smallSpacing;
  background-color: $mainBlue;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-title{
  font-weight: 600;
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: $mediumSpacing;
}
.article-created-at{
  color: $textGrey;
  font-size: $fontSmall;
  margin-bottom: $mediumSpacing;
  margin-left: 20px;
}
.article-footer{
  display: flex;
  margin-left: 20px;
  margin-right: 15px;
}
.article-views-icon{
  color: $mainBlue;
  height: 15px;
  width: 15px
}
.article-view-count{
  color: $textGrey;
  font-size: $fontMedium;
  margin-left:$smallSpacing;
  margin-right: $smallSpacing;
}
.article-image{
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  margin-bottom: $mediumSpacing;
}
.article-survey_has_questions-icon{
  margin-left: $smallSpacing;
  color: $mainBlue;
  height: 15px;
  width: 15px
}
.article-survey_has_questions{
  color: $textGrey;
  font-size: $fontMedium;
  margin-left:$smallSpacing;
}
.article-survey-container{
  margin-bottom: 15px;
}
.article-related-articles-container{
  font-size: $fontMedium;
  font-weight: 500;
  margin-left: 15px;
  margin-right: 15px;
  border-top: 1px solid rgba(0,0,0,0.1);
  padding-top: 10px;
  color: $mainBlue;
}
.article-related-articles-cell-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: $mediumSpacing;
  margin-bottom: 15px;
}
.article-modal-cell{
  width: 100%;
}
.article-list-cell{
  width: calc(20% - 10px);
  min-width: 300px;

  @media screen and (max-width: 1500px) {
    width: calc(25% - 10px);
  }
  @media screen and (max-width: 1100px) {
    width: calc(33% - 10px);
    min-width: 300px;
  }
  @media screen and (max-width: 1000px) {
    width: calc(50% - 10px);
    min-width: 250px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
