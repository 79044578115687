@import '../../variables';

.report-templates-list{
  width: 100%;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
}
