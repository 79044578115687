@import '../../variables';

.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.small-group-user-cell{
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.small-group-user-cell-text{
  font-size: 14px;
  color: $textGrey;
}
.small-group-user-button{
  height: 20px;
  margin-right: $mediumSpacing;
}
.small-group-user-title-container{
  padding-top: $mediumSpacing;
  padding-bottom: $smallSpacing;
  padding-left: $smallSpacing;
  font-size: $fontMedium;
  color: $textGrey;
  display: flex;
  justify-content: space-between;

  svg{
    height: 16px;
    width: 16px;
  }
}
.small-group-user-title-info{
  display: flex;
  align-items: center;
}
.small-group-user-title-circle{
  background-color: $white;
  border-radius: 50%;
  font-size: 14px!important;
  font-weight: 600;
  box-shadow: $boxShadow;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-right: $smallSpacing;
}
.small-group-user-title-text{
  font-size: 16px;
  color: $textGrey;
  font-weight: 500;
  margin-left: $smallSpacing;
}
.small-group-user-count-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $largeSpacing;
  padding-bottom: $mediumSpacing;
}
.patient-user-count{
  font-weight: 500;
  font-size: 14px;
  color: $textGrey;
}
.patient-cell-container{
  display: flex;
  flex-wrap: wrap;
}
