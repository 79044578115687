@import '../../../variables';


.survey-builder-survey-cell{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $white;
  background-color: $white;
}
.survey-builder-survey-cell-selected{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $mainBlue;
}
.create-survey-question-cell-textarea{
  width: 100%;
  margin-bottom: 0;

  textarea{
    width: 100%;
  }
}
.create-survey-question-cell .create-survey-question-cell{
  margin-bottom: 0;
  margin-top: 20px  ;
}
.survey-builder-survey-enabled-settings-container{
  font-size: $fontSmall;
}
.survey-builder-survey-enabled-settings-title{
  color: $mainBlue;
  font-weight: 600;
  font-size: $fontMedium;
  margin-bottom: 3px;
}
.survey-builder-survey-enabled-settings-array{
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: 1px;

  &:last-of-type{
    margin-bottom: 0;
  }
}
.survey-builder-survey-header{
  display: flex;
  align-items: center;
}
.survey-builder-page-drag-handle{
  margin-right: 10px;
}
.survey-builder-survey-input-title {
  width: 250px;
  margin-left: auto;

  .text-input-field {
    width: 100%;
  }

  input {
    margin-right: 0;
  }
}
