@import '../../_variables.scss';

.reports-list-page{
  width: 100%;
  background-color: #FFFFFF;
  height: calc(100vh - 184px);
  padding-top: 20px;
}
.reports-title{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.reports-title-cell{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  padding-top: $mediumSpacing;
  margin-bottom: $largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    display: flex;
  }
}
.reports-title-search{
  width: calc(50% - 20px);
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.reports-title-search-cell{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.reports-search-container{
  width: 200px;
  padding-left: $largeSpacing;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-right: $largeSpacing;
}
.reports-list-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  margin-right: 10px;
  padding-left: 10px;
}
.reports-header-small{
  width: 20%;
  padding: $mediumSpacing;
}
.reports-header-medium{
  width: 60%;
  padding: $mediumSpacing;
}
.reports-list-container{
  width: 100%;
  min-height: 200px;
}
.reports-name{
  input{
    width: 100%;
    margin-bottom: 10px;
  }

  .labeled-input-heading{
    line-height: 1.3;
  }

}
.reports-search-list-container{
  display: flex;
  overflow-y: hidden;
  width: 100%;
  flex: 1 1 auto;
}
.reports-container{
  width: 100%;
  flex: 1 1 auto;
}
.reports-search-title{
  font-weight: 400;
  margin-top: 10px;
  flex: 0 0 auto;
  padding-bottom: 10px;
  color: $mediumGrey;
  font-size: 12px;
  border-bottom: 1px solid #eeeeee;
  margin-right: 10px;
}
.reports-search-status{
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 5px;
}
.reports-search-inputs{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}
.reports-search-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 25px;
}
.reports-title-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 22px;
  margin-left: 5px;
  margin-right: 5px;
}
.reports-search-popover-cell{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
.report-cell-status-info{
  display: flex;
}
.report-loop-animation{
  animation: spin 4s infinite linear;
}
