@import '../../../../variables';


.survey-builder-slider-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.survey-builder-slider-question-input{
  width: calc(25% - 15px);

  input{
    margin-right: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

