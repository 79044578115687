@import '../../variables.scss';

.group-summary-patient-info-container{
  overflow: hidden;
}
.patient-list-summary-container{
  height: calc(100vh - 114px);
  display: block;
  @include hidden-scrollbar;
}
.patient-dynamic-list-container{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;
  height: 350px;
}
.patient-invite-codes-container{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;
  height: 350px;
}
.patient-invite-codes-container-text{
  padding-left: 20px;
  font-size: 15px !important;
  color: #428bca !important;
  font-weight: 600 !important;
}


.group-info-container{
  overflow: hidden;
}
.group-summary-container{
  height: calc(100vh - 114px);
  display: block;
  @include hidden-scrollbar;
}
.group-summary-top-container{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;
  height: 350px;
}
.group-summary-top-container-data{
  background-color: $white;
  width: 100%;
  padding-top: $mediumSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;
  height: 350px;
}
.group-summary-top-container-spam{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;
  height: 350px;
}
.group-summary-top-container-no-scroll{
  height: 350px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow: hidden;

  .patient-list-cell-container{
    height: 250px;
  }
}
.group-summary-virtual-appointment{
  height: 350px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  border-bottom-left-radius: 10px;
  margin-bottom: $largeSpacing;
  overflow: hidden;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;

  .patient-list-cell-container{
    height: 250px;
  }
}
.group-summary-bottom-container{
  height: calc(100vh - 350px - 250px);
  min-height: 350px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow-y: auto;
  @include hidden-scrollbar;
}
.group-summary-bottom-container-symptoms{
  height: calc(100vh - 350px - 250px);
  min-height: 350px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $largeSpacing;
  overflow-y: hidden;
}
.group-tools-end-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-summary-appointments{
  width: calc(100%);
  padding-left: 20px;
  padding-right: 20px;
}
.group-symptoms-list-cell{
  height: 275px;
  padding: $noSpacing $largeSpacing;
  @include hidden-scrollbar;
}
.sidebar-group-wrapper{
  width: 100%;
  height: 100%;
}
.medium-blue{
  font-size: 15px !important;
  color: #428bca !important;
  font-weight: 600 !important;
}
