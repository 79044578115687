@import '../../_variables.scss';

.data-queries-list-container {
    height: 360px;
}
.data-query-date-title-answered{
  font-size: 12px;
  color: $mediumGrey;
  line-height: 1;
}
.data-query-date-title-resolved{
  font-size: 12px;
  color: $mediumGrey;
  padding-top: $smallSpacing;
  line-height: 1;
}
.data-query-popover-textarea{
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
}
