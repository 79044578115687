@import '../../../../variables.scss';

.profile-section-contact-information{
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  margin-left: $largeSpacing;
  margin-bottom: 10px;
}
.profile-section-contact-information-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(100% - 20px);
  }
}
.profile-section-contact-information-input-small{
  width: calc(25% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: calc(50% - 20px);
  }
}
