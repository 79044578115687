@import '../../_variables.scss';


.manage-tools-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
  overflow-y: scroll;
}
.manage-tools-econsent-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
  overflow-y: scroll;
}
.manage-tools-survey-version-collections-list-container{
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  background-color: $white;
}
.manage-tools-survey-lists-container{
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  padding-top: $largeSpacing;
}
.manage-survey-tools-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
  overflow-y: scroll;
}
.manage-survey-tools-builder-container{
  width: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 34px);
  overflow: visible;
  @include hidden-scrollbar;
}
.manage-tools-inner-container{
  height: calc(100vh - 184px);
}
.manage-tools-container-tools{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
  overflow: hidden;
}
.manage-tools-inner-container-tools{
  height: calc(100vh - 243px);
  @include hidden-scrollbar;
}
.manage-tools-table-cell{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $textGrey;
  font-size: 12px;
}
.manage-tools-button{
  width: 30px;
}
.manage-tools-title-container{
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: $largeSpacing;
  font-weight: 500;
}
.manage-tools-title-blue{
  width: 50%;
  font-size: 16px;
  color: $mainBlue;
  margin-left: $largeSpacing;
  font-weight: 600;
}
.manage-tools-permission-outer-container{
  overflow: hidden;
  padding-right: $largeSpacing;
  padding-left: $largeSpacing;
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}
.manage-tools-permission-container{
  width: auto;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: visible;
  margin-bottom: $mediumSpacing;
  margin-top: $smallSpacing;
  height: 195px;
  max-width: calc(100vw - 40px);

  //@media screen and (max-width: $mediumScreenWidth) {
  //  max-width: 800px;
  //}
}
.manage-tools-permission-cell{
  .user-permission-cell{
    height: 180px!important;
  }
}
.manage-tools-user-permission-cell{
  height: 100px !important;
}
.manage-tools-type-container{
  border-bottom: 1px solid $lightGrey;
  padding-top: $largeSpacing;
  background-color: $white;
  width: 100%;
}
.manage-tools-type-container-survey{
  position: relative;
  z-index: 3;
}
.manage-tool-create-container{
  background-color: $white;
}
.manage-tools-tab-bar{
  z-index: 3;
  position: relative;
}
.manage-tools-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.manage-tools-questionnaire-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.manage-tools-econsent-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
