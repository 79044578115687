@import '../../../variables';
@import '../../../index';

.create-tools-button {
  display: flex;
  align-items: center;
  color: $primary-white;
  border: none;
  font-size: 14px;
  font-weight: $semi-bold;
  background-color: #16405b;
  padding: 0px 20px 0px 20px;
}

.create-tools-main-section {
  display: flex;
  height: 100%;
  background-color: $primary-white;
  overflow: none;

  .scrollbar-container{
    height: 350px!important;
  }
}

.tool-preview-container-edit-create {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-preview-container-edit-create-heading {
  color: $primary-gray-1;
  font-size: 16px;
  font-weight: 900;
  margin-top: 2em;
  width: 100%;
  text-align: center;
}
.language-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.tool-language-picker-container{
  display: flex;
  width: calc(100% - 20px);
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: $largeSpacing;
  margin-bottom: $largeSpacing;
  margin-left: $largeSpacing;
}
.tool-subtitle-container{
  display: flex;
  justify-content: space-between;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
}
.tool-subtitle{
  font-size: 14px;
  color: $textGrey;
  width: calc(100% - 20px);
  margin-top: 10px;
}
.tool-text{
  font-size: 12px;
  color: $mediumGrey;
}

.tools-modal-title-cell{
  padding: $largeSpacing;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $textGrey;
}
.tools-modal-title-answer-cell{
  padding: $smallSpacing;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.tools-title{
  font-size: 16px;
  font-weight: 600;
  color: $textGrey;
  margin-left: 5px;
}
.tool-input-switch-required{
  font-size: 14px;
  color: $textGrey;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $largeSpacing;
}
.tools-block-answer-input{
  width: 100%;
}
.create-tool-button-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.tool-blocks-subtitle{
  font-size: 14px;
  color: $textGrey;
  width: calc(100% - 20px);
  margin-top: 10px;
  display: inline-block;
}
