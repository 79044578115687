@import '../../../variables';

.survey-builder-error-popover-text{
  color: $black;
  line-height: 1;
  margin-top: 2px;
}
.survey-builder-error-popover-icon-red{
   color: $red;
  font-size: 20px !important;
  margin-right: 3px;
}
.survey-builder-error-popover-icon-yellow{
  color: $orange;
  font-size: 20px !important;
  margin-right: 3px;
}
.survey-builder-error-icon-red{
   color: $red;
  font-size: 20px !important;
  margin-right: 3px;
}
.survey-builder-error-text-red{
  color: $red;
  font-size: 14px;
}
.survey-builder-error-icon-yellow{
  color: $orange;
  font-size: 20px !important;
}
.survey-builder-error-text-yellow{
  color: $orange;
  font-size: 14px;
  margin-right: 3px;
}
.survey-builder-error-cell{
  display: flex;
  margin-right: 10px;
}
.survey-builder-error-text-green{
  color: $green;
  font-size: 14px;
}
.survey-builder-error-container{
  display: flex;
  margin-top: 15px;
}
.survey-builder-error-message{
  display: flex;
}
.survey-builder-error-popover{
  display: flex;
  align-items: center;
  height: 20px
}
.survey-builder-error-popover-cell{
  display: flex;
  align-items: center;
  height: 20px
}
