@import '../../_variables.scss';

.PaymentTriggersList {}

.header-row-table{

}
.payment-trigger-text{
  word-wrap: normal;
  white-space: normal;
}
.payment-trigger-list-cell-container{
  height: calc(100vh - 273px);
  @include hidden-scrollbar;
}
