@import '../../variables';

.step-level {
  display: flex;
  align-items: center;
  justify-content: center;

  .filled{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $panel-head-bg-color;
    margin-right: 5px;
  }

  .unfilled{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: $btn-default-color;
    margin-right: 5px;
  }
}
