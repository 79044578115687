.show-consent-fields-button{
  background-color: #ec7925;
  width: 100%;
  color: white;
}
.consent-fields-container{
  display: flex;
  flex-direction: column;
}
.consent-name-container{
  display: flex;
  flex-direction: column;
}
.consent-name-label-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.consent-name-label-container{
  margin-bottom: 10px;
}
.consent-name-input{
  margin-bottom: 10px;
}
.consent-signature-placeholder{
  width: 100%;
  height: 150px;
  border: black 1px solid;
  margin-bottom: 10px;
}
.consent-check-container{
  margin-bottom: 10px;
  display: flex;
  flex-direction: row
}
.consent-check-checkbox{
  margin-right: 5px;
}
.consent-signature-label{
  margin-bottom: 10px;
}
