@import '../../variables';
@import '../../index';

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container input {
  width: 100%;
}

.custom-modal-body-container {
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 10px 0px 10px 0px;

  .subsection-element {
    padding: 0px 20px;
  }

  .search-symptoms {
    display: flex;
    flex-direction: column;
  }

}

.create-new-appointment-container textarea{
  font-size: 12px !important;
  height: 100px!important;
  margin-bottom: $largeSpacing;
  margin-right: $largeSpacing;
  width: calc(100% - 24px);
}
.search-patients-virtual-appointments-body-container {
  padding-right: $largeSpacing;

  @extend .custom-modal-body-container;
  .header-next-state {
    display: flex;
    flex-direction: column;
    .top-section {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      margin-left: 50%;
      margin-right: 20px;
    }
  }
  .extra-notes-input{
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 100px;
}

.react-datepicker__time-container {
  width: 100px;
}
.add-appointment-patient-cell-container{
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}
.add-appointment-patient-cell{
  width: 33%;

  @media screen and (max-width: $mediumScreenWidth) {
    width: 50%;
  }

  @media screen and (max-width: $smallScreenWidth) {
    width: 100%;
  }
}
.add-appointment-patient-cell-border{
  border:1px solid $mediumGrey;
  padding-top: $largeSpacing;
  padding-bottom: 0;
  margin-top: $smallSpacing;
  border-radius: $borderRadius;
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  max-height: 250px;
  @include hidden-scrollbar;
}
.add-appointments-title-container{
  font-size: 14px;
  color: $textGrey;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $largeSpacing;
}
.add-virtual-appointment-modal-input{
    width: calc(100% - 40px);
    margin-left: $largeSpacing;
    margin-right: $largeSpacing;
    margin-bottom: $mediumSpacing;
}
.add-virtual-appointment-modal-input-container{
  display: flex;
  justify-content: space-between;
  margin-left: $largeSpacing;
}
.add-virtual-appointment-modal-input-small{
  width: 50%;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.add-virtual-appointment-input-search-patients{
  width: 50%;
}
.add-virtual-appointment-button-container{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
