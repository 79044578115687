@import '../../../variables';

.survey-builder-survey-settings-drawer{
  position: sticky;
  top: 180px;
  right:0;
  margin-top: 20px;
  width: 250px;
  height: calc(100vh - 363px);
  min-height: 200px;
  background-color: $white;
  box-shadow: $boxShadow;
  flex: 0 0 250px;

  @media only screen and (max-height: 850px){
    height: calc(100vh - 210px);
  }
}
.survey-builder-survey-settings-drawer-position{
  height: calc(100vh - 446px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-height: 850px){
    height: calc(100vh - 220px);
  }
}
.survey-builder-survey-settings-header{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey ;
}
.survey-builder-survey-settings-header-text{
  font-size: $fontMedium;
  color: $mainBlue;
  font-weight: 600;
}
.survey-builder-survey-settings-drawer-close{
  color: $textGrey;
  background-color: $white;
  cursor: pointer;
}
.survey-builder-survey-settings-controls{
  margin: 20px;
  margin-top: 0;
}
.survey-builder-survey-settings-button-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .white-button-main-container .white-button-main{
    color: $red;
    border:2px solid $red;

    &:hover{
      background-color: $red!important;
      color: $white!important;
    }
  }
}
.survey-builder-survey-settings-empty-cell{
  height:calc((100vh - 466px) / 2);
  min-height: 80px;
}
.survey-builder-survey-settings-control{
  margin: 20px;
  margin-top: 0;
}
