@import '../../_variables.scss';

.groups-modal-container{
  display: flex;
  overflow-y: auto;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.groups-modal-cell{
  color: $mainBlue;
  height: 30px;
  display: flex;
  font-size: 14px;
  align-items: center;
  padding-left: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  padding-right: 10px;
  background-color: $light-gray;
}
