@import '../../variables';
@import '../../index';

.add-note-main-container textarea{
  font-size: 12px !important;
  border: 1px solid $mediumGrey!important;
  height: 100px!important;
  margin-bottom: $largeSpacing;
  margin-right: $largeSpacing;
  width: calc(100% - 24px);
}
.add-note-main-container{
  position: relative;
  height: 100%;
}
.custom-modal-body-container {
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 10px 0px 10px 0px;

  .subsection-element {
    padding: 0px 20px;
  }

  .search-symptoms {
    display: flex;
    flex-direction: column;
  }
  .panel-main-header-title-text{
    font-size: 12px !important;
  }

}

.assign-symptoms-body-container {
  @extend .custom-modal-body-container;
  height: auto;
  .header-next-state {
    display: flex;
    flex-direction: column;
    .top-section {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      margin-left: 50%;
      margin-right: 20px;
    }
  }

  .extra-notes-input{
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

}

.change-password-body-container {
  @extend .custom-modal-body-container;
  height: auto;
}

.tool-edit-heading-container{
  height: 45px !important;
}
