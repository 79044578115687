@import '../../../_variables.scss';

.labeled-delayed-input-container{
  font-size: 14px;
  color: $textGrey;
}
.labeled-delayed-input-textarea{
  border-radius: 3px !important;
  border: solid 1px $secondary-gray-2;
  color: $primary-gray-1;
  padding-left: 12px;
  font-size: 14px;
  outline: none;
  background-color: $white;
  width: 100%;
  height: 100px;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}
input{
  color: $textGrey;
  width: 100%;
  border:1px solid $mediumGrey;
  height: 39px;
  padding: $mediumSpacing;
  font-size: 14px;
  line-height: normal;
  border-radius: $smallSpacing;
}
.text-input-field-inactive{
  border: solid 1px $inputDisabled!important;
  color: $inputDisabledText;
  background-color: $inputDisabled!important;
}
.input-description-text{
  font-size: $fontSmall;
  color: $mediumGrey;
  margin-bottom: $mediumSpacing;
}
.labeled-delayed-input-loading,
.labeled-delayed-input-clear{
  position: absolute;
  width: auto;
}
.labeled-delayed-input-clear {
  background-color: transparent;
}
.labeled-delayed-input-clear::after{
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.labeled-input-search-button-input{
  position: relative;
  display: flex;
  width: 100%;
}
.labeled-input-search-container{
  display: flex;
  align-items: center;
  position: relative;
}
