@import '../../index.scss';
@import '../../variables.scss';


.patient-profile-container{
  width: 100%;
  background-color: $lightBlueBackground;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 114px);
  position: relative;
  padding-top: 5px;
  @include hidden-scrollbar;
}

.patient-profile-main-header {
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 2;

  .patient-profile-panelprofile-header-container{
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;

    .patient-profile-panelprofile-pic {
      border-radius: 50%;
      border: none;
      height: 30px;
      width: 30px;
    }

    .patient-profile-panelprofile-name {
      margin-left: 10px;

      a {
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        border-bottom: dashed 1px #0088cc;
      }

    }
  }
}

.patient-panel-body-container {
  @extend .flex-column;
}

.patient-panel-profile-information-editable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
}

.patient-panel-additional-info {
  width: 100%;

  @extend .flex-column;
  .list-outer-container{
    margin-top: 10px;


    .list-inner-container{
      padding: 0px;

    }
  }
}

.patient-profile-main-container {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  height: 100%;
}

.symptoms .subsection-element{
  margin-top: 10px;
}
.upcoming-virtual-appointments{
  width: 100%;
  border-bottom: 1px solid #fff;
}
.virtual-appointments-history{
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #fff;
}

.sidebar-survey-wrapper{
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
}

.stacked-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  margin: 3px;
}

.drawer-header-split{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  border-bottom: 1px solid gray;
}

.no-background-button{
  display: flex;
  border: none;
  color: black;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 15px;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.no-background-button:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.clipboard-img-main {
  height: 14px;
  width: auto;
  margin-right: 5px;
}
.patient-profile-cell{
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 300px;
  border-radius: $borderRadius;
  background-color: $white;
  margin-bottom: $largeSpacing;
  overflow-y: auto;
  padding: $largeSpacing;
}
.patient-profile-title{
  color: $textGrey;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  margin-top: $largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 500;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }

  input{
    width: 100%;
    margin-right: 0;
  }
}
.patient-profile-title-alt{
  color: $textGrey;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right:$largeSpacing;
  margin-bottom: $mediumSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 500;
  }
  span{
    color: $textGrey;
    font-size: 16px!important;
    font-weight: 500;
  }

  input{
    width: 100%;
    margin-right: 0;
  }
}
.patient-profile-input-large{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.patient-summary-container-short{
  height: 360px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow-x: hidden;
  @include hidden-scrollbar;
}
.patient-summary-container-vaccination-entries{
  height: 360px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom: 0;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow-x: hidden;
}
.patient-summary-container-short-alt{
  height: 360px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
}
.patient-summary-container{
   height: 400px;
   background-color: $white;
   width: 100%;
   padding-top: $largeSpacing;
   padding-bottom:$largeSpacing;
   border-bottom-left-radius: 10px;
   box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
   overflow-x: hidden;
   @include hidden-scrollbar;
 }
.patient-tools-summary-container{
   height: 400px;
   background-color: $white;
   width: 100%;
   padding-top: $largeSpacing;
   border-bottom-left-radius: 10px;
   box-shadow: 0 0 5px 2px rgba(0,0,0,0.05);
   overflow-x: hidden;
 }
.patient-summary-container-appointments{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  overflow: hidden;
}
.patient-summary-container-payments{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  overflow: hidden;
}
.patient-profile-call-log-container{
  width: 100%;
  height: 400px!important;
  overflow: hidden;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  background-color: $white;
  border-bottom-left-radius: 10px;
}
.patient-summary-container-data{
  height: 600px;
  background-color: $white;
  width: 100%;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
}
.patient-summary-container-data-query{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
}
.patient-summary-container-scroll{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
  padding-top: $largeSpacing;
}
.patient-summary-container-data-query-second-tab{
  height: 400px;
  background-color: $white;
  width: 100%;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  overflow: hidden;
  padding-top: $largeSpacing;
}
.patient-summary-container-scroll-notes{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
}
.patient-profile-additional-app-columns-container{
  height: 400px;
  background-color: $white;
  width: 100%;
  padding-top:$largeSpacing;
  padding-bottom:$largeSpacing;
  border-bottom-left-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  margin-bottom: $mediumSpacing;
  overflow: hidden;
}
.patient-profile{}

.groups-inner-container{
  display: flex;
  width: 100%;
  align-items: center;
}
.group-list-title-cell{
  display: flex;
  justify-content: space-between;
  font-size: $fontLarge;
  font-weight: 500;
  color: $textGrey;
  align-items: center;
  width: 100%;
}
.conditions-inner-container {
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  width: calc(100% - 40px);
  margin-bottom: 20px;
}
.patient-profile-conditions-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
  margin-top:24px;
}
.patient-profile-conditions-year{
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.conditions-inner-cell{
  width: 50%;
  display: flex;
justify-content: space-between;
  align-items: center;
}
.scrollbar-data-scroll{
  height: 338px!important;
  min-height: 250px;
  overflow-y: auto;
  margin-bottom: $smallSpacing;
}
.patient-profile-tool-container{
  overflow-y: auto;
  margin-bottom: $smallSpacing;
  border-bottom: 1px solid $lightGrey;
}
.profile-alternative-contact-list{
  height: 306px;
  overflow-y: auto;
}
.patient-list-item-cell{
  display: flex;
  padding-top: $mediumSpacing;
  padding-bottom: $mediumSpacing;
  align-items: center;
  justify-content: space-between;
  font-size: $fontMedium;
  color: $textGrey;
}
.patient-list-item-title-circle{
  background-color: $white;
  border-radius: 50%;
  font-size: 14px!important;
  font-weight: 600;
  box-shadow: $boxShadow;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-right: $mediumSpacing;
}
.call-note-history-container{
  overflow-y: auto;
  margin-left: 20px;
}
.patient-profile-tool-viz-container{
  height: 485px;
  overflow-y: auto;
}
.patient-profile-loading-spinner-container{
  height: calc(100vh - 115px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}
.drawer-header-left{
  display: flex;
}
.medication-no-medications-found{
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.symptom-no-symptoms-found{
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.allergy-no-allergies-found{
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.medication-allergy-no-medication-allergies-found{
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.group-no-groups-found{
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.condition-no-conditions-found{
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.alternative-contact-list-no-alternative-contacts-found{
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.health-care-provider-list-no-health-care-providers-found{
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}
.conditions-dropdown-loading-container{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.circle {
  height: 8px;
  width: 8px;
  border-radius: 5px;
  display: inline-block;
  background-color: red;
  margin: auto;
  margin-right: 10px;
}

.progress-breakdown-count {
  font-size: 12px;
  color: #4a4a4a;
  margin-right: 20px;
}

.progress-breakdown-title {
  font-weight: 600;
  font-size: 14px;
  color: #4a4a4a;
}

.tag-block {
  width: 30%;
  display: inline-block;
  margin-bottom: 20px;
  padding-right: 20px;
}

.progress-title {
  font-family: Avenir-Heavy;
  font-size: 12px;
  color: #4a4a4a;
  margin-bottom: 5px;
} 
.patient-profile-tools-features-econsents{
  height: 380px;
}
.patient-profile-tools-questionnaire{
  height: 380px;
}
