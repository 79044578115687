@import '../../../../variables';

.survey-builder-list-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: $mediumSpacing;
  align-items: flex-end;
}
.survey-builder-list-question-cell-input{
  flex: 1 1 auto;
}
.survey-builder-list-question-cell-button-container{
  display: flex;
}
.survey-builder-list-question-cell-button-second{
  margin-left: 20px;
}

