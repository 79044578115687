@import '../../variables';

.PaymentTriggerCell {
  border-radius: 10px;
  border: 1px solid $textGrey;
  color: $textGrey;
  margin: $largeSpacing;
  overflow: hidden;
}
.payment-trigger-cell-container-first{
  color: $textGrey;
  padding: $largeSpacing;
  padding-top: 15px;
  padding-bottom: $mediumSpacing;
  border-bottom: 1px solid $textGrey;
}
.payment-trigger-cell-container-second{
  color: $textGrey;
  padding: $largeSpacing;
  padding-top: $mediumSpacing;
  padding-bottom: 0;
}
.payment-trigger-cell-title-cell{
  color: $textGrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $fontLarge;
  font-weight: 600;
}
.trigger-cell-table-header{
  border: none!important;
}
.payment-criteria-icon{
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: $textGrey;
  margin-right: 2px;
}
.payment-status-cell{
  display: flex;
  align-items: center;
}
.payment-trigger-group-cell{
  max-height: 160px;
  overflow-x: hidden;
  width: 100%;
  padding-right: $smallSpacing;
  @include hidden-scrollbar;
}
.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.payment-trigger-info-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $mediumGrey;
  display: flex;
}
.payment-trigger-info-header-cell-large{
  padding: $mediumSpacing;
  width: 60%;
  display: flex;
}
.payment-trigger-info-header-cell-small{
  padding: $mediumSpacing;
  width: 20%;
  display: flex;
}
.payment-trigger-info-body{
  color: $textGrey;
  font-size: $fontSmall;
  display: flex;


  &:hover{
    background-color: $lightGrey;
  }
}
.payment-trigger-info-body-cell-large{
  padding: $mediumSpacing;
  width: 60%;
  display: flex;
  align-items: center;
}
.payment-trigger-info-body-cell-small{
  padding: $mediumSpacing;
  width: 20%;
  display: flex;
}
