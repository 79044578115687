@import '../../variables';

.alert-indicator{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $btn-danger-color;
  border-radius: 10px;
  height: 20px;
  line-height: 1;
  font-size: 12px;
  padding-left: 7px;
  padding-right: 7px; 
  width: fit-content; 
}
