@import '../../../_variables.scss';

.labeled-textarea-input-container{
  font-size: 14px;
  color: $textGrey;
}
.textarea-field{
  border-radius: 3px !important;
  border: solid 1px $secondary-gray-2;
  color: $primary-gray-1;
  padding-left: 12px;
  font-size: 14px;
  outline: none;
  background-color: $white;
  width: 100%;
  height: 100px;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
}
.textarea-field-inactive{
  border: solid 1px $inputDisabled!important;
  color: $inputDisabledText;
  background-color: $inputDisabled!important;
}
.textarea-field::-webkit-input-placeholder {
  color: $input-placeholder-color;
}
.textarea-field:-moz-placeholder {
  color: $input-placeholder-color;
}
.manage-tools-empty-colour-grey {
  background-color: $inputDisabled;
  color: $inputDisabledText;
}
.labeled-textarea-cell{
  position: relative;
  display: flex;
}
.labeled-textarea-input-loading{
  position: absolute;
  right: 15px;
  top: 15px;
  width: auto;
}
