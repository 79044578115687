@import '../../variables';


.articles-list-container{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  width: calc(100% - 40px);
  margin-top: $largeSpacing;
  height: calc(100vh - 202px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.articles-list-title{
  width: 100%;
  font-size: $fontMedium;
  font-weight: 500;   
  flex: 0 0 70px;
  height: 100px;
  display: flex;
  flex-direction: column;
}
.articles-list-search{
  width: 100%;

  input{
    margin-right: 0;
    margin-bottom: 0;
  }
}
.articles-list-article-topics-container{
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: $mediumSpacing;
}
.articles-list-cell-container{
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: flex-start;
}
.articles-list-cell{
  width: calc(20% - 10px);
  min-width: 300px;

  @media screen and (max-width: 1700px) {
    width: calc(25% - 10px);
  }
  @media screen and (max-width: 1300px) {
    width: calc(33% - 10px);
    min-width: 300px;
  }
  @media screen and (max-width: 1000px) {
    width: calc(50% - 10px);
    min-width: 250px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
