@import '../../variables';

.survey-lists-container{
  width: 100%;
  height: calc(100vh - 183px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.survey-lists-list-outer-scroll-container{
  flex: 1 1 auto;
  padding-top: $mediumSpacing;
  height: calc(100vh - 190px);
}
