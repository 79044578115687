@import '../../variables';

.loading-container-top{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .loading-text{
    margin-bottom: $mediumSpacing;
  }
}
.loading-container-left{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .loading-text{
    text-align: right;
    margin-right: $mediumSpacing;
  }
}
.loading-container-right{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;

  .loading-text{
    text-align: right;
    margin-left: $mediumSpacing;
    margin-bottom: 0;
  }
}
.loading-container-bottom{
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  .loading-text{
    margin-top: $mediumSpacing;
    margin-bottom: 0;
    margin-left: $smallSpacing;
  }
}
.loading-text{
  color: $textGrey;
  font-size: $fontMedium;
  font-weight: 600;
}
