@import '../../variables';

.health-care-provider-information-input{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
  }
}
