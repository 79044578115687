@import '../../_variables.scss';

.tool-schedule-modal{
  height: calc(100% - 20px);
}
.tool-schedule-modal-date-input{
  width: calc(50% - 10px);
}
.tool-schedule-modal-dual-input-container{
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
}
.tool-schedule-modal-text-input{
  width: calc(50% - 10px);
  margin-top: $mediumSpacing;
}
.tool-schedule-modal-dropdown{
  width: calc(50% - 10px);
  margin-top: $mediumSpacing;
}
