@import '../../../_variables.scss';

.labeled-text-input-container{
  font-size: 14px;
  color: $textGrey;

  input.is-empty:not(.is-focused) {
    background-color: $inputBackground;
    border: none;
    outline: none;
  }
}
.labeled-text-input-cell{
  display: flex;
  align-items: center;
  position: relative;
}
.text-input-field{
  color: $primary-gray-1;
  width: 100%;
  border: solid 1px $secondary-gray-2;
  outline: none;
  font-size: 14px;
  padding-left: 12px;
  border-radius: 5px;
  background-color: $white;
}
.text-input-field-inactive{
  border: solid 1px $inputDisabled!important;
  color: $inputDisabledText;
  background-color: $inputDisabled!important;
}
.text-input-fields::-webkit-input-placeholder {
  color: $input-placeholder-color;
}
.text-input-fields:-moz-placeholder {
  color: $input-placeholder-color;
}
.input-description-text{
  font-size: $fontSmall;
  color: $mediumGrey;
  margin-bottom: $mediumSpacing;
}
.labeled-text-input-loading{
  position: absolute;
  right: 15px;
  width: auto;
}
