@import '../../_variables.scss';

.payment-trigger-large{
  padding: $largeSpacing;
  padding-right: 0;
  padding-left: 0;
}
.trigger-container{
  padding: 0;
  display: block;
  background-color: $white;
  width: 100%;
  overflow-y: auto;
  height: auto;

  input{
    margin: 0;
  }
}
.trigger-dual-input-container{
  display: flex;
  justify-content: space-between;
  margin-left: $largeSpacing;

  @media screen and (max-width: $smallScreenWidth) {
    flex-direction: column;
  }
}

.trigger-input-small{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  display: block;

  @media screen and (max-width: $smallScreenWidth) {
    width: calc(100% - 20px);
  }
}
.trigger-input{
  width: calc(100% - 20px);
  margin-left: $largeSpacing;
}
.trigger-input-cell {
  font-size: $font-size-base;
  color: $textGrey;
  margin-bottom: $mediumSpacing;

  @media screen and (max-width: $smallScreenWidth) {
    width: 100%;
  }
}
.trigger-input-title{
  font-weight: 500;
  padding-right: 20px;
}
.trigger-text{
  font-size: 12px;
  line-height: 1.29;
}
.payment-modal-title{
  font-size: 16px;
  color: $mainBlue;
  font-weight: 600;
  margin-bottom: 15px;
}
.payment-trigger-input-cell{
  display: block;
}
.payment-trigger-input-title{
   font-weight: 500;
  color: $textGrey;
  font-size: 14px;
  margin-top: $smallSpacing;
 }
.payment-modal-input-container{
  display: flex;
  align-items: center;

}
.payment-modal-input-subtitle{
  font-size: $font-size-base!important;
}
.payment-trigger-input-small{
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin-right: 20px;

  .editable-input{
    margin-bottom: 10px;
  }
}
.payment-trigger-cell{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.payment-trigger-input-row{
  width: calc(100% - 40px);
  margin-right: $largeSpacing;
  margin-left:$largeSpacing;
}

.payment-modal-text{
  font-size: 12px;
  line-height: 1.29;
}
.payment-modal-button-cell{
  display: flex;
  justify-content: flex-end;
}
.trigger-blue-button{
  font-size: $font-size-base;
  color: $white;
  background-color: $mainBlue;
  border-radius: 3px;
  font-weight: 600;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.trigger-blue-button-disabled{
  font-size: $font-size-base;
  color: $textGrey;
  background-color: $inputBackground;
  border-radius: 3px;
  font-weight: 400;
  margin-right: $mediumSpacing;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-modal-padding{
  margin-right: $smallSpacing;
  margin-left: $smallSpacing;
}
.payment-modal-dual-cell{
  display: flex;
  justify-content: space-between;
}
.payment-modal-dual-spacing{
  margin-right: $largeSpacing;
}
.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.custom-select{
  appearance: none;
  background-image: url("../collapse-left-button.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center right 10px;
  position: relative;
}
.formControl {
  margin: 20px;
  min-width: 120px;
}
.selectEmpty {
  margin-top: 20px;
}
option{
  position: relative;
  left: 10px;
}
.payment-trigger-text-input{
  display: flex;
  align-items: center;
  margin-bottom: $largeSpacing;

  @media screen and (max-width: $smallScreenWidth){
    flex-direction: column;
    align-items: flex-start;
  }
}
.payment-trigger-text-input-dropdown{
  margin-left: $smallSpacing;
  margin-right: $smallSpacing;
  width: 60%;
  flex: 1 1 auto;
  margin-bottom: 20px;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.margin-top{
  margin-top: $mediumSpacing;
}
.trigger-button-container{
  width: calc(100% - 40px);
  margin-top: $mediumSpacing;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  display: flex;
  justify-content: flex-end;

  .loading-container{
    width: 40px;
  }
}
.payment-trigger-large-dual-input{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
//.payment-modal-switch {
//  position: relative;
//  display: inline-block;
//  width: 60px;
//  height: 34px;
//  font-size: 12px;
//}
//
//.payment-modal-switch input{
//  opacity: 0;
//  width: 0;
//  height: 0;
//}
//.payment-modal-slider-round{
//  position: absolute;
//  cursor: pointer;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: $inputBackground;
//  color: $textGrey;
//  -webkit-transition: .4s;
//  transition: .4s;
//  content: "No";
//  border-radius: 34px;
//}
//.payment-modal-slider-round:before {
//  position: absolute;
//  content: "";
//  height: 26px;
//  width: 26px;
//  left: 4px;
//  bottom: 4px;
//  background-color: white;
//  -webkit-transition: .4s;
//  transition: .4s;
//  border-radius: 50%;
//}
//input:checked + .payment-modal-slider-round{
//  background-color: $mainBlue;
//  color: $white;
//  content: "Yes";
//}
//
//input:focus + .payment-modal-slider-round{
//  box-shadow: 0 0 1px $mainBlue;
//  color: $white;
//}
//
//input:checked + .payment-modal-slider-round:before{
//  -webkit-transform: translateX(26px);
//  -ms-transform: translateX(26px);
//  transform: translateX(26px);
//}
.trigger-button{
  margin-left: 20px;
  margin-bottom: 10px;
}
