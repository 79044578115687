@import '../../variables';

.modal-subtitle{
  display: flex;
  align-items: center;
  padding: 10px;
  //background: #85aec0;
  background: #bed5de;
}

.modal-section{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  align-content: space-around;
  background: #deeaee;
}

.summary-detail-item{
  margin-top: 5px;
  margin-bottom: 5px;
}

.error{
  color: red;
  text-align: center;
}

.call-summary-modal-dual-input-container{
  display: flex;
  margin-left: $largeSpacing;
}
.call-summary-modal-caller{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-attendees{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-type-of-visit{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.call-summary-modal-date-time{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;

  .labeled-input-heading{
    font-weight: $bold;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .manage-reports-datepicker-colour-grey {
    border: none !important;
  }
}
.call-summary-modal-call-duration-in-minutes{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-call-duration{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-visit-desposition{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.call-summary-modal-survey-name{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.call-summary-modal-notes-optional{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-purpose-of-visit{
  width: calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.call-summary-modal-notes-optional{
  width: calc(100% - 40px);
  margin-left: 20px;
}
