@import '../../../variables';

.survey-builder-question-settings-drawer{
  position: sticky;
  top: 180px;
  right:0;
  margin-top: 20px;
  width: 250px;
  height: calc(100vh - 363px);
  min-height: 200px;
  background-color: $white;
  box-shadow: $boxShadow;
  flex: 0 0 250px;
  z-index: 3;
}
.survey-builder-question-settings-drawer-modal{
  position: sticky;
  top: 180px;
  right:0;
  margin-top: 20px;
  width: 250px;
  height: calc(100vh - 319px);
  min-height: 200px;
  background-color: $white;
  box-shadow: $boxShadow;
  flex: 0 0 250px;
  z-index: 9001;

  @media only screen and (max-height: 850px){
    height: calc(100vh - 210px);
  }
}
.survey-builder-question-settings-drawer-position{
  height: calc(100vh - 446px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 3;
}
.survey-builder-question-settings-drawer-position-modal{
  height: calc(100vh - 401px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  @media only screen and (max-height: 850px){
    height: calc(100vh - 220px);
  }
}
.survey-builder-question-settings-header{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrey;
}
.survey-builder-question-settings-drawer-close{
  color: $textGrey;
  background-color: $white;
  cursor: pointer;
}
.survey-builder-question-settings-header-text{
  font-size: $fontMedium;
  color: $mainBlue;
  font-weight: 600;
}
.survey-builder-question-settings-controls{
  margin: 20px;
  margin-top: 0;
}
.survey-builder-question-settings-controls-input-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid $lightGrey;
  padding-top: 20px;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }

  .labeled-errors-warning-container{
    width: 100%;
    top: 0;
  }
}
.survey-builder-question-settings-button-container{
  width: 100%;
  display: flex;
  justify-content: center;

  .white-button-main-container .white-button-main{
    color: $red;
    border:2px solid $red;

    &:hover{
      background-color: $red!important;
      color: $white!important;
    }
  }
}
.survey-builder-question-settings-empty-cell{
  height: calc((100vh - 466px) / 2);
  min-height: 80px;
}
