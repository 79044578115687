@import '../../variables';

.healthcare-provider-information-section-title{
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: $bold;
  color: $primary-gray-1;
}

.healthcare-provider-information-section-container{
  margin-top: 15px;
}