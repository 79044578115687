@import '../../../../index';
@import '../../../../variables';

.patient-panel-subsection {
  @extend .flex-column;
  flex: 1;
  padding: 10px 20px;
  border: solid 1px #ffffff;
}

.patient-panel-subsection-left-border {
  border-top: none;
  border-left: none;
}

.patient-panel-subsection-right-border {
  border-top: none;
  border-left: none;
  border-right: none;
}

.patient-panel-section{
  @extend .flex-row-wrap;
  width: 100%;
  justify-content: space-between;

  .add-button-container {
    align-self: flex-end;
  }

  .conditions {
    @extend .patient-panel-subsection;
    @extend .patient-panel-subsection-left-border;

    .conditions-inner-container {
      display: flex;
      justify-content: space-between;
      margin-top: 1px;
      margin-left: $largeSpacing;
      margin-right: $largeSpacing;
    }
  }

  .groups {
    @extend .patient-panel-subsection;
    @extend .patient-panel-subsection-right-border;

    .groups-inner-container {
      display: flex;
      margin-left: $largeSpacing;
      margin-right: $largeSpacing;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      .group-search {
        height: 36px;
        width: 400px;
        margin-right: 19px;
      }
    }
  }
}

.medications {
  @extend .patient-panel-subsection;
  @extend .patient-panel-subsection-left-border;
  .medications-inner-container{
    margin-top: 10px;
    .medication-search-container{
      display: flex;
      width: auto;
      flex-wrap:wrap;

    }
    .reminders-set-container{
      margin-top: 10px;
      #num-reminders {
        height: 36px;
        width: 50px;
      }
      #reminder-type {
        height: 36px;
        width: 100px;
      }

      .list-item {
        justify-content: flex-start;
        align-items: center;
        .list-item-name2{
          font-size: 14px;
          font-weight: 500;
          color: $primary-gray-1;
        }
        div {
          .list-item-name2{
            font-size: 14px;
            font-weight: 500;
            color: $primary-gray-1;
          }
        }
      }
    }
  }
}

.medications-list-item {
  width: calc(100% - 40px);
  display: flex;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  justify-content: space-between;

  .remove-button-main-container {
    display: none;
  }
  .edit-button-main-container {
    display: none;
  }
}
.medications-type{
  font-size: 14px;
  color: $textGrey;
  font-weight: 600;
}
.medications-reminders{
  font-size: 12px;
  color: $mediumGrey;
  font-style: italic;
}
.medications-list-item:hover {
  .remove-button-main-container {
    display: flex;
    margin-right: 240px;
  }
  .edit-button-main-container {
    display: flex;
  }
}

.symptoms-list-item {
  max-width: 400px;
  .remove-button-main-container {
    display: none;
  }
  .edit-button-main-container {
    display: none;
    margin-right: -50px;
  }
}

.symptoms-list-item:hover {
  .remove-button-main-container {
    display: flex;
  }
  .edit-button-main-container {
    display: flex;
    margin-right: -50px;
  }
}

.symptoms {
  @extend .patient-panel-subsection;
  @extend .patient-panel-subsection-right-border;
}

.fc-event-main{
  color: #4a4a4a!important;
  background-color: #eeeeee!important;
  border: none!important;
  width: 100%;
}
.fc-timegrid-col{
  background-color: #FFFFFF!important;
}
.fc{
  background-color: #FFFFFF!important;
}
.fc-view-harness{
  height: calc(100vh - 315px)!important;
}
.manage-appointment-hover{
  visibility: hidden;
  display: flex;
  align-items: center;
}

.manage-appointment-hover-week{
  visibility: hidden;
  display: flex;
}
.manage-appointment-icon-cell{
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.manage-appointment-icon-week-cell{
  display: flex;
}
.manage-appointment-icon-cell:last-of-type{
  margin-right: 0;
}
.manage-appointment-cell:hover{
  .manage-appointment-hover{
    visibility: visible;
  }
}
.manage-appointment-cell:hover{
  .manage-appointment-hover-week{
    visibility: visible;
  }
}
.manage-appointment-icon{
  font-size: 19px!important;
  margin-left: 5px;
  margin-right: 3px;
}
.manage-appointment-icon-week{
  font-size: 15px!important;
  margin-left: 3px;
  margin-right: 3px;
}
@media only screen and (max-width: $screen-sm-min) {
  .medications {
    flex: 1 100%;
  }
}
.current-time-cell {
  background-color: rgb(240,241,239);
  display: flex;
  justify-content: flex-end;
  color: black;
  padding: 0 10px;
  font-size: 15px!important;
}
.manage-appointment-icon-add{
  font-size: 15px!important;
  margin-right: 5px;
}
.fc .fc-daygrid-day-frame{
  max-height: 150px;
  height: 150px;
  overflow: auto;
}
.subsection-element{
  padding: 0 10px;
  height: auto;
  display: flex;
}
.subsection-element-new-appt{
  padding: 0;
  height: auto;
  display: flex;
  flex-direction: column;
}
.full-calender-all-day{
  height: 100px!important;
}
.fc .fc-daygrid-day.fc-day-today{
  background: rgb(55,177,179)!important;
  color: white!important;
  border: none !important;
  height: 100px!important;

  a{
    color: white!important;
  }
  div{
    color: white!important;
  }
}
.fc-v-event{
  border: rgb(238,238,238)!important;
}
.fc th, .fc td{
  a{
    color: black!important;
  }
}
.patient-profile-input{
  width: 50%;
}
