@import '../../_variables.scss';

.fraud-events-title{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.fraud-events-title-cell{
  color: $textGrey;
  font-size: $fontLarge;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;

  div{
    color: $textGrey;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    display: flex;
  }
}
.fraud-events-title-search{
  width: calc(100% - 20px);
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.fraud-events-title-search-cell{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.fraud-events-search-container{
  width: 500px;
  padding-left: $largeSpacing;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin-right: 10px;
}
.fraud-events-list-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  margin-right: 10px;
}
.fraud-events-header-small{
  width: 20%;
  padding: $mediumSpacing;
}
.fraud-events-header-medium{
  width: 20%;
  padding: $mediumSpacing;
}
.fraud-events-header-large{
  width: 50%;
  padding: $mediumSpacing;
}
.fraud-events-name{
  flex: 0 0;
  margin-right: 20px;
  margin-bottom: 10px;

  .labeled-input-heading{
    line-height: 1.3;
  }

}
.fraud-events-search-list-container{
  display: flex;
  overflow-y: hidden;
}
.fraud-events-container{
  flex: 1 1 auto;
  width: 60vw;
}
.fraud-events-search-title{
  font-weight: 400;
  margin-top: 10px;
  flex: 0 0 auto;
  padding-bottom: 10px;
  color: $mediumGrey;
  font-size: 12px;
  border-bottom: 1px solid #eeeeee;
  margin-right: 10px;
}
.fraud-events-search-status{
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 5px;
}
.fraud-events-search-inputs{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;

  .labeled-input-heading-tools{
    white-space: pre-wrap;
    width: 170px;
  }
}
.fraud-events-search-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 25px;
}
.fraud-events-title-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 22px;
  margin-left: 5px;
  margin-right: 5px;
}
.fraud-events-search-popover-cell{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
}
.fraud-event-table-five-columns{
  width: 20%!important;
}
.fraud-event-table-four-columns{
  width: 25% !important;
}
.fraud-event-table-three-columns{
  width: calc(100% / 3)!important;
}
.fraud-events__action-menu[role=presentation] {
  z-index: 9100;
}
.fraud-event-cell-alert-status{
  display: flex;
}
