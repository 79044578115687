@import '../../../variables';

//.manage-survey-links-draggable-list{
//  width: 430px;
//  margin: 10px auto;
//  padding: 15px;
//
//  div{
//    margin-bottom: 0!important;
//  }
//}
//.item {
//  overflow: hidden;
//  transform-origin: 30% 50% 0px;
//  max-height: 100%;
//  padding: 20px;
//  padding-bottom: 5px;
//  border: 2px solid $white;
//  border-bottom: 0;
//  background-color: $white;
//  box-shadow: $boxShadow;
//  clip-path: inset(0px -7px 0px -7px);
//}
//.item:hover .dragHandle,
//.item.dragged .dragHandle {
//  visibility: visible;
//}
//.item h2 {
//  margin: 0;
//}
//.item .subtitle {
//  font-weight: bold;
//}
//::-webkit-scrollbar {
//  -webkit-appearance: none;
//  width: 7px;
//}
//::-webkit-scrollbar-thumb {
//  border-radius: 4px;
//  background-color: rgba(0, 0, 0, 0.5);
//  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
//}
//.survey-builder-reorder-cell-content{
//}
//.survey-builder-reorder-cell-title-container{
//  display: flex;
//  align-items: center;
//}
//.survey-builder-reorder-cell-drag-handle{
//  margin-right: 10px;
//}
//.survey-builder-reorder-cell-title{
//  font-weight: 600;
//}
//.survey-builder-reorder-cell-input{
//  width: 100%;
//  margin-right: 0;
//  margin-bottom: 0;
//}

.survey-builder-reorder-cell{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 3px;
  margin-bottom: 5px;
  border: 2px solid $white;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
}
.survey-builder-reorder-cell-title-container{
  display: flex;
  align-items: center;
}
.survey-builder-reorder-cell-title{
  font-size: $fontMedium;
  color: $textGrey;
  margin-left: 5px;
  line-height: 1;
  font-weight: bold;
}
.survey-builder-reorder-cell-edit-container{
  display: flex;
  align-items: center;
}
.survey-builder-reorder-outer-cell{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.survey-builder-reorder-indent{
  font-size: $fontLarge;
  color: $textGrey;
  width: 40px;
  font-weight: 900;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
