@import '../../variables';

.survey-drawer-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  border-bottom: 1px solid gray;
}
.survey-drawer-title-cell{
  display: flex;
}
.survey-drawer-title-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  margin: 3px;
}
.survey-drawer-survey-component-container{
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
}
