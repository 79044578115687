@import '../../../_variables.scss';

.labeled-switch-input-cell{
  font-size: 14px;
  color: $textGrey;
  flex-direction: column;
  margin-top: 3px;
  position: relative;

  .labeled-errors-warning-container{
    top: 0;
    width: 100%;
  }
}
.labeled-input-heading{
  font-size: 14px;
  font-weight: 500;
  color: $textGrey;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-right: $largeSpacing;
}
.editable-input{
  border:1px solid $mediumGrey;
  color: $textGrey;
  padding: $mediumSpacing;
  height: 39px;
  border-radius: $smallSpacing;
  line-height: normal;
  font-size: 14px;
  margin-bottom: $largeSpacing;
  margin-right: $largeSpacing;
}
.labeled-switch-input-container-right{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
.labeled-switch-input-container-bottom{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.labeled-switch-labeled-info{
  width: 100%;
}
.labeled-switch-cell-right{
  display: flex;
  align-items: center;
  margin-left: auto;
}
.labeled-switch-cell-bottom{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.labeled-switch-input-loading{
  position: relative;
  width: auto;
  margin-right: 5px;
}
