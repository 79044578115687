@import '../../_variables.scss';

.survey-builder-dynamic-list-question-cell{
  margin-top: $largeSpacing;
  display: flex;
}
.send-survey-link-modal-input-email{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.send-survey-link-modal-input-locale{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.send-survey-link-modal-input-id-message{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.send-survey-link-modal-input-email-subject{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.send-survey-link-modal-input-textarea-email-body{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
}