@import '../../../../variables';

.translation-select-list{
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 20px);
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 20px;
  margin-top: 20px;
}

.translation-select-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $textGrey;
  margin-right: 20px;
  padding: 10px 20px;
  cursor: pointer;
  flex: 0 0;
}

.translation-select-button.selected,
.translation-select-button:hover,
.translation-select-button:active,
.translation-select-button:focus{
  background: #2D81C9;
  border: 1px solid $mainBlue;
}

.translation-select-button.selected *,
.translation-select-button:hover *,
.translation-select-button:active *,
.translation-select-button:focus *{
  color: white!important;
}

.translation-select-title{
  color: $textGrey;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.translation-select-name{
  color: #4a4a4a;
  font-size: 12px;
  word-wrap: normal;
  white-space: nowrap;
}
.language-picker-container{}
.language-select-description-title{
  color: $textGrey;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 5px;
}
