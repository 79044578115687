@import './_variables.scss';
@import '~react-quill/dist/quill.snow.css';
@import '~react-datepicker/dist/react-datepicker.css';

select {
  background-color: $primary-white;
}

html {
  overflow: hidden;
}

a {
  text-decoration: none;
}

body {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  padding: 0px;
  -webkit-text-fill-color: $primary-gray-1;
  padding-left: 12px;
}

.display-mobile{
  display: none;

  @media screen and (max-width: $mediumScreenWidth){
    display: block;
  }
}

.display-web{

  @media screen and (max-width: $mediumScreenWidth){
    display: none;
  }
}

.flex-row {
  display: flex;
}

.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.align-items-flex-start {
  align-items: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
}


.vertical-spacer-25 {
  height: 25px;
}

.vertical-spacer-20 {
  height: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.permissions {
  display: inline;
}

.inline-flex {
  display: inline-flex;
}

.subsection-heading {
  font-size: $subsection-heading-font-size;
  font-weight: $semi-bold;
  color: $secondary-gray-1;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.subsection-heading-2 {
  font-size: $subsection-heading-font-size;
  font-weight: $semi-bold;
  color: $primary-gray-1;
}

.subsection-heading-3 {
  font-size: 12px;
  font-weight: 600;
  color: $primary-gray-1;
}

select option:disabled {
  color: $input-placeholder-color !important;
  font-weight: bold;
}

// Form Field Styles //

.radio-inline {
  display: inline-flex;
  padding: 0;

  span {
    margin-left: 20px;
  }

}

.radio-inline input[type="radio"] {
  margin: 0;
}

.text-input-fields {
  border-radius: 3px !important;
  border: solid 1px $secondary-gray-2 !important;
  color: $primary-gray-1;
  padding-left: 12px;
  font-size: 14px;
  outline: none;
}

.text-input-fields::-webkit-input-placeholder {
  color: $input-placeholder-color;
}
.text-input-fields:-moz-placeholder {
  color: $input-placeholder-color;
}

.form-field-margin-right {
  margin-right: 10px;
}

.form-field-200-36 {
  @extend .text-input-fields;
  width: 200px !important;
  height: $form-field-height !important;
}

.form-field-300-36 {
  @extend .text-input-fields;
  width: 100% !important;
  height: $form-field-height !important;
}

.form-field-100-36 {
  @extend .text-input-fields;
  width: 100px !important;
  height: $form-field-height !important;
}

.form-field-105-36 {
  @extend .text-input-fields;
  width: 105px !important;
  height: $form-field-height !important;
}

.form-field-800-36 {
  @extend .text-input-fields;
  width: 800px !important;
  height: $form-field-height !important;
}

.horizontal-form-fields-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.manage-input-boxes {
  width: 80% !important;
  height: 36px !important;
  background-color: $primary-white !important;
}

// PANEL STYLES
.panel-main-header-title-text {
  font-size: 16px;
  font-weight: 600;
  color: $primary-white;
}

// MAIN HEADER
.panel-main-header {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  border-radius: 0;

  @media screen and (max-width: $mediumScreenWidth){
    border-bottom-left-radius: $mobileBorderRadius;
    border-bottom-right-radius: $mobileBorderRadius;
    border-top-left-radius: 0;
    border-top-right-radius: 0px;
    margin-bottom: $mediumSpacing;
  }


  .panel-main-header-title-container  {
    margin-left: 20px;
  }

  .panel-main-header-modal-title-container {
    display: flex;
    align-items: center;
    flex: 1;
  }

  #left-section{
    display: flex;
    align-items: center;
  }

  #right-section {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: $btn-font-size;
    color: white;
    border-radius: 15px;
    border: solid 1px $primary-white;
    padding: 7px 20px;

    @media screen and (max-width: $mediumScreenWidth){
      padding: $smallSpacing;
      border: none;
      border-radius: 0;

      svg{
        margin-right: 0!important;
        font-size: $mobileIconSize;
      }

      span{
        display: none;
      }
    }
  }
}

.panel-circle {
  background-color: $primary-white;
  margin-left: 20px;
  border:none;
}


// subsection 
.panel-subsection {
  margin-bottom: 20px;
}

.panel-subsection-title {
  font-size: 14px;
  font-weight: 600;
  color: $textGrey;
}

.panel-subsection-title-container{
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  justify-content: space-between;

  @media screen and (max-width: $mediumScreenWidth){
  }

  .panel-subsection-title-color-block {
    width: 5px;
    height: 20px;
    margin-right: 15px;

    @media screen and (max-width: $mediumScreenWidth){
      display: none;
    }
  }
}

// -- MANAGE GROUP -- //
.group-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  span{
    font-size: 14px!important;
  }
  .group-circle-letter {
    font-size: 16px;
    font-weight: $bold;
  }
}

.manage-circle-container {
  display: flex;
  border-radius: 50%;
  border: solid 2px $light-gray;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.subsection-container {
  padding: 0 $margin-horizontal-subsection !important;
}

.subsection-element {
  margin-bottom: $margin-vertical-subsection !important;
}

.subsection-inner-element {
  margin-bottom: $margin-vertical-subsection-inner !important; 
}

.todo-item {
  border-bottom: 1px solid #eee;

  button {
    display: none;
    margin-top: 8px;
  }

  &:hover {
    background: rgb(254, 254, 244);

    button {
      display: inline-block;
    }
  }
}

.user-prompt-msg {
  font-size: 12px;
  color: $secondary-gray-1;
  font-style: italic;
}

.user-prompt-msg-medication-notes {
  font-size: 12px;
  color: $secondary-gray-1;
  font-weight: normal;
  width: 100%;
  padding-left: 10px;
}

.section-headings{
  font-size: 16px;
  font-weight: 600;
  color: $mainBlue;
  margin-bottom: 0px;
}

.clickable {
  cursor: pointer;
}

.apply-button-format {
  padding-left: 141px;
  margin-top: 5px;
  align-content: center;
  align-items: center;
}

.language-selection-format {
  margin-left: 10px;
}

.reminder-group-container {
  display: flex;
  align-items: center;
}

.chart-group-title {
  padding-left: 10px;
}

.circle-container {
  display: flex;
  border-radius: 50%;
  border: solid 2px $light-gray;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
}

.remove-button-main-container{
  display: inline-flex;
  align-items: center;
  .remove-button-main {
    height: 14px;
    width: auto;
    margin-right: 5px;
  }

  .remove-button-main-title {
    font-size: 12px;
    color: #d0011b;
    font-weight: normal;

  }
}

.edit-button-main-container{
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  .edit-button-main {
    height: 14px;
    width: auto;
    margin-right: 5px;
  }

  .edit-button-main-title {
    font-size: 12px;
    color: #000000;
    font-weight: normal;

  }
}
#home {
  width: 100%;
  max-height: 100vh;
  padding-bottom: 0;
  background-color: $primary-gray-2;
  padding-left: 15px;
  flex: 1 1;

  @media screen and (max-width: $mediumScreenWidth){
    width: 100%;
    max-height: calc(100vh - 40px)!important;
    padding-bottom: 0;
    background-color: rgb(241, 240, 238)!important;
    padding-left: 15px;
    padding-top: 0!important;
  }
}

.main-panel-inner-container {
  min-height: calc(100vh - 60px);
  max-height: fit-content;
  background-color: $lightBlueBackground;
  flex-grow: 1;

  @media screen and (max-width: $mediumScreenWidth){
    min-height: calc(100vh - 50px);
    max-height: fit-content;
    background-color: $lightBlueBackground;
    flex-grow: 1;
    position: relative;

    .manage-patients-bottom-margin{
      max-height: fit-content;
      min-height: calc(10vh);
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: $fontSmall;
      display: flex;
      flex-direction: row;
      color: $mediumGrey;

      .manage-patients-bottom-text{
        margin: 10px;
      }
    }
  }

  .main-panel-inner-container {
    background-color: $primary-white;
  }
}
.profile-left-side {
  position: relative;
  width: 50%;
  float: left;
  height: 100%;
  z-index: 1010101010
}

.profile-right-side {
  position: relative;
  width: 50%;
  float: left;
  height: 100%;
  z-index: 1010101010;
  padding-top: 10px;
}

.top-nav-container {
  display: flex;
  height: 50px;
  background-color: rgb(24, 64, 90);
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;

  .profile-pic-nav {
    height: 25px;
    width: 25px;

    border-radius: 20px; 
    padding: 0;
  }

}

.ReactModalPortal {
  z-index: 9009;
}

.ql-editor {
  img {
    width: 100%;
  }
}

body {
  font-family: Avenir, sans-serif;
  display: flex;
  margin: 0;
}

#root {
  height: 100vh;
  width: 100%;
}

.group-manage-item-title {
  font-size: 14px;
  font-weight: normal;
  color: #2c3e50;
}

.tool-search-plus-tool-list {
  display: flex;
  flex-direction: column;
}

.tool-search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.tool-search {
  @extend .flex-row-wrap;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
}

.tool-list-container {
  display: flex;
  flex-direction: column;
  .tool-list{
    display: flex;
    margin-left: 31px;
    flex-wrap: wrap;
    width: auto;
    max-width: auto;
  }
}


.inbox-avatar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0px;
  .profile-pics {
    border: none;
    padding: 2px;
    border-radius: 100%;
    margin-right: 5px;
    background: #000 -ms-linear-gradient(top, #fff 0%, #000 100%);
  }
  .inbox-avatar-name{
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #2c3e50;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 215px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .name {
      align-self: flex-start;
      color: $textGrey;
    }
    .email {
      align-self: flex-start;
      font-size: 14px;
      font-weight: 400;
      color: $textGrey;
    }
    .patient-groups-labels-container{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 14px;

      .label-container {
        height: 4px;
        width: 20px;
        border-radius: 4px;
        display: flex;
        margin-right: 10px;
        margin-top: 2px;
      }
    }

  }
  .alert-indicator{
    display: flex;
    margin-right: 10px;
  }
}

#user-permissions-select {
  display: inline;
  width: 600px;
  justify-content: space-between;
  font-size: 14px;
  label {
    font-weight: bold;
    display: flex;
    align-items: center;
    p {
      margin-left: 5px;
    }
  }
  div {
    padding-bottom: 10px;
    margin-left: 18px;
    font-size: smaller;
  }
}

.date-range-select {
  display: flex;
  width: 157px;
  justify-content: space-between;
  font-size: 12px;
  font-family: Avenir;
  font-weight: 900;

  label {
    display: flex;
    align-items: center;
    width: 75px;
    p {
      margin-left: 5px;
    }
  }

  input{
    margin: 0;
    height: 15px;
    width: 15px;
  }
}

.radio-select:checked ~ p {
  color: #2d81c9;
}

.settings-gear {
  height: 20px;
  width: 20px;
}

// DATE PICKER COMPONENT
.react-datepicker__input-container {
  input {
    height: 2rem;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding-top: 2px;
  }
  input::-ms-clear {
    display: none;
  }

}

.react-datepicker {
  font-size: 1rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
}

.rounded-input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding-left: 5px;
  min-height: 30px;
}

.panel-scroll-list {
  height: calc(100vh - 450px);
  overflow: hidden;
}

/* CREATE TOOLS */
.ql-editing {
  left: 0px !important;
}

.rc-slider-mark-text {
  width: 60px !important;
  margin-left: 15px !important;
  word-break: break-word;
}

.Toastify__toast {
  font-family: inherit;
  color: $primary-gray-1;
  padding: 15px;
}

.ant-switch-checked {
  background-color: #7ed321;
}
.ant-select-selection--single {
  height: 36px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #979797;
}

.ant-select-dropdown-menu-item {
  font-size: 14px;
  font-weight: 900;
  color: #4a4a4a;
}

.ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
  color: $primary-gray-1;
}

.tool-start-end-date-picker {

  .ant-calendar-picker-input.ant-input {
    border: none;
    width: 65px;
    padding: 0px;
    height: 16px;
    font-size: 12px;
  }
  .ant-calendar-picker-icon {
    display: none;
  }
}

.tool-start-end-date-picker:hover {
  .ant-calendar-picker-input.ant-input{
    width: 90px;
    transition: 1s;
    cursor: pointer; 
  }
}

.tool-push-status-dot {
  .ant-badge-status-dot {
    height: 10px;
    width: 10px;
  }
}

.disabled-switch-text {
  margin-left: 10px;
  color: #9b9b9b;
  font-size: 12px;
}

.error-message {
  color: red;
}
.patient-notes-data{
  font-size: 14px;
  color: $textGrey;
  font-weight: 400;
}
.list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .list-item-name{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: $textGrey;
  }

  .list-allergy-item-name{
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: $textGrey;
    margin-left:10px;
  }
  .list-allergy-severity-item-name{
    font-size: 14px;
    color: $textGrey;
    font-weight: 400;
    opacity:59%;
  }
  .list-item-additional-info{
    font-size: 14px;
    font-weight: 200;
    font-style: oblique;
  }
  .remove-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: $margin-vertical-subsection-inner;

    .list-item-name{
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: $textGrey;
    }
    .list-item-additional-info{
      font-size: 14px;
      font-weight: 200;
      font-style: oblique;
    align-items: center;
    .remove-button {
      height: 14px;
      width: 14px;
      margin-right: 5px;
      cursor: pointer;
    }
    span {
      font-size: 12px;
      color: #d0011b;
    }
  }
  }
}

.header-with-alert-icon{
  display: flex;
  align-items: center;

  .alert-indicator{
    margin-left: 10px;

    @media screen and (max-width: $mediumScreenWidth){
      margin-left: $smallSpacing;
    }
  }

  @media screen and (max-width: $mediumScreenWidth){
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: $mobileBorderRadius;
    border-top-right-radius: $mobileBorderRadius;
    margin-bottom: $mediumSpacing;
  }

}
/* Animations */ 
.show-on-hover {
  opacity: 0;
}

.show-on-hover:hover {
  opacity: 1; 
}

.edit-show-on-hover{
  opacity: 0;
}

.edit-hover-visible{

  &:hover{
    .edit-show-on-hover{
      opacity: 1;
    }
  }
}

.overflow-ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.patient-inbox-icon{
  font-size: 16px;
  margin-right: 10px;
}

.no-padding{
  padding: 0px;
}

.fixed-medium-height{
  overflow-y: scroll;
  display:block
}
.header-layout{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  margin-right: 10px;
}
.header-layout-inner{
  align-content: center;
  display: flex;
  font-size: 16px;

  .alert-indicator{
    margin-left: 10px;

    @media screen and (max-width: $mediumScreenWidth){
      margin-left: $smallSpacing;
    }
  }
}
.header-add-icon{
  font-size: 16px!important;
  margin-right: 3px;
}
.no-info-available{
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  font-size: 12px;
  color: $textGrey;
}

input{

  &:-webkit-direct-focus{
    outline: none!important;
    outline-color: unset;
    outline-style: none;
    outline-width: 0;
  }
}
