@import '../variables';
@import '../index.scss';


.table-no-border {
  border: none !important;
  border-radius: 0px !important;
  margin: 0px !important;
  table-layout: fixed;
}

.header-row-table {
  th {
    padding: 10px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $secondary-gray-1 !important;
    background-color: $white!important;

  }
}

.table-row-actions-container {
  display: flex;
  color: $secondary-gray-1;
  opacity: 0;
  overflow: hidden;
  z-index:2;

  i {
    font-size: 16px;
    margin-right: 5px;
  }

  .preview-container {
    display: flex;
    align-items: center;
  }

  .preview-title {
    margin-left: 5px;
    margin-right: 20px;
  }
}

.table-body-row {
  font-size: 12px !important;
  min-height: 50px;
  td {
    vertical-align: center !important;
  }
  .title-container {
    min-width: 175px !important;
    max-width: 175px !important;
    width: 175px !important;
    padding: 10px !important;
    color: white;
    border-bottom: 1px solid;
  }
  .report-download-button{
    font-size: 12px;
    color: #16405b;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
}

.table-body-row:hover {
  .table-row-actions-container {
    opacity: 1;
    align-items: center;
    transition: 1s;
    justify-content: flex-end;
    overflow: unset;

    .report-download-button{
      z-index: 3;
    }
  }
  .table-row-visible {
  }
  .table-row-progress {
  }
  .table-row-completed {
  }
}

.multi-line-row {
  td {
    vertical-align: top !important;
  }
}

.scroll-table-outer-container {
  max-height: 300px;
}

.more-less{
  float: right;
}

.pull-left{
  width: 90px;
  float: left;
  vertical-align: center;
  .preview-title{
    vertical-align: super;
    margin-left: 5px;
  }
}
.no-background-button{
  overflow: visible;
  position: relative;

  &:hover{
  }
}
.dropdown-select{
  border: 1px solid $mainBlue;
  font-size: 14px;
  margin-top: 0!important;
}
.popover-search-form-container{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.popover-search-form-input{
  width: 100%;
  margin-bottom: 10px;

  .text-input-field{
    margin-bottom: 0px;
  }
  .editable-input{
    margin-bottom: 0px;
  }

}
.popover-search-form-input-language{
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;

  .labeled-dropdown{
    z-index: 4200;

    option{
      z-index: 4200;
    }
  }
}
.popover-search-form-input-alert{
  width: 100%;
  margin-bottom: 10px;

  .labeled-dropdown{
    z-index: 4000;

    option{
      z-index: 4000;
    }
  }
}
.no-information-text{
  font-size: 16px;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
  color: $textGrey;
  margin-top: $largeSpacing;
  height: auto;
}
.trigger-remove-payment{
  margin-top: 0px;
  display: inline-block;
}
.Toastify__toast{
  padding: 10px;
}
.Toastify__toast-container {
  width: 500px;
}
.Toastify__toast-container--top-center {
  margin-left: -250px;
}
.Toastify__toast-body {
  width: calc(100% - 20px);
  white-space: normal;
  word-break: normal;
  overflow-wrap: break-word;
  font-size: $fontMedium;
  color: $textGrey;
}
.Toastify__close-button {
  padding: 5px;
  position: relative;
  left: 5px;
  top: -5px;
}
