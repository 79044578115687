@import '../../variables';


.title-icon-text-container {
  display: flex;
  .title-icon {
    margin-right: 5px;
    color: rgb(250, 170, 23);
    height: 20px;
    width: 20px;
    font-size: 17px;
  }

  i{
    height: 20px;
    width: 20px;

    ::before{
      height: 20px;
      width: 20px;
    }
  }
  .title-text {
    font-weight: 500;
    white-space:normal;
    word-break: break-word;
    color: $textGrey;
    font-size: 14px;
  }
}

