@import '../../../../variables';


.survey-builder-range-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.survey-builder-range-question-input{
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
