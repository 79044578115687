@import '../../../variables';

.survey-builder-page-cell{
  background-color: white;
  border-radius: 10px;
  box-shadow: $boxShadow;
  margin: 20px;
  margin-bottom: 0;
  width: calc(100% - 40px);
  padding: 20px;

  ul{
    list-style-type: none;
    padding-inline-start: 0;

    li{
      list-style: none;
    }
  }
}
.survey-builder-page-header{
  display: flex;
  align-items: center;
  margin-bottom: $mediumSpacing;
}
.survey-builder-page-drag-handle{
  margin-right: 10px;
}
.survey-builder-page-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
}
.survey-builder-page-input-title{
  width: 250px;
  margin-left: auto;
  margin-bottom: $mediumSpacing;

  .text-input-field{
    width: 100%;
  }
  input{
    margin-right: 0;
    width: 100%;
  }
}
.survey-builder-page-input{
  width: 50%;
  margin-top: 20px;
}
.survey-builder-page-cell-container{
}
.survey-builder-page-button-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width:500px;
  margin-left: auto;
  margin-right: auto;
}
.survey-builder-page-button-add{
  background-color: $white;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: $boxShadow;
  color: $mainBlue;
  font-size: $fontMedium;
  font-weight: 600;
  cursor: pointer;
}
.survey-builder-page-cell-sortable-questions-cell-list{
  list-style: none;
  z-index: 1;
}
.survey-builder-page-cell-sortable-questions-cell-list-modal{
  list-style: none;
  z-index: 9010;
}
.survey-builder-page-cell-question-cell-container{
  list-style-type: none;
  padding-inline-start: 0;
}
.survey-builder-page-cell-sortable-surveys-cell-list{
  list-style-type: none;
  padding-inline-start: 0;

  li{
    list-style: none;
  }
}
.survey-builder-page-cell-sortable-surveys-cell-list-modal{
  list-style-type: none;
  padding-inline-start: 0;
  z-index: 9010;

  li{
    list-style: none;
  }
}
