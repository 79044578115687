@import '../../../variables';

.next-button {
  width: 50px;
  display: flex;
  justify-content: center;
  background-color: $light-gray;
  color: $panel-head-bg-color;
  font-weight: bold;
  border-radius: 15px;
  width: 60px;
  height: 30px;
}

.add-medication-next-button {
  margin-left: auto;
}
