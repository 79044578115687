@import '../../variables';


.profile-picture-container-inner{
  width: 100px;
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.profile-picture-label{
  text-align: center;
  color: $textGrey;
  font-size: 14px;
  font-weight: 500;
}
.profile-picture-disabled{
  border-radius: 50%;
  background-color: $inputBackground;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-picture-button{
  margin-left: auto;
  margin-right: auto;
  margin-top: $mediumSpacing;
}
.profile-picture{
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    border-radius: 50%;
  }
  div{
    border-radius: 50%;
  }

  .ui{
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .segment{
    padding: 0;
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.profile-picture-image-positioning{
  object-position: center;
  object-fit: cover;
  background-color: $white;
}
