@import '../../_variables.scss';


.manage-patient-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
  margin-bottom: 100px;
}
.manage-patient-container-import{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  padding-left:$largeSpacing;
  overflow: visible;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 174px);
  overflow-y: auto;
}
.manage-patient-container-title{
  font-size: 14px;
  color: $textGrey;
  font-weight: 500;
  margin-bottom: $smallSpacing;
  margin-right: $largeSpacing;
}
.manage-patient-invite-container-text{
   font-size: 12px;
   color: $mediumGrey;
   font-weight: 500;
  width: 100%;
  margin-bottom: $largeSpacing;
}
.manage-patient-container-text-add{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
  margin-right: $largeSpacing;
  margin-left:  $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.manage-patient-import-input{
  width: calc(100% - 20px);
  margin-top: $mediumSpacing;
}
.manage-patient-import-csv-input{
  width: calc(100% - 20px);
  margin-top: $mediumSpacing;
  margin-bottom: $largeSpacing;
  margin-right: $largeSpacing;
}
.manage-patient-container-invite{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-left:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 174px);
  overflow-y: auto;

  .subsection-element {
    margin-top: $smallSpacing;
    color: $mainBlue;
    font-style: normal;
  }

  .manage-patient-invite-input-large{
    width: calc(100% - 20px);
  }

  @media screen and (max-width: $mediumScreenWidth) {
    height: calc(100vh - 282px);
  }
}
.manage-patient-invite-input-large{
  width: 100%;

  input.is-empty:not(.is-focused) {
    background-color: $inputBackground;
  }
}
.manage-patient-invite-search-text{
  width: 100%;
  margin-right: $largeSpacing;
  font-size: 12px;
  color: $mainBlue;
  margin-left: $smallSpacing;
}
.manage-patient-invite-patient-input{
  width: 50%;
  margin-bottom: $mediumSpacing;
  margin-right: $largeSpacing;

  @media screen and (max-width: $mediumScreenWidth) {
    width: calc(100% - 20px);
  }
}
.manage-patient-invite-input-sub-container {
  display: flex;
  width: 50%;
  margin-right: 20px;

  @media screen and (max-width: $mediumScreenWidth) {
    width: 100%;
  }

  @media screen and (min-width: $mediumScreenWidth) {
    & > .manage-patient-invite-other-input {
      margin-right: 0px !important;
    }
  }
}

.manage-patient-invite-group-input{
  width: calc(100% - 20px);
}
.manage-patient-invite-input-container{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth) {
    flex-direction: column;

    &.manage-patient-column{
      flex-direction: column;
    }
  }
  .manage-patient-invite-input {
    .labeled-input-heading {
      span {
        margin-left: 3px;

        &.is-required {
          color: $red;
        }
      }
    }
  }
}
.manage-patients-patient-list-container{
  background-color: $white;
  width: 100%;
  padding-top: $largeSpacing;
  padding-left: 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 184px);
  overflow-y: hidden;
}
.manage-patients-list-container{
  height: calc(100vh - 203px);
  overflow: auto;
}
.manage-patients-dynamic-list-container{
  height: 100%;
  overflow: auto;
}
.manage-patient-no-user-container{
  margin-top: $smallSpacing;
  color: $mainBlue;
  font-style: normal;
  font-size: 12px;
}
.manage-patient-import-csv-text{
  font-size: 12px;
  color: $mediumGrey;
  font-weight: 500;
}
.manage-patient-import-title{
  font-size: 14px;
  color: $textGrey;
  font-weight: 500;
  margin-bottom: $smallSpacing;
  margin-right: $largeSpacing;
}
.manage-patient-import-button-container{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
  margin-bottom: 100px;
}
