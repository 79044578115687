.tooltip-container {
  width: 115px;
}

.tooltip-date {
  opacity: 0.5;
  font-family: Avenir-Roman;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.tooltip-body {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.tooltip-footer {
  opacity: 0.5;
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: oblique;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
  padding-top: 5px;
}

.no-padding-top-footer{
  padding-top: 0;
}

.match-image {
  padding-left: 7.5px;
}

.__react_component_tooltip {
  padding: 10px !important;
}
