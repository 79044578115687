@import '../../../variables';

.quest-container {
  background-color: $primary-white;
  width: 100%;
  max-width: 100%;

  .patient-panel-headings-container {
    height: 40px;
    font-size: $fontMedium;
    color: $textGrey;

    .section-control-button {
      h4 {
        color: $light-gray;
      }
    }
  }
}
.patient-quest-title{
  font-size: $fontMedium;
  color: $textGrey;
  font-weight: 500;
  margin-left: 5px;
}

.graph-heading {
  font-size: 14px;
  font-weight: 600;
  color: $primary-gray-1;
}
.patient-data-chart-container{
  border: 0 solid #ccc;
  overflow: visible;
}
.responses-list-container{
  width: 250px;
  flex-shrink: 0;
  z-index: 2;
}
.graph-section-container {
}
