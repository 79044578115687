@import '../../../variables';
@import '../../../index.scss';

:-webkit-direct-focus{
  outline: none!important;
  outline-color: unset;
  outline-style: none;
  outline-width: 0;
}

.virtualized-dropdown{
  background-color: #EEEEEE;
  z-index: 1000;
  height: 39px;
  font-size: 14px;
  border: 1px solid $mediumGrey;
  margin-top: 0!important;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    -webkit-box-align: center;
      -ms-flex-align: center;
        align-items: center;
  cursor: pointer;

  &:focus-visible{
    outline: none;
  }

  &.is-open{
    border: 1px solid $mainBlue !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    z-index: 1001;
    background-color: $white !important;
    color: $textGrey;
  }

  &.has-value{
    background-color: $white !important;
  }
}

.Select-control {
  position: relative;
  width: calc(100% - 2px)!important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Select-placeholder{
  position: absolute;
  left: 10px;
  top: 8px;
  width: 100%;
  font-size: 15px;
  color: $textGrey;
  z-index: 10000;
}

.Select-input {
  width: 100%;
  position: relative;
  height: 39px;

  &:hover{
    border: none;
  }
  &:focus-visible{
    outline: none;
  }
  input {
    top: 0;
    border: none;
    height: 35px;
    margin-left: 10px;
    left: 10px!important;
    padding: 0!important;
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100% - 40px)!important;
    background-color: inherit;
    border-bottom: none!important;
    margin-bottom: 0!important;
    outline: none!important;

    &:focus-visible{
      outline: none;
    }
  }
  &:-webkit-direct-focus{
    outline: none!important;
    outline-color: unset;
    outline-style: none;
    outline-width: o;
  }
}

.Select-menu-outer{
  padding-top: 10px;
  z-index: 9000;
  background-color: white;
  border: 1px solid $mainBlue;
  position: absolute;
  width: 100%;
  top: 30px;
  border-top: none;
  left: 0;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.Select-noresults{
  margin-left: 10px;
}

.Select-noresults{
  margin-left: 10px;
}

.Select-arrow-zone{
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
}

.Select-value{
  position:relative;
}

.Select-value-label{
  height: 35px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  padding-top: 8px;
  z-index: 100;
  position: absolute;
  color: $textGrey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactVirtualized__Grid__innerScrollContainer{
  div{
    padding-left: 10px;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  div:hover{
    background-color: #dfdfdf;
  }
}

.Select-multi-value-wrapper{
  flex: 1 1 auto;
}
