@import '../../variables';

.download-questionnaire-reports-loading-spinner {
    margin-left: 20px;
    width: auto;
    display: block;
}
.download-questionnaire-reports-outer-scroll-container{
    height: calc(100vh - 412px);
    @include hidden-scrollbar;
}
.download-questionnaire-reports-cell-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: flex-start;
}
.download-questionnaire-reports-container{
    padding: $mediumSpacing $largeSpacing;
    border: 1px solid $mediumGrey;
    border-radius: $borderRadius;
    margin-bottom: $largeSpacing;
    height: calc(100vh - 325px);
    font-size: 14px;
    color: $textGrey;
    @include hidden-scrollbar;
    overflow-y: hidden !important;

    svg{
        height: 16px;
        width: 16px;
    }
}
.download-questionnaire-reports-title{
  margin-bottom: 10px;
}
.download-questionnaire-report-button{
  margin-left: $largeSpacing;
}
.download-questionnaire-reports-languages-dropdown-list{
  list-style: none;
  position: absolute;
  margin-top: 42px;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 3px;
  overflow-x: auto;
  max-height: 140px;
  min-width: 130px;
  top: -185px;
  background-color: $white;
  right: 135px;
  z-index: 10001;
}
.download-questionnaire-reports-languages-dropdown-list li{
  padding: 5px;
  cursor: pointer;

  &:hover{
    background-color: rgba(0,0,0,0.15)
  }
}
