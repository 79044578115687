@import '../../../_variables.scss';

.call-button-container{
  color: $mainBlue;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.call-button-container-disabled{
  color: rgba(74,74,74, 0.25);
  cursor: not-allowed !important;
}
