@import '../../variables';


.schedule-survey-cell{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $white;
}
.schedule-survey-cell-selected{
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid $mainBlue;
}
.create-survey-question-cell-textarea{
  width: 100%;
  margin-bottom: 0;

  textarea{
    width: 100%;
  }
}
.create-survey-question-cell .create-survey-question-cell{
  margin-bottom: 0;
  margin-top: 20px  ;
}
.schedule-survey-enabled-settings-container{
  font-size: $fontSmall;
}
.schedule-survey-enabled-settings-title{
  color: $mainBlue;
  font-weight: 600;
  font-size: $fontMedium;
  margin-bottom: 3px;
}
.schedule-survey-enabled-settings-array{
  color: $textGrey;
  font-size: $fontMedium;
  margin-bottom: 1px;

  &:last-of-type{
    margin-bottom: 0;
  }
}
.schedule-survey-header{
  display: flex;
  align-items: center;
}
.schedule-page-drag-handle{
  margin-right: 10px;
}
.schedule-survey-input-title {
  width: 250px;
  margin-left: auto;

  .text-input-field {
    width: 100%;
  }

  input {
    margin-right: 0;
  }
}
.schedule-condition-cell-input{
  width:100%;
  margin-right: 20px;

  &:last-of-type{
    margin-right: 0;
  }
}
.schedule-condition-cell-input-condition-reference{
  .Select-menu-outer{
    width: 100%;
  }
}
.schedule-condition-cell-switch-input{
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-direction: column;
  margin-right:20px;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }

  .labeled-errors-warning-container{
    width: 100%;
    top: 0;
  }
}
.schedule-condition-cell-add-button{
  color: $mainBlue;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 10px;
}
.schedule-condition-cell-labeled-info{
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
.schedule-condition-cell-input-first-of-type{

  .Select-menu-outer{
    width: 100%;
  }
}
.schedule-condition-cell-container{
  margin-top: 10px;
}
.schedule-condition-cell-is-table{
  margin-top: 10px;

  .Select-menu-outer{
    width: 100%;
  }
}
.schedule-condition-cell-type-index{
  display: flex;
  border-bottom: 1px solid $lightGrey;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .Select-menu-outer{
    width: 100%;
  }
}
.schedule-condition-cell-survey-operations-cell{
  display: flex;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 20px;
}
