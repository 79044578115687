@import '../../_variables.scss';


.manage-survey-links-page{
  min-height: calc(100vh - 60px);
  max-height: fit-content;
  background-color: $lightBlueBackground;
  flex-grow: 1;

  @media screen and (max-width: $mediumScreenWidth){
    min-height: calc(100vh - 50px);
    max-height: fit-content;
    background-color: $lightBlueBackground;
    flex-grow: 1;
    position: relative;
  }

  .manage-survey-links-page{
    background-color: $primary-white;
  }
}
.manage-survey-links-navigation{
  overflow: visible;
  background-color: $white;
  box-shadow: $boxShadow;
  border-top-left-radius: $border-radius-panel;
  height: calc(100vh - 124px);
}
.manage-survey-links-container{
  width: 100%;
  background-color: $white;
  padding-top: $largeSpacing;
  padding-bottom:$largeSpacing;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: 100%;
  overflow: hidden;
}