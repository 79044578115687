@import '../../../../variables';

.language-dropdown-item{
  display: flex;
}

.language-dropdown-text{
  margin-left: 5px;
}

.language-dropdown-spacer{
  width: 14px;
}

.language-dropdown-title{
  color: $primary-gray-1;
}

.language-dropdown-display-name{
  color: $input-placeholder-color;
}
