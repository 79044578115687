@import '../../../variables';

.clear-receipt-dropdown-modal {
  position: absolute;
  top: unset;
  width: 225px;

  &.dynamic-list-popper-positioning {
    position: relative;
    top: unset;
    right: 10px;
  }

  .right-section {
    font-size: 12px;
    opacity: 0.5;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }
  .right-section:hover {
    opacity: 1.0;
  }

  .triangle{
    color: white;
  }

  & .drop-down-body{
    padding: 8px;
  }
}

.buttons-container{
  display: flex;
  margin-bottom: 8px;
}

.close-button{
  border-radius: 5px;
  background-color: $light-gray;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 8px;
}

.confirm-button{
  border-radius: 5px;
  background-color: $mainBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex: 1 1 auto;

  & span{
    margin-left: 5px;
  }
}

.add-note-button{
  color: $mainBlue;
  display: flex;
  align-items: center;
  font-size: 12px;

  & span{
    margin-left: 5px;
  }
}
.confirmation-dropdown-button-container{
  margin-top: 10px;
 display: flex;
  justify-content: space-between;
}
.confirmation-dropdown-button{

}
.confirmation-note-textarea{
  .textarea-field{
    width: 100%!important;
  }

}
