@import '../../_variables.scss';

.TriggersList {}

.user-trigger-list-cell-container{
  height: calc(100vh - 273px);
}
.labeled-dropdown {
  min-width: 130px;
}
.select-patient-text{
  font-size: 16px;
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
  color: $textGrey;
  margin-top: $largeSpacing;
  height: auto;
}
.user-triggers-select-patient-dropdown{
  padding-left: $largeSpacing;
  padding-right:$largeSpacing;
  width: 100%;
}
