@import '../../../_variables.scss';

.gray-button-main-container{
  display: flex;
  justify-content: flex-start;
  position: relative;

  .gray-button-main{
    min-width: 100px;
    height: 40px;
    padding-left: $largeSpacing;
    padding-right:  $largeSpacing;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $greyButton;
    color: $mediumGrey;
    font-size: 14px;
    font-weight: 400;
    border-radius: $smallSpacing;
    border: none;
    cursor: pointer;
    flex: 0 0 auto;

    &:hover{
      background-color: $greyButtonHover;
    }
    &:active{
      background-color: $greyButtonPressed;
      color: $white;
      border: none;
    }
  }
}
.gray-button-loading{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.gray-button-icon-down{
  font-size: large;
  margin-top: -7px;
  margin-left: 5px;
}
