@import '../../variables';
@import '../../index';

.custom-modal-body-container {
  display: flex;
  flex-direction: column;
  background-color: $primary-white;
  padding: 10px 0px 10px 0px;
 
  .subsection-element {
    padding: 0px 20px;
  }

  .search-symptoms {
    display: flex;
    flex-direction: column;
  }

}

.assign-symptoms-body-container {
  @extend .custom-modal-body-container;
  height: 450px;  
  .header-next-state {
    display: flex;
    flex-direction: column;
    .top-section {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      margin-left: 50%;
      margin-right: 20px;
    }
  }

  .extra-notes-input{
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }

}

.change-password-body-container {
  @extend .custom-modal-body-container;
  height: auto;
}
.create-survey-question-select-modal{
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 10px;

  .user-permission-cell{
    width: calc((100% / 3) - 10px)!important;
    height: 170px!important;
    margin-bottom: 10px;
    padding: 10px!important;
  }
}
