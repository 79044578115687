@import '../../../../variables';


.survey-builder-question-db-write-input-switch{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid $lightGrey;
  padding-top: 20px;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
.survey-builder-question-db-write-input-more{
  margin-left: auto;
  width: 100%;
  margin-bottom: $largeSpacing;
  padding-bottom: $mediumSpacing;

  &:first-of-type{
    padding-top: $largeSpacing;
  }

  &:last-of-type{
    border-bottom: 1px solid $lightGrey;
  }

  .text-input-field{
    width: 100%;
  }
  .Select-menu-outer{
    width: 100%;
  }
}
.survey-builder-question-db-write-column-value{

  input{
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }
}
