@import '../../_variables.scss';

.survey-list-large{
  padding: $largeSpacing;
  padding-right: 0;
  padding-left: 0;
}
.survey-list-modal{
  padding: 0;
  display: block;
  background-color: $white;
  width: 100%;
  overflow-y: auto;
  height: auto;

  input{
    margin: 0;
  }
}
.survey-list-modal-title{
  font-size: 16px;
  color: $mainBlue;
  font-weight: 600;
  margin-bottom: 15px;
}
.survey-list-input-cell{
  display: flex;
  width: 50%!important;
  flex: 1 1 50%!important;

  @media screen and (max-width: $mediumScreenWidth){
    width: 100%!important;
    flex: 1 1 100%!important;
    }
}
.survey-list-input-title{
  font-weight: 500;
  color: $textGrey;
  font-size: 14px;
 }
.survey-list-modal-input-container{
  display: flex;
  align-items: center;
}
.survey-list-modal-input-subtitle{
  font-size: $font-size-base!important;
}
.survey-list-input-small{
  flex: 0 0 50%;
  width: 50%;
  margin-right: 20px;

  .editable-input{
    margin-bottom: 10px;
  }
}
.survey-list-cell{
  display: flex;
  width: 100%;
}
.survey-list-input-criteria{
  width: calc(50% - 40px);
  margin-right: $largeSpacing;
  margin-left:$largeSpacing;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 40px);
  }
}

.survey-list-modal-text{
  font-size: 12px;
  line-height: 1.29;
}
.survey-list-modal-button-cell{
  display: flex;
  justify-content: flex-end;
}
.survey-list-modal-button{
  font-size: $font-size-base;
  color: $white;
  background-color: $mainBlue;
  border-radius: 3px;
  font-weight: 600;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.survey-list-modal-button-disabled{
  font-size: $font-size-base;
  color: $textGrey;
  background-color: $inputBackground;
  border-radius: 3px;
  font-weight: 400;
  margin-right: $mediumSpacing;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.survey-list-modal-padding{
  margin-right: $smallSpacing;
  margin-left: $smallSpacing;
}
.survey-list-modal-dual-cell{
  display: flex;
  justify-content: space-between;
}
.survey-list-modal-dual-spacing{
  margin-right: $largeSpacing;
}
.survey-list-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.custom-select{
  appearance: none;
  background-image: url("../collapse-left-button.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center right 10px;
  position: relative;
}
.formControl {
  margin: 20px;
  min-width: 120px;
}
.selectEmpty {
  margin-top: 20px;
}
option{
  position: relative;
  left: 10px;
}
.survey-list-text-cell-input{
  display: flex;
  align-items: center;
  margin-bottom: $largeSpacing;

  @media screen and (max-width: $smallScreenWidth){
    .survey-list-text-input-dropdown{
      width: 60%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
.survey-list-text-input-dropdown{
  margin-left: $smallSpacing;
  margin-right: $smallSpacing;
  width: 60%;
  flex: 1 1 auto;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.margin-top{
  margin-top: $mediumSpacing;
}
.survey-list-button-container{
  width: calc(100% - 40px);
  margin-right: $largeSpacing;
  margin-left: $largeSpacing;
  display: flex;
  justify-content: center;

  .loading-container{
    width: 40px;
  }
}
.survey-list-input-cell{
  display: flex;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.survey-list-large-dual-input{
  display: flex;
  width: 100%;

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;
  }
}
.survey-list-input-cell-input-text{
  width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 20px);
    margin-right: 0;
  }
  @media screen and (max-width: $smallScreenWidth){
    margin-bottom: 10px;
  }
}
.survey-list-input-cell-input-dropdown{
  width: calc(50% - 10px);
  margin-bottom: 20px;

  @media screen and (max-width: $mediumScreenWidth){
    width: calc(100% - 20px);
    margin-left: 20px;
    margin-right: 0;
  }
  @media screen and (max-width: $smallScreenWidth){
    margin-bottom: 10px;
  }
}
.survey-list-input-cell-input-date{
  width: 50%;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.survey-list-input-cell-container{
  display: flex;
  margin-left: 20px;

  .survey-list-input-cell{
    display: flex!important;
  }

  @media screen and (max-width: $mediumScreenWidth){
    flex-direction: column;

    .survey-list-input-cell{
      display: flex!important;
    }
  }

}
.survey-list-input-cell-input-value{
  width: 50%;

  @media screen and (max-width: $smallScreenWidth){
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.survey-list-advanced-settings{
}
.survey-list-advanced-settings-input-cell{
  display: flex;
  margin-top: 20px;
  margin-left: 20px;

  @media screen and (max-width: $mediumScreenWidth){
    flex-wrap: wrap;
  }
}
.survey-list-advanced-settings-input{
   width: calc(100% / 4);
   font-size: $font-size-base;
   color: $textGrey;

   @media screen and (max-width: $mediumScreenWidth){
     width: 100%;
   }
 }
.survey-list-advanced-settings-input-switch{
  width: calc(100% / 4);
  font-size: $font-size-base;
  color: $textGrey;

  @media screen and (max-width: $mediumScreenWidth){
    width: 50%;
  }
}
.survey-list-advanced-settings-button{
  margin-left: 20px;
}

.survey-list-button{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.survey-list-modal-button-container{
  display: flex;
  justify-content: center;
}
.survey-list-button-upload{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.survey-list-button-download{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.survey-list-input-container{

}
.survey-list-input-cell-input{
  width: calc(100% - 20px);
  margin-left: 20px;
  margin-bottom: 20px;
}
.survey-list-diagnosis-upload-name{
  margin-left: 22px;
  margin-right: 22px;
  font-size: $fontMedium;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}
