@import '../../variables';

.schedules-list{
  width: 100%;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
}
.schedules-list-switch{
  height: 39px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
    white-space: nowrap;
  }
  .labeled-input-heading-tools {
    margin-right: 20px;
  }
  button{
    margin-left: 5px;

    span {
      margin-right: 6px;
    }
  }
}
