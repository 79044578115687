@import '../../_variables.scss';

.GroupCodesList {}

.group-codes-list-container{
  height: 286px;
  margin-bottom: 10px;
}
.manage-patients-group-codes-list-container{
  height: 390px;
  margin-bottom: 10px;
}
.groupcode-title-list-search{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}