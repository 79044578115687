@import '../../../variables';

.patient-profile-data-graphs{
  width: 100%;
  .patient-profile-data-controls {
    padding: 20px 20px 0px 20px;
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    border-bottom: 1px solid #eeeeee;
  }
  .patient-profile-data-graphs-heading-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;

    .graph-time-control-buttons{
      height: 20px;
      width: 12px;
    }
    .graph-time-period-info {
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 600;
      color: $primary-gray-1;
    }
    .graph-time-container {
      display: flex;
      align-items: center;
    }
  }
}
