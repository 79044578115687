@import '../../../../variables';

.survey-builder-date-time-question-cell{
  display: flex;
  justify-content: space-between;
  margin-top: $mediumSpacing;
}
.survey-builder-date-time-cell{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.survey-builder-date-time-input{
  width: calc(50% - 10px);
  margin-bottom: 0;

  .editable-input{
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
  }
}
