@import '../../../variables';
@import '../../../index';

.tool-preview-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 24em;
  height: 650px;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
  font-size: 14px;

  a {
    color: #2EB398;
  }
}
.tool-preview-date-objective{
  border-radius: 0 !important;
  width: 100%;
  padding: 0;
  margin-bottom: 5px;
}
