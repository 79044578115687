@import '../../variables';
@import '../../index';

.tool-edit-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.tool-edit-heading-container {
  display: flex;
  background-color: #16405b;
  border-radius: '5px 5px 0 0';
}
