@import '../../variables';

.separator-dividing-line{
  width: 100%;
  height: 1px;
  flex: 0 0 1px;
}
.separator-container{
  width: 100%;
  display:flex;
  align-items: center;
}
.separator-dividing-line-text{
  height:1px;
  flex:1 0 1px;
}
.separator-dividing-text{
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
