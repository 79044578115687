@import '../../variables';

.dynamic-list-cell{
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start;

  &:hover{
    background-color: rgba(245,245,245,1);

    .dynamic-list-cell-clamped{
      background-color: rgba(245,245,245,1)!important;
    }
  }
  &:focus-within{
    background-color: rgba(245,245,245,1);
  }
  &:active{
    background-color: rgba(245,245,245,1);
  }

  input{
    width: 100%;
    border: none;
    border-radius: $smallSpacing;
  }
  .react-datepicker__input-container input{
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 5px;
    height: 39px;
  }
  .react-datepicker__close-icon {
    padding-right: 3px;

    &::after{
      background-color: transparent;
      color: $textGrey;
      font-size: 21px;
    }
  }
}

.dynamic-list-cell-selected-row{
  background-color: rgba(245,245,245,1);

  .dynamic-list-cell-clamped{

    &:first-of-type{
      background-color: rgba(245,245,245,1);
    }
  }
}
.dynamic-list-cell-text{
  display: flex;
  padding: $mediumSpacing;
  color: $textGrey;
  font-size: $fontMedium;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  position: relative;
  height: auto;
  align-items: center;

  &:first-of-type{
    padding-left: 20px;
  }
}
.dynamic-list-cell-properties-array{
  overflow-wrap: anywhere;
  hyphens: auto;
}
.dynamic-list-cell-selected{
  font-weight: 600;
}
.dynamic-list-cell-clamped{

  &:first-of-type{
    left:0px;
    z-index: 2;
    position: sticky;
    background-color: $white;
  }

  &:hover{
    background-color: rgba(245,245,245,1);
  }
}
.dynamic-list-cell-border{
  border: solid $textLightGrey;
  box-sizing: border-box;
  border-collapse: collapse;
  border-top-width: 0;
  border-left-width: 0;
}
.dynamic-list-cell-template{
  width: 100%;
  height:100%;

  .labeled-input-container{
    width: 100%;
    margin-bottom: 10px;
  }
  .labeled-switch-input-cell{
    margin-bottom: 10px;
  }
  button{
    width: 100%;
    margin-bottom: 10px;
  }
  img{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .editable-input{
    margin-bottom: 10px;
  }

  :only-child{
    .labeled-input-container{
      input{
        margin-bottom: 0px;
      }
      textarea{
        margin-bottom: 0px;
      }
      .dynamic-list-cell-labeled-dropdown{
        margin-bottom: 0px;
      }
    }
    .labeled-switch-input-cell{
      margin-bottom: 0px;
    }
    button{
      margin-bottom: 0px;
    }
    img{
      margin-bottom: 0px;
    }
    .editable-input{
      margin-bottom: 0px;
    }
  }
}
.dynamic-list-cell-loading-container{
  display: flex;
  font-size: 21px;
  position: relative;
  align-items: center;
  justify-content: center;

  .loading-spinner {
    padding-top: 10px;

    .loading-container-top {
      margin-bottom: 0px;
    }
  }

  .loading-container{
    position: relative;
  }

  .ui.loader:after, .ui.loader:before{
    width: 21px;
    height: 21px;
  }
}
.dynamic-list-cell-labeled-datepicker{
  border: none !important;
}
