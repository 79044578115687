@import '../../../variables';

.drop-down-menu {
  height: auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  
  .panel-main-header {
    font-weight: 600;
  }
  .main-container {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    .right-section {
      font-size: 12px;
      opacity: 0.5;
      span {
        margin-right: 20px;
      }

      span:last-child {
        margin-right: 0px;
      } 
    }
    .right-section:hover {
      opacity: 1.0;
    }
  }

  .triangle {
    color: $panel-head-bg-color;
    display: flex;
    align-self: flex-end;
    margin-right: 28px;
    transform: rotate(-90deg);
    margin-bottom: -10px;
    font-size: 25px;
  }

  .panel-main-header {
    padding-left: 20px;
    color: #fff;
    padding-right: 20px;
    background-color: #16405b;
  }
  .panel-main-header-title-text {
    font-size: 16px;
  }

  .drop-down-body {
    padding: 20px;
    z-index: 2;
    background-color: white; 
    border-radius: 10px;
    font-size: 14px;

    .report-send-details {
      display: flex;
      height: 90px;
      flex-direction: column;
      justify-content: space-between;
      .group-details {
        display: flex;
        align-items: center;
        .panel-circle {
          margin-left: 0;
          height: 20px;
          width: 20px;
          margin-right: 5px;

        }

        .group-details-header {
          font-size: 14px;
        }
      }
    }
  }

  .report-select-options {
    margin-top: 100px;
    display: flex;
    width: 410px;
    .select-dates{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .react-datepicker__input-container{
        width: 200px;
        height: 26px;
        input {
          height: 100%;
          width: 178px;
        }
      }

      .react-datepicker-ignore-onclickoutside {
        height: 100%;
        width: 178px;
      }
    }
  }
}

