@import '../../variables';

.schedule-container{
  width: 100%;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
  justify-content: space-between;
}
.schedule-title-header{
  display: flex;
  flex-direction: row;
}
.schedule-title-explanation-text{
  color: $mediumGrey;
  font-size: 12px;
  font-weight: 500;
  margin-top: $smallSpacing;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
}
.schedule-modal-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
  justify-content: space-between;
}
.schedule-dynamic-list{
  height: calc(100% - 146px);
}
.schedule-dynamic-list-when-searching-patient{
  height: calc(100% - 163px);
}
.schedule-title{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  margin-bottom: $mediumSpacing;
  justify-content: space-between;
}
.schedule-name-input{
  min-width: 300px!important;
  margin-right: 10px;

  .labeled-dropdown{
    margin-bottom: 0!important;
    margin-right: 5px;
  }
}
.icon-button-container{
  display: flex;
  padding: $mediumSpacing;
  align-items: center;
  font-weight: 400;
  flex-direction: column;
  justify-content: center;
}
.schedule-icon-button-container{
  display: flex;
  margin-top: $largeSpacing;
  align-items: center;
  margin-right:$largeSpacing;
  border-radius: 5px;
  background-color: $inputBackground;
}
.schedule-editing-title-container{
  display: flex;
  align-items: flex-end;
}
.icon-button-container-text{
  color:$mainBlue;
  font-weight: 600;
  font-size: $fontSmall;
  line-height: 0.8;
}
.icon-button-circle{
  width: 40px;
  height: 40px;
  display: flex;
  box-shadow: 0 0 3px rgba(0,0,0, 0.2);
  align-items: center;
  border-radius: 20px;
  margin-bottom: 7px;
  justify-content: center;
  background-color: $white;
}
.schedule-sub-header{
  display: flex;
  align-items: flex-end;
  padding-right: $largeSpacing;
  justify-content: space-between;
  flex: 1 1 auto;
  min-width: 80%;
  max-width: 100%;
}
.schedule-sub-header-patient{
  display: flex;
  align-items: flex-end;
  padding-right: $largeSpacing;
  justify-content: space-between;
  flex: 1 1 auto;
  min-width: 50%;
  max-width: 100%;
}
.schedule-search-patients-dropdown{
  width: 30%;
  max-width: 300px;

  .labeled-dropdown{
    margin-bottom: 0!important;
    margin-right: 0px;
  }
}
.schedule-patient-info-legend{
  display: flex;
  align-items: center;
  margin-right: $mediumSpacing;
}
.schedule-patient-info-legend-container{
  display: flex;
  height: 40px;
  align-items: center;
  border: 1px solid $mainBlue;
  padding-left: $largeSpacing;
  padding-right: $largeSpacing;
  border-radius: 20px;
}
.schedule-patient-info-text{
  font-weight: 600;
  margin-left: $smallSpacing;
}
.schedule-patient-info-container{
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: $mediumSpacing;
  padding-left: $largeSpacing;
  margin-bottom: $mediumSpacing;
  justify-content: space-between;
}
.schedule-patient-info-summary-container{
  color: $white;
  display: flex;
  max-width: 50%;
  min-width: 300px;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  flex-direction: column;
  background-color: $mainBlue;
}
.schedule-patient-info-header-container{
  color: $white;
  display: flex;
  padding: $smallSpacing;
  justify-content: space-between;
}
.schedule-patient-info-details-container{
  display: flex;
  justify-content: space-between;
}
.schedule-patient-info-details-patient{
  display: flex;
  flex-direction: column;
  padding: $smallSpacing;
  width: 34%;
  flex: 0 0 34%;
}
.schedule-patient-info-group-cell{
  flex: 0 0;
  color: $mainBlue;
  height: 25px;
  display: flex;
  font-size: $fontSmall;
  margin-left: $smallSpacing;
  padding-left: 7px;
  align-items: center;
  border-radius: 13px;
  padding-right: 7px;
  background-color: $white;
  white-space: nowrap;
}
.schedule-patient-info-group{
  display:flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 0 0 calc(66% - 5px);
}
.schedule-separator{
  margin-Top: 5px;
  margin-bottom: 5px;
  width: 100%;
  background-color:$white;
  height: 1px;
}
.schedule-selected-groups-dropdown{
  width: 50%;

  .labeled-dropdown{
    height:41px;
    margin-bottom: 0;
  }
}
.schedule-saving{
  height:21px;
  display:flex;
  margin-top:9px;
  flex-direction:row;
}
.schedule-saving-title{
  color:$mediumGrey;
  margin-left:10px;
}
.schedule-title-name-info{
  flex:1 1;
}
.schedule-add-view{
  color:$mainBlue;
  cursor:pointer;
  display:flex;
  padding:10px;
  font-weight:600;
  align-items:center;
}
.schedule-trigger-view{

}
.schedule-trigger-view-title{
  font-size:$fontMedium;
  font-weight:600;
}
.schedule-trigger-view-description{
  font-size:$fontSmall;
  font-weight:400;
}
.schedule-survey-view{

}
.schedule-survey-view-title{

}
.schedule-title-view{
  width:100%;
  cursor:auto;
  display:flex;
  padding:10px;
  justify-content:space-between;

  .schedule-title-delete-view{
    cursor:pointer;
    visibility:hidden;
  }
  &:hover{
    .schedule-title-delete-view{
      visibility:visible;
    }
  }
}
.schedule-title-arrow-view{
  cursor:auto;
  display:flex;
  padding:10px;
  visibility:hidden;
  justify-content:space-between;

  &:hover{
    .schedule-title-arrow-view{
      cursor:pointer;
      visibility:visible;
    }
  }
}
.schedule-indicator-container{
  flex:1 1 auto;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;

  .schedule-indicator-add-view{
    color:white;
    width:20px;
    cursor:pointer;
    height:20px;
    margin:5px;
    visibility:hidden;
    border-radius:10px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  &:hover{
    .schedule-indicator-add-view{
      visibility:visible;
    }
  }
}
.schedule-indicator{
  width:20px;
  cursor:pointer;
  height:20px;
  margin:5px;
  font-size:20px;
  font-weight:bold;
  border-radius:10px;
}
.schedule-indicator-hidden{
  width:20px;
  cursor:none;
  height:20px;
  margin:5px;
  visibility:hidden;
  border-radius:10px;
}
