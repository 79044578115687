@import '../../../../variables';


.survey-builder-question-visibility-input{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid $lightGrey;
  padding-top: 20px;

  .labeled-input-heading{
    font-weight: 600;
    margin-right: auto;
  }
}
.survey-builder-question-trigger-input-value{
  width: 100%;


  &:first-of-type{
    padding-top: 20px;
  }

  &:last-of-type{
    border-bottom: 1px solid $lightGrey;
  }

  .text-input-field{
    width: 100%;
  }
  input{
    margin-right: 0;
    width: 100%
  }
}
