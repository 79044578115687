@import '../../variables';

.report-builder-container{
  width: 100%;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
  justify-content: space-between;
}
.report-builder-container-modal{
  width: 100%;
  height: calc(100vh - 150px)!important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding-top: 20px;
  justify-content: space-between;
}
.report-builder-dynamic-columns-list{
  height: calc(50% - 10px);
  margin-bottom: $mediumSpacing;
}
.report-builder-dynamic-filters-list{
  height: calc(50% - 10px);
}
.report-builder-header-container{
  display: flex;
  margin-left: 20px;
  align-items: center;
  width: calc(100% - 20px);
}
.report-builder-header-delayed-input{
  width: calc(50% - 60px);
  margin-right: 20px;
  margin-bottom: 20px;
}
.report-builder-header-input{
  width: calc(50% - 60px);
  margin-right: 20px;
  margin-bottom: 20px;
}
.report-builder-header-button-save{
  align-self: flex-end;
  margin-right: 10px;
  max-width: 170px;
  white-space: nowrap;
  margin-left: auto;
}
.report-builder-header-spinner-button-cell{
  width: 140px;
  display: flex;
}
.report-builder-add-relationship-container{
  display: flex;
  align-items: center;
}
