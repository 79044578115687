@import '../../variables';

.medical-records-list-container{
  width: 100%;
  height: 380px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: $white;
}
