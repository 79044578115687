@import '../../../../variables';


.survey-builder-dynamic-list-question-cell{
  margin-top: 20px;
  display: flex;

  .Select-menu-outer{
    width: 100%;
  }
  .Select-control{
    z-index: 2 !important;
  }
}
.survey-builder-dynamic-list-question-input{
  width: 100%;

  .labeled-dropdown-error-warning-container{
    position: relative;
    top: 5px;
  }
}
