@import '../../variables';

.tab-controller{
  padding: 0;
  position: relative;
  background: white;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: $mobileBorderRadius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 60px;
  display: flex;
  box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.2);
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: auto;
}
.tab-controller::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.tab-controller-top-padding{
  height: 10px;
  width: 100%;
  background-color: $lightBlueBackground;
}
.tab-controller-background{
  background-color: $lightBlueBackground;
}
.tab-container{
  display: flex;
  align-items: center;
  position:relative;
  z-index: 0;
  padding-bottom: $smallSpacing;
  padding-top: 8px;
  padding-left:25px;
  padding-right: 25px;
  font-weight: 600;
  font-size: 16px;
  width: auto;
  flex: 0 0;
  margin: 0 -5px;
  box-sizing: border-box;
  white-space: nowrap;
  background: transparent;

  &:last-of-type{
    margin-left: 5px;
  }

  &:first-of-type{
    margin: 0;
    border-top-left-radius: $mobileBorderRadius;
  }

  @media screen and (max-width: $mediumScreenWidth){
    font-size: $fontMedium;
  }

  @media screen and (max-width: 890px){
    padding-top: 5px;
    padding-left:15px;
    padding-right: 15px;
  }
}
.tab-container-selected{
  background: white;
  display: flex;
  position:relative;
  z-index: 0;
  padding-bottom: $smallSpacing;
  padding-top: 8px;
  padding-left:25px;
  padding-right: 25px;
  flex: 0 0;

  @media screen and (max-width: $mediumScreenWidth){
    font-size: $fontMedium;
  }
  @media screen and (max-width: 890px){
    padding-top: 5px;
    padding-left:20px;
    padding-right: 20px;
  }
}
.tab-container-selected{
  border-top-left-radius: $mobileBorderRadius;
  border-top-right-radius: $mobileBorderRadius;
  background: #FFF;
  color: $brand-primary;
  font-weight: 600;
  z-index: 2;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), inset 0 1px 0 #FFF;
  position: sticky;
  left: 0;
  right: 0;

  &:before{
    position: absolute;
    bottom: 0px;
    width: 16px;
    height: 12px;
    content: "";
    background-color: white;
  }

  &:after{
    position: absolute;
    bottom: 0px;
    width: 16px;
    height: 12px;
    content: "";
    background-color: white;
  }

  &:before{
    left: -12px;
  }

  &:after{
    right: -12px;
  }

  .tab-cover-left{
    height: 12px;
    width: 12px;
    border-bottom-right-radius: 30px;
    position: absolute;
    bottom: 0px;
    left: -12px;
    background-color: transparent;
    box-shadow: inset -7px -7px 9px -7px rgba(0,0,0,0.1);
  }

  .tab-cover-right{
    height: 12px;
    width: 12px;
    border-bottom-left-radius: 30px;
    position: absolute;
    bottom: 0px;
    right: -12px;
    background-color: transparent;
    box-shadow: inset 7px -7px 9px -7px rgba(0,0,0,0.1);
    z-index: 3;
  }

  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {

    .tab-cover-right-safari-only {
      box-shadow: inset 7px -7px 9px -4px rgba(0,0,0,0.1);
    }

    .tab-cover-left-safari-only {
      box-shadow: inset -7px -7px 9px -4px rgba(0,0,0,0.1);
    }
  }
  }
}

.tab-container-selected:first-of-type{

  &:before{
    display: none;
  }
  .tab-cover-left{
    display: none;
  }

}
.tab-container-alert{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
  background-color: $brand-danger;
  border-radius: 50%;
  margin-left: $mediumSpacing;
  margin-right: $smallSpacing;
  padding: 3px;
  min-width: 20px;
  max-width: 30px;
  height: 20px
}
.tab-title{
  cursor: pointer;
}
.tab-header{
  width: 100%;
  padding: $largeSpacing;
  color: $mainBlue;
  font-size: 16px;
  font-weight: 600;
  background-color: $white;
  position: relative;
  bottom: -5px;
  border-top-left-radius: $mobileBorderRadius;

  @media screen and (max-width: $mediumScreenWidth){
    font-size: $fontMedium;
  }
}
.tab-controller-header{
  padding: 0;
  position: relative;
  background: white;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: $mobileBorderRadius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 60px;
  display: flex;
  padding-top: 3px;
}
