@import '../../_variables.scss';

.PaymentsList {}

.table-payment-more{
  width: 20px;
  font-size: 20px;
  color: $textGrey;
  padding: 0;
  margin: 0;
}
.payment-status-cell{
  display: flex;
  align-items: center;
}
.payment-status-cell-top{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.payment-status-cell-criteria{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.payment-status-icon{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $red;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-status-icon-paid{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $green;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-criteria-text{
  width: 100%;
  color: $textGrey;
  font-size: 12px;
}
.payment-criteria-text-bold{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $textGrey;
  font-size: 12px;
  font-weight: 500;
}
.payment-criteria-text-thin{
  width: 100%;
  white-space: normal;
  color: $mediumGrey;
  font-size: 12px;
  font-weight: 300;
}

td{
  vertical-align: unset;
  padding-top: 0;
}

.payment-list-cell{
  padding: 0;
  list-style-type:disc;
  margin-bottom: 0;
  padding-inline-start: 10px;
}
.payment-criteria-icon{
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: $textGrey;
  margin-right: 2px;
}
.payment-title-list-search{
  width: 50%;
  min-width: 250px;

  input{
    margin: 0!important;
  }
}
.payment-list-cell-dual{
  display: flex;
}
.payments-list-header{
  color: $mediumGrey;
  font-size: $fontSmall;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  padding-left: $mediumSpacing;
}
.survey-response-container{
  width: 480px;

  @media screen and (max-width: $mobileScreenWidth){
    width: 300px;
  }
}
.survey-list-title{
  color: $textGrey;
  font-size: $fontMedium;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: $mediumSpacing;
  padding-right: 5px;
  border-bottom: 1px solid gray;
}
.survey-list-close{
  cursor: pointer;
}
.survey-response-cell-list-container{
  overflow-y: auto;
  height: calc(100vh - 50px);
  padding-left: 15px;
  padding-right: 15px;
}
.survey-response-cell{
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #dedede;
  box-shadow: $boxShadow;
  border-radius: 10px;
  color: black;
  padding: 10px;
  position: relative;

  &:hover{
    cursor: pointer;
  }

  &:first-of-type{
    margin-top: 15px;
  }
}
.survey-response-cell-name{
  font-size: $fontLarge;
  font-weight: 500;
  margin-bottom: 5px;
}
.survey-response-cell-id{
  font-size: $fontLarge;
  font-weight: 700;
  margin-left: $smallSpacing;
}
.survey-response-cell-previous-answer-title-cell{
  font-size: $fontLarge;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.survey-response-cell-previous-answer-number{
  font-size: $fontMedium;
  font-weight: 700;
  margin-left: 5px;
  display: flex;
  padding: 5px;
  padding-bottom: 4px;
  padding-right: 7px;
  border-radius: 15px;
  line-height: 1;
  color: $white;
  background-color: $mainBlue;
}
.survey-response-cell-status{
  font-size: $fontMedium;
  font-weight: 500;
  margin-bottom: 5px;
}
.survey-response-cell-date{
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 10px);
}
.survey-response-cell-pdf-container{
  font-size: $fontSmall;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  cursor: pointer;
  justify-content: center;
}
.survey-response-cell-pdf-icon{
  height: 20px;
  width: auto;
}
.survey-response-cell-date-created{
  font-size: $fontSmall;
  margin-bottom: 5px;
}
.survey-response-cell-date-container{
  width: 100%;
}
.survey-response-cell-title-cell{
  width: 100%;
  display: flex;
  margin-bottom: $mediumSpacing;
}
.survey-response-edit-button{
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
}
.download-email-report-button{
  position: absolute;
  left: 50px;
  background-color: white;
}
.survey-downloading-text{
  left: 0px;
  position: absolute;
  Bottom: 0px;
  font-size: 15px;
  background-color: white;
  width: 120px;
  padding-top: 10px;
  z-index: 3;
  padding-left: 10px;
  padding-bottom: 15px;
}
