@import '../../_variables.scss';


.payment-modal{
  display: block;
  background-color: $white;
  width: 100%;
  overflow-y: auto;
  height: auto;
}
.payment-modal-title{
  font-size: 16px;
  color: $mainBlue;
  font-weight: 600;
  margin-bottom: 15px;
}
.payment-modal-input-cell {
  font-size: $font-size-base;
  color: $textGrey;
  margin-bottom: $mediumSpacing;
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  margin-top: $mediumSpacing;
}
.payment-modal-input-title{
   font-weight: 600;
 }
.payment-modal-input-container{
  display: flex;
  align-items: center;
}
.payment-modal-input-subtitle{
  font-size: $font-size-base!important;
}
.payment-modal-input-gray{
  font-weight: 400;
  background-color: $inputBackground;
  border:none!important;
  border-radius: 3px;
  width: 150px;
  min-height: 35px;
  margin-top: $smallSpacing;
  padding: $mediumSpacing;
  font-size: $font-size-base;
}
.payment-modal-input-white{
  font-weight: 400;
  background-color: $white;
  color: $textGrey;
  border:1px solid $mediumGrey;
  border-radius: 3px;
  width: 100%;
  min-height: 35px;
  margin-top: $smallSpacing;
  padding: $mediumSpacing;
  font-size: $font-size-base;
}
.payment-modal-input-small{
  font-weight: 400;
  background-color: $white;
  color: $textGrey;
  border:1px solid $mediumGrey;
  border-radius: 3px;
  width: 150px;
  min-height: 40px;
  height: 40px;
  margin-top: $smallSpacing;
  padding: $mediumSpacing;
  font-size: $font-size-base;
}

.payment-modal-text{
  font-size: 12px;
  line-height: 1.29;
}
.payment-trigger-button-cell{
  display: flex;
  justify-content: flex-end;
  margin-right: $largeSpacing;
}
.payment-modal-button{
  font-size: $font-size-base;
  color: $white;
  background-color: $mainBlue;
  border-radius: 3px;
  font-weight: 600;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-modal-button-disabled{
  font-size: $font-size-base;
  color: $textGrey;
  background-color: $inputBackground;
  border-radius: 3px;
  font-weight: 400;
  margin-right: $mediumSpacing;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-modal-padding{
  margin-right: $smallSpacing;
  margin-left: $smallSpacing;
}
.payment-modal-dual-cell{
  display: flex;
}
.payment-modal-dual-spacing{
  margin-right: $largeSpacing;
}
.payment-modal-blue-switch{
  color: $textGrey!important;
  background-color: $inputBackground!important;
  margin-top: 5px;
}
.ant-switch-checked{
  color: $white!important;
  background-color: $mainBlue!important;
}
.custom-select{
  appearance: none;
  background-image: url("../../Components/collapse-left-button.png");
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center right 10px;
  position: relative;
}
.formControl {
  margin: 20px;
  min-width: 120px;
}
.selectEmpty {
  margin-top: 20px;
}
option{
  position: relative;
  left: 10px;
}
.payment-modal-blue-title{
  font-size: 16px;
  color: $mainBlue;
  font-weight: 600;
}
.custom-select-spacing{
  margin-left: 5px;
  margin-right: 5px;
}
