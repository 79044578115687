@import '../../variables';

.download-report-button {
  margin-left: $largeSpacing;
}

.subsection-container{

  div{
    &.ant-select{
      border:1px solid black !important;
    }

    &.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field{
      border: none!important;
    }
  }
}
.manage-reports-container{
  width: 100%;
  background-color: $white;
  padding: $largeSpacing;
  padding-right: 0;
  overflow: visible;
  overflow-y: auto;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 184px);
}
.manage-reports-download-container{
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 180px);
  padding-top: $largeSpacing;
}
.manage-questionnaire-reports-download-container{
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 180px);
  padding-top: $largeSpacing;
}
.manage-scheduled-reports-download-container{
  width: 100%;
  background-color: $white;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.05);
  height: calc(100vh - 180px);
  padding-top: $largeSpacing;
  overflow: hidden;
}
.manage-reports-input-headers{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: $smallSpacing;
  color: $textGrey
}
.manage-reports-dropdown{
  flex:1 1  calc(100% - 20px);
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;


  .labeled-dropdown{
    z-index: 4200;

    option{
      z-index: 4200;
    }
  }
}
.manage-reports-dropdown-group{
  flex: 1 1 calc(100% - 20px);
  width: calc(100% - 20px);
  margin-right: $largeSpacing;
  height: auto;
  margin-bottom: 40px;
}
.manage-reports-date-container{
  display: flex;
  justify-content: space-between;
}
.manage-reports-datepicker{
  flex:0 0 50%;
  width: 50%;
}
.tool-reports-datepicker{
  flex:0 0 calc(50% - 20px);
  margin-right: $largeSpacing;
  margin-bottom: $largeSpacing;
}
.download-report-button{
  margin-left: $largeSpacing;
}
.manage-reports-buttons-container{
  display: flex;
  justify-content: flex-end;
}
.manage-buttons-container{
  display: flex;
  width: calc(100% - 20px);
  justify-content: flex-end;
  position: relative;
}
.report-button-dropdown-container{
  position: relative;
  display: flex;
}
.report-dropdown-list{
  list-style: none;
  position: absolute;
  margin-top: 42px;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 3px;
  overflow-x: auto;
  max-height: 140px;
  min-width: 130px;
  top: -185px;
  background-color: $white;
  left: 10px;
  z-index: 10001;
}
.report-dropdown-list li{
  padding: 5px;
  cursor: pointer;

  &:hover{
    background-color: rgba(0,0,0,0.15)
  }
}
.download-questionnaire-report-dropdown-list{
  list-style: none;
  position: absolute;
  margin-top: 42px;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 3px;
  overflow-x: auto;
  max-height: 140px;
  top: -185px;
  background-color: $white;
  right: 0px;
}
.download-questionnaire-report-dropdown-list li{
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;

  &:hover{
    background-color: rgba(0,0,0,0.15)
  }
}
.manage-report-dropdown-list{
  list-style: none;
  position: absolute;
  margin-top: 42px;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  border-radius: 4px;
  padding: 3px;
  overflow-x: auto;
  max-height: 140px;
  min-width: 130px;
  bottom: 31px;
  right: 0px;
  background-color: $white;
}
.manage-report-dropdown-list li{
  padding: 5px;
  cursor: pointer;

  &:hover{
    background-color: rgba(0,0,0,0.15)
  }
}
.manage-reports-survey-cell{
  height: calc(100vh - 262px);
  min-height: 200px;
  width: 100%;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-right: $mediumSpacing;
  margin-bottom: $largeSpacing;
  overflow-y: auto;
  overflow-x: hidden;
}
.manage-reports-survey-cell-download{
  height: calc(100vh - 259px);
  min-height: 200px;
  width: 100%;
  margin-right: $mediumSpacing;
  margin-bottom: $largeSpacing;
  overflow-x: hidden;
}
.manage-reports-download-surveys-container{
  padding: $mediumSpacing $largeSpacing;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-bottom: $largeSpacing;
  height: calc(100vh - 325px);
  font-size: 14px;
  color: $textGrey;
  @include hidden-scrollbar;

  svg{
    height: 16px;
    width: 16px;
  }
}
.manage-scheduled-reports-container{
  padding: $mediumSpacing $largeSpacing;
  border: 1px solid $mediumGrey;
  border-radius: $borderRadius;
  margin-bottom: $largeSpacing;
  height: calc(100vh - 325px);
  font-size: 14px;
  color: $textGrey;
  overflow: hidden;
}
.scheduled-reports-list-cell-container{
  margin-bottom: $largeSpacing;
  height: calc(100vh - 381px);
  overflow-y: auto;
  margin-top: 10px;
}
.manage-reports-download-cell{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $largeSpacing;
}
.manage-reports-buttons-container{
  display: flex;
  width: calc(100% - 20px);
  justify-content: flex-end;
  position: relative;
}
.manage-reports-download-list-container{
}
.manage-reports-cells-container{
  width: calc(100% - 40px);
  margin-left: $largeSpacing;
  margin-right: $largeSpacing;
  height: auto;
  overflow-x: hidden;
}
.manage-reports-loading{
  align-items: center;
  width: 150px;
  position: relative;
  top: -2px;
}
