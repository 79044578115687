
.response-box {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding-right: 15px;
  position: sticky;
  top: 0;
}

.title-bar {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.quest-title {
  display: inline;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.close-event-handler {
  display: inline;
  font-family: Avenir;
  font-size: 14px;
  color: #9b9b9b;
  cursor: pointer;
  margin-left: auto;
}

.response-date {
  display: block;
  font-family: Avenir-Roman;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #9b9b9b;
}

.objective-name {
  font-family: Avenir-Roman;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.entry-text {
  font-family: Avenir-Roman;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-top: 5px;
}

.green {
  color: #24895b,
}

.red {
  color: #f34d33,
}

.yellow {
  color: gold;
}

.objective-container {
  border-top: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
  padding: 10px;
}

.objective-container:hover {
  background-color: #eeeeee;
}

.reminder-status-wrapper {
  display: flex;
}

.reminder-status {
  align-self: center;
}

.align-with-image {
  margin-left: 3.5%;
}

.break {
  flex-basis: 100%;
}

.entry-title {
  font-size: 12px;
  color: #9b9b9b;
  padding-top: 10px;
}
