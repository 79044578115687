@import '../../variables';

.add-container{
  display:flex;
  align-items:center;
  margin-left:auto;
  margin-right:auto;
}
.add-cell{
  color:$mainBlue;
  cursor:pointer;
  display:flex;
  font-weight:600;
  align-items:center;
  margin-left:20px;
  margin-right:20px;
}
