@import '../../variables';

.inbox-item {
  margin: 0px;
  padding: 0px;
  height: 50px;
  display: flex;
}
.selected-patient {
  height: 50px;
  width: 5px;
  background-color: #16405b;
  margin-right: 2px;
}
.patient-list-icon{
  order: none;
  padding: 2px;
  border-radius: 100%;
  margin-right: 5px;
  box-sizing: border-box;
  object-fit: contain;
  object-position: bottom;
}
.patient-list-date{
  font-size: $fontSmall;
  color: $textGrey;
  width: 50%;
  padding: $mediumSpacing;
}
.patient-groups-labels-container{
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
}
.patient-list-cell-container{
  .patient-list-item-cell{
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: normal;
    margin-left: 10px;

    .patient-list-avatar{
      width: 50%;
    }
    .patient-list-item-date{
      width: 25%;
    }
  }
}
.patient-list-patient-email{
  font-weight: 300;
  color: $textGrey;
  font-size: 14px;
  width: 100%;
  max-height: 42px;
}
.patient-list-item-date{
  width: 50px;
  margin-left: auto;
}
.patient-list-item-cell{
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  justify-content: normal ;
}
.patient-list-avatar{
  width: calc(100% - 25% - 50px);
  color: $textGrey;
  font-size: $fontMedium;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $mediumSpacing;

  .profile-picture-container-inner{
    width: 40px;
    margin-right: 5px;
  }
}
.patient-list-item-selected{
  width: 100%;
  color: $textGrey;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  padding: $mediumSpacing;
}
.patient-list-item-alert-info{
  width: 100%;
  color: $textGrey;
  font-size: $fontMedium;
  display: flex;
  align-items: center;
  position: relative;
}
.patient-list-item-alert{
  width: 50%;
}
.patient-list-input-cell{
  position: relative;
  display: block;
}
.patient-list-custom-input-cell{
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 17px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 16px;
  height: max-content;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.patient-list-custom-input-cell input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.patient-list-custom-checkmark{
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white;
  border: 1px solid $mainBlue;
}
.patient-list-custom-input-cell:hover input ~ .patient-list-custom-checkmark{
  background-color: #eee;
}
.patient-list-custom-input-cell input:checked ~ .patient-list-custom-checkmark{
  background-color:#eee;
}
.patient-list-custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.patient-list-custom-input-cell input:checked ~ .patient-list-custom-checkmark:after{
  display: block;
}
.patient-list-custom-input-cell .patient-list-custom-checkmark:after{
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $mainBlue;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
//<label class="custom-input-cell">One
//<input type="checkbox" checked="checked">
//<span class="custom-checkmark"></span>
//</label>

.patient-list-custom-checkmark-radio{
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 2px $mainBlue;
}
.patient-list-custom-input-cell:hover input ~ .patient-list-custom-checkmark-radio{
  background-color: $lightGrey;
}

.patient-list-custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.patient-list-custom-input-cell input:checked ~ .patient-list-custom-checkmark-radio:after{
  display: block;
}
.patient-list-custom-input-cell .patient-list-custom-checkmark-radio:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $mainBlue;
}

.patient-list-checked{
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $mainBlue;
  border: 3px solid $white;
  box-shadow: 0px 0px 1px 2px $mainBlue;
  position: absolute;
}
.patient-list-item-selected{
  margin-left: auto;
}
.patient-list-fraud-info{
  width: 25%;
  padding: $mediumSpacing;
}
.patient-list-fraud-info-status{
  width: 100%;
  white-space: normal;
  color: $textGrey;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.patient-list-fraud-popover-button{
  background: $light-gray;
  padding: 2px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 9px;
  font-weight: 900;
  font-size: 20px;
  height: 13px;
  width: 25px;
  margin-left: 5px;
}
.patient-list-fraud-info-small{
  width: 100%;
  white-space: normal;
  color: $mediumGrey;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
}
.patient-list-fraud-info-count{
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
}
.patient-list-fraud-alert{
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: $red;
  color: white;
  margin-right: 3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 1;
  letter-spacing: 0.5px;
}
